import React, { Fragment, useEffect, useState } from "react";
import { Button, MenuItem, TextField } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import useTranslation from "../../../Hooks/useTranslation";
import DmsService from "../../../Services/DMS/dms-api.service";
import AlertToastr from "../../../utils/alert";
import moment from "moment";
import DatePicker from "react-datepicker";
import { useBoxes } from "Contexts/BoxContext";

const RadioLabel = ({ label }) => {
  return (
    <div className="jobLabel">
      <div className="labelName">{label}</div>
    </div>
  );
};

const ExportCsvForm = ({ unitName, onExportData, onCancel, setStep }) => {
  const translation = useTranslation();
  const [filename, setFilename] = useState("");
  const [delimiterOption, setDelimiterOption] = useState("semicolon");
  const [decimalSeparator, setDecimalSeparator] = useState("dot");
  const [dateTimeFormat, setDateTimeFormat] = useState("");
  const [dateTimeTypes, setDateTimeTypes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [dateChanged, setDateChanged] = useState(false);
  const { startChartDate, endChartDate, modalStep } = useBoxes();

  const getLocalTimeFromUtc = (utcDate, timezone) => {
    const utcMoment = moment.utc(utcDate);
    return utcMoment.tz(timezone).toDate();
  };

  useEffect(() => {
    const timezone = moment.tz.guess();

    if (!dateChanged && startChartDate && endChartDate) {
      setDateFrom(getLocalTimeFromUtc(startChartDate, timezone));
      setDateTo(getLocalTimeFromUtc(endChartDate, timezone));
    }
  }, [endChartDate, startChartDate, dateChanged]);

  useEffect(() => {
    fetchExportDateTypes().then();
  }, []);

  useEffect(() => {
    setFilename(unitName);
  }, [unitName, setFilename]);

  const fetchExportDateTypes = async () => {
    try {
      let response = await DmsService.getExportDateTypes();

      setDateTimeTypes(response.data);
      if (response.data[0]) setDateTimeFormat(response.data[0].typeValue);
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.Message
      )
        AlertToastr.showErrorAlert(error.response.data.Message);
    } finally {
      setLoading(false);
    }
  };

  const handleDelimiterChange = (event) => {
    setDelimiterOption(event.target.value);
  };

  const handleDecimalSeparatorChange = (event) => {
    setDecimalSeparator(event.target.value);
  };

  const getFormattedUtcOffset = () => {
    let offset = moment().format("Z");
    if (offset.charAt(0) === "+") {
      offset = offset.substring(1);
    }
    return `${offset}:00`;
  };

  const exportData = () => {
    if (!filename) {
      AlertToastr.showErrorAlert(translation.notificationsFileNameRequired);
      return;
    }
    let delimiter;
    switch (delimiterOption) {
      case "tabulator":
        delimiter = "\t";
        break;
      case "space":
        delimiter = " ";
        break;
      case "semicolon":
        delimiter = ";";
        break;
      case "comma":
        delimiter = ",";
        break;
      default:
        break;
    }
    let decimalDelimiter;
    switch (decimalSeparator) {
      case "dot":
        decimalDelimiter = ".";
        break;
      case "comma":
        decimalDelimiter = ",";
        break;
      default:
        break;
    }
    let locale;
    if (navigator.languages !== undefined) locale = navigator.languages[0];
    else locale = navigator.language;
    onExportData({
      name: filename,
      delimiter: delimiter,
      decimalDelimiter: decimalDelimiter,
      dateTimeFormat: dateTimeFormat,
      cultureInfoName: locale,
      timeShift: getFormattedUtcOffset(),
      dateFrom: modalStep === "exportCsv" ? dateFrom.valueOf() : null,
      dateTo: modalStep === "exportCsv" ? dateTo.valueOf() : null,
    });
    setTimeout(() => setStep("selectChannels"), 2000);
  };

  return (
    <Fragment>
      <div className="formContentHolder">
        <div className="formContent">
          <div className="formContentBlock">
            <TextField
              value={filename}
              variant="outlined"
              margin="none"
              fullWidth
              id="filename"
              label={translation.exportToCsvModalFileNameLabel}
              name="filename"
              onChange={(e) => setFilename(e.target.value)}
              inputProps={{
                autoComplete: "off",
              }}
            />
          </div>
          <div className="formContentBlock">
            <FormControl component="fieldset">
              <FormLabel component="label" className={"radioLabel text-bold"}>
                {translation.exportToCsvModalDelimiterSymbolLabel}
              </FormLabel>
              <RadioGroup
                row
                aria-label="position"
                name="position"
                value={delimiterOption}
                onChange={handleDelimiterChange}
              >
                <FormControlLabel
                  checked={delimiterOption === "semicolon"}
                  value="semicolon"
                  control={<Radio color="default" />}
                  label={
                    <RadioLabel
                      label={
                        translation.exportToCsvModaDelimiterSymbolSemicolonLabel
                      }
                    />
                  }
                />
                <FormControlLabel
                  disabled={decimalSeparator === "comma"}
                  checked={delimiterOption === "comma"}
                  value="comma"
                  control={<Radio color="default" />}
                  label={
                    <RadioLabel
                      label={
                        translation.exportToCsvModalDelimiterSymbolCommaLabel
                      }
                    />
                  }
                />
                <FormControlLabel
                  checked={delimiterOption === "tabulator"}
                  value="tabulator"
                  control={<Radio color="default" />}
                  label={
                    <RadioLabel
                      label={
                        translation.exportToCsvModalDelimiterSymbolTabulatorLabel
                      }
                    />
                  }
                />
                <FormControlLabel
                  checked={delimiterOption === "space"}
                  value="space"
                  control={<Radio color="default" />}
                  label={
                    <RadioLabel
                      label={translation.exportToCsvModalSpaceLabel}
                    />
                  }
                />
              </RadioGroup>
            </FormControl>
          </div>
          <div className="formContentBlock">
            <FormControl component="fieldset">
              <FormLabel component="label" className={"radioLabel text-bold"}>
                {translation.exportToCsvModalDecimalPointSeparatorLabel}
              </FormLabel>
              <RadioGroup
                row
                aria-label="position"
                value={decimalSeparator}
                onChange={handleDecimalSeparatorChange}
              >
                <FormControlLabel
                  checked={decimalSeparator === "dot"}
                  value="dot"
                  control={<Radio color="default" />}
                  label={
                    <RadioLabel
                      label={translation.exportToCsvModalPointSeparatorDotLabel}
                    />
                  }
                />
                <FormControlLabel
                  disabled={delimiterOption === "comma"}
                  checked={decimalSeparator === "comma"}
                  value="comma"
                  control={<Radio color="default" />}
                  label={
                    <RadioLabel
                      label={
                        translation.exportToCsvModalPointSeparatorCommaLabel
                      }
                    />
                  }
                />
              </RadioGroup>
            </FormControl>
          </div>
          {modalStep === "exportCsv" && (
            <div className="formContentBlock" style={{ display: "flex" }}>
              <div>
                <FormLabel component="label" className={"radioLabel text-bold"}>
                  {translation.fromLabel}
                </FormLabel>
                <div
                  className="DateTimePickerFrom"
                  style={{ paddingBottom: "0" }}
                >
                  <DatePicker
                    selected={dateFrom}
                    onChange={(date) => {
                      setDateFrom(date);
                      setDateChanged(true);
                    }}
                    showTimeSelect
                    dateFormat="Pp"
                    className="formControlDatePicker"
                    timeFormat="hh:mm aa"
                  />
                </div>
              </div>
              <div>
                <FormLabel component="label" className={"radioLabel text-bold"}>
                  {translation.toLabel}
                </FormLabel>
                <div
                  className="DateTimePickerTo"
                  style={{ paddingBottom: "0" }}
                >
                  <DatePicker
                    selected={dateTo}
                    onChange={(date) => {
                      setDateTo(date);
                      setDateChanged(true);
                    }}
                    showTimeSelect
                    dateFormat="Pp"
                    className="formControlDatePicker"
                    timeFormat="hh:mm aa"
                  />
                </div>
              </div>
            </div>
          )}
          <div className="formContentBlock">
            <FormLabel component="label" className={"radioLabel text-bold"}>
              {translation.exportToCsvModalDateAndTimeExportFormatLabel}
            </FormLabel>
            <TextField
              variant="outlined"
              margin="normal"
              select
              fullWidth
              label={translation.manageAxesModalFormatLabel}
              value={dateTimeFormat}
              onChange={(e) => setDateTimeFormat(e.target.value)}
            >
              {dateTimeTypes.map((type) => (
                <MenuItem
                  key={type.typeValue}
                  value={type.typeValue}
                  className="filterMenuItem"
                >
                  {type.name}
                </MenuItem>
              ))}
            </TextField>
          </div>
        </div>
        <div className="btnHolder end fixedWidthBtn">
          <Button variant="outlined" onClick={onCancel}>
            {translation.cancelButtonLabel}
          </Button>
          <Button
            disabled={loading}
            onClick={exportData}
            variant="contained"
            color="primary"
          >
            {translation.exportButtonLabel}
          </Button>
        </div>
      </div>
    </Fragment>
  );
};

export default ExportCsvForm;
