import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import UnitBoxLoader from './UnitBoxLoader';
import { useAuth } from '../../../Contexts/AuthProvider';
import ManageAxesModal from '../../modals/ManageAxisModal/ManageAxesModal';
import ManageDateFormatModal from '../../modals/ManageDateFormatModal/ManageDateFormatModal';
import UnitBoxDataError from './UnitBoxDataError';
import UnitDataTable from './UnitDatatable';
import UnitBoxDatatableHeader from './UnitBoxDatatableHeader';
import UnitJobDateRangeFilters from '../../filters/UnitJobDateRangeFilters';
import useUnitInitChannelsDataWithFilters from '../../../Hooks/useUnitInitChannelsDataWithFilters';
import UnitBoxDataTableSignalR from '../../SignalrSubscribes/UnitBoxDataTableSignalR';
import { Cached } from '@material-ui/icons';
import Button from '@material-ui/core/Button';
import useTranslation from '../../../Hooks/useTranslation';
import DataTableCustomDateBoxFilter from '../../filters/DataTableCustomDateBoxFilter';
import { useBoxes } from '../../../Contexts/BoxContext';
// import { isFirefox } from 'react-device-detect';
// import DataTableBoxFilter from '../../filters/DataTableBoxFilter';


const UnitBoxDatatable = props => {

    const {
        boxData,
        onRemove,
        handleEdit,
        dragHandleProps,
        onNewWindowOpen,
        onExportToCsv,
        onFilterChange,
        showJobDateRangeFilter = false,
        showDateTypeFilter = true,
        section = 'unit',
        onUpdateSettings,
    } = props;

    const {
        unitUid,
        channels,
        timeType,
        timeFormat,
        filters,
    } = boxData;

    const translation = useTranslation();
    const { token, currentCompany } = useAuth();

    const [showManageAxesModal, setShowManageAxesModal] = useState(false);
    const [showManageDateFormatModal, setManageDateFormatModal] = useState(false);
    const [data, setData] = useState(null);
    const [hasDataError, setHasDataError] = useState(false);
    const [currentTimestamp, setCurrentTimestamp] = useState(null);
    const [dateTimeFormat, setDateTimeFormat] = useState('');
    const [dateFrom, setDateFrom] = useState(null);
    const [dateTo, setDateTo] = useState(null);
    const signalrRequestBody = useMemo(() => ({
        uId: unitUid,
        channels, ...filters,
        jobType: section,
    }), [unitUid, channels, filters, section]);
    const [refreshCounter, setRefreshCounter] = useState(0);
    const { startDate, endDate, updateChartDateRange } = useBoxes();

    const channelsDataUnitRequest = useUnitInitChannelsDataWithFilters({ unitUid, channels, filters, section, startDate,  endDate });

    useEffect(() => {
        if (filters.dateFrom !== undefined) {
            setDateFrom(filters.dateFrom)
        } else {
            setDateFrom(startDate)
        }
        if (filters.dateTo !== undefined) {
            setDateTo(filters.dateTo)
        } else {
            setDateTo(endDate)
        }
    }, [endDate, filters, startDate]);

    useEffect(() => {
        if (channelsDataUnitRequest.isSuccess) {
            const {
                channelDataResponses,
                lastProcessedTimestamp,
                dateTimeFormat,
            } = channelsDataUnitRequest.data;

            if (!channelDataResponses) {
                setHasDataError(true);
                return;
            }

            const startTime = new Date(lastProcessedTimestamp);
            startTime.setMinutes(startTime.getMinutes() - startTime.getTimezoneOffset());
            startTime.setSeconds(0);
            startTime.setMilliseconds(0);
            setDateFrom(prev => {
                const newDateFrom = prev ? prev : startTime.toISOString().replace('Z', '')
                updateChartDateRange(newDateFrom, dateTo);
                return newDateFrom;
            });

            setData(channelDataResponses);
            setCurrentTimestamp(lastProcessedTimestamp);
            setDateTimeFormat(dateTimeFormat);
        }
    }, [channelsDataUnitRequest.data, channelsDataUnitRequest.isSuccess]);


    const handleLiveData = useCallback(incomingData => {
        if (incomingData && incomingData.length) {
            setData(prevData => {
                const newData = prevData ? [...prevData] : [];
                incomingData.forEach((channelData) => {
                    const { channel, data } = channelData;
                    const chIdx = newData.findIndex(chData => chData.channel.code === channel.code);
                    if (chIdx !== -1) {
                        newData[chIdx].data = [...newData[chIdx].data, ...data];
                    }
                });
                return newData;
            });
        }

    }, [setData]);

    const openManageAxesModal = () => {
        setShowManageAxesModal(true);
    };

    const openManageDateFormatModal = () => {
        setManageDateFormatModal(true);
    };

    const saveAxesSettings = (yAxisSettings, xAxisSettings, timeType, timeFormat) => {
        boxData.yAxisSettings = yAxisSettings;
        boxData.xAxisSettings = xAxisSettings;
        boxData.timeType = timeType;
        boxData.timeFormat = timeFormat;
        if (onUpdateSettings) onUpdateSettings();
    };

    const saveDateFormat = (timeType, timeFormat) => {
        boxData.timeType = timeType;
        boxData.timeFormat = timeFormat;
        if (onUpdateSettings) onUpdateSettings();
    };

    const content = hasDataError
        ? <UnitBoxDataError />
        : (
            <UnitDataTable
                channelsData={data}
                dateFrom={dateFrom}
                dateTo={dateTo}
                job={null}
                timeType={timeType}
                timeFormat={timeFormat}
                timeDiff={currentTimestamp}
                dateTimeFormat={dateTimeFormat}
            />
        );


    const handleChangeFilters = (filters) => {
        onFilterChange({ ...boxData, filters: { ...boxData.filters, ...filters } });
        updateChartDateRange(dateFrom, dateTo);
    };

    const handleRefresh = () => {
        setDateFrom(null);
        setDateTo(null);
        setData(null);
        channelsDataUnitRequest.refetch().then();
        setRefreshCounter(c => c + 1);
    };

    const handleLiveIncomingDataStop = useCallback((lastTimestamp) => {
        const endTime = new Date(lastTimestamp);
        endTime.setMinutes(endTime.getMinutes() - endTime.getTimezoneOffset());
        // endTime.setSeconds(0);
        endTime.setMilliseconds(0);
        setDateTo(prev => {
            const newDateTo = prev ? prev : endTime.toISOString().replace('Z', '');
            updateChartDateRange(dateFrom, newDateTo);
            return newDateTo;
        });
    }, []);


    return (
        <Fragment>
            <UnitBoxLoader
                loading={channelsDataUnitRequest.isLoading || channelsDataUnitRequest.isFetching}
            />

            <UnitBoxDatatableHeader
                box={boxData}
                onRemove={onRemove}
                handleEdit={handleEdit}
                dragHandleProps={dragHandleProps}
                onManageAxesSettings={openManageAxesModal}
                onManageDateFormat={openManageDateFormatModal}
                onNewWindowOpen={onNewWindowOpen}
                onExportToCsv={onExportToCsv}
            />

            <div className="pageCard">
                <div className="pageBtnSection pt0">
                    <div style={{ display: 'flex' }}>
                        {
                            showJobDateRangeFilter && (
                                <UnitJobDateRangeFilters
                                    initData={boxData.filters}
                                    unitUid={unitUid}
                                    onChange={handleChangeFilters}
                                    inline
                                />
                            )
                        }
                        {
                            showDateTypeFilter && (
                                <DataTableCustomDateBoxFilter
                                    showStepFilter={false}
                                    initData={boxData.filters}
                                    onChange={handleChangeFilters}
                                    dateFrom={dateFrom}
                                    dateTo={dateTo}
                                    onDateFromChange={date => {
                                        setDateFrom(date);
                                        updateChartDateRange(date, dateTo);
                                    }}
                                    onDateToChange={date => {
                                        setDateTo(date);
                                        updateChartDateRange(dateFrom, date);
                                    }}
                                    saveInLocalTime={true}
                                />
                                // isFirefox ? (
                                //     <DataTableCustomDateBoxFilter
                                //         showStepFilter={false}
                                //         initData={boxData.filters}
                                //         onChange={handleChangeFilters}
                                //         dateFrom={dateFrom}
                                //         dateTo={dateTo}
                                //         onDateFromChange={date => setDateFrom(date)}
                                //         onDateToChange={date => setDateTo(date)}
                                //     />
                                // ) : (
                                //     <DataTableBoxFilter
                                //         showStepFilter={false}
                                //         dateFrom={dateFrom}
                                //         dateTo={dateTo}
                                //         onDateFromChange={e => setDateFrom(e.target.value)}
                                //         onDateToChange={e => setDateTo(e.target.value)}
                                //         initData={boxData.filters}
                                //         onChange={handleChangeFilters}
                                //     />
                                // )
                            )
                        }
                        <Button
                            className='refreshBtn'
                            onClick={handleRefresh}
                            disabled={channelsDataUnitRequest.isLoading || channelsDataUnitRequest.isFetching}
                            startIcon={<Cached />}
                        >
                            <span className='hideTitleRefreshBtn'>{translation.refreshButtonLabel}</span> 
                        </Button>
                    </div>
                </div>
                {content}
            </div>

            <ManageAxesModal
                isOpen={showManageAxesModal}
                setOpen={setShowManageAxesModal}
                editedModel={boxData}
                reverseChart={false}
                onSave={saveAxesSettings}
                timeType={timeType}
                timeFormat={timeFormat}
            />

            <ManageDateFormatModal
                isOpen={showManageDateFormatModal}
                setOpen={setManageDateFormatModal}
                onSave={saveDateFormat}
                dateFrom={dateFrom}
                dateTo={dateTo}
                setDateFrom={setDateFrom}
                setDateTo={setDateTo}
                timeType={timeType}
                timeFormat={timeFormat}
                type="dataTable"
            />


            { (currentTimestamp && !channelsDataUnitRequest.isFetching) && (
                <UnitBoxDataTableSignalR
                    refreshCounter={refreshCounter}
                    token={token}
                    companyId={currentCompany?.id}
                    requestBody={signalrRequestBody}
                    onIncomingData={handleLiveData}
                    onIncomingDataStop={handleLiveIncomingDataStop}
                    lastProcessedTimestamp={currentTimestamp}
                />
            )}

            
        </Fragment>
    );
};

export default UnitBoxDatatable;
