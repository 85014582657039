import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import { Redirect } from 'react-router-dom'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import IconButton from '@material-ui/core/IconButton'
import MmsService from 'Services/MMS/mms-api.service'
import { useAuth } from 'Contexts/AuthProvider'
import { Checkbox, MenuItem, Switch } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import Fade from '@material-ui/core/Fade'
import Popover from '@material-ui/core/Popover'
import AlertToastr from 'utils/alert'
import DeleteItemModal from 'Components/modals/delete-item-modal/delete-item-modal.component'
import SaveComponentModal from './Modals/SaveComponentModal'
import ChangeDepartmentModal from './Modals/ChangeDepartmentModal'
import DuplicateComponentModal from './Modals/DuplicateComponentModal'
import ReplaceComponentModal from './Modals/ReplaceComponentModal'
import UninstallComponentModal from './Modals/UninstallComponentModal'
import SaveActivityModal from '../Activities/Modals/SaveActivityModal'
import CloneToEquipmentModal from '../Catalog/Modals/CloneToEquipmentModal'
import {
  setComponentsCatalog,
  setCurrentPage,
  setPageTitle,
  setRspSelectedComponentsIds,
  setRunComponentsUpdate,
} from '../../../Store/modules/mms/actions'
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button'
import useTranslation from 'Hooks/useTranslation'
import RecommendedSparePartsComponentBlock from './RecommendedSparePartsComponentBlock'
import CompleteActivityModal from '../Activities/Modals/CompleteActivityModal'
import { useAppSettings } from '../../../Contexts/AppSettingsProvider'
import MmsAssetsSignalR from '../../../Components/SignalrSubscribes/MmsAssetsSignalR'
import { flattenTree } from '../../../helpers'
import { useComponent, useComponentsWithPaging } from '../../../Hooks/mms/useComponents'
import { useQueryClient, useQuery } from 'react-query'
import EquipmentInlineWorkhoursForm from './Modals/EquipmentInlineWorkhoursForm'
import EquipmentInlineNotesForm from './Modals/EquipmentInlineNotesForm'
import ActivitiesTable from './ActivitiesTable'
import TablePagination from './TablePagination'
import SearchInput from '../../../Components/SearchInput'
import { useEmployeesByDepartmentId } from '../../../Hooks/mms/useEmployees'
import EventEmitter from '../../../utils/EventEmitter'
import { apiMmsBaseUrl } from "config";
import Api from "../../../Services/Api.service";
import axios from 'axios';

const descConst = 'descending'
const ascConst = 'ascending'
const arrowDownClassName = 'hasArrow'
const arrowUpClassName = 'hasArrow up'

const EquipmentComponent = props => {
  const {
    isCatalog,
    currentCompany,
    currentDepartment,
    employees,
    setCurrentPage,
    runComponentsUpdate,
    setRunComponentsUpdate,
    setComponentsCatalog,
    componentsCatalog,
    setPageTitle,
    setRspSelectedComponentsIds,
  } = props

  const { token } = useAuth()
  const [components, setComponents] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [isOpenNewSaveComponentModal, setIsOpenNewSaveComponentModal] = useState(false)
  const [isOpenSaveComponentModal, setIsOpenSaveComponentModal] = useState(false)
  const [isOpenCloneToEquipmentModal, setIsOpenCloneToEquipmentModal] = useState(false)
  const [parentComponentId, setParentComponentId] = useState(null)
  const [parentWorkhours, setParentWorkhours] = useState(null)
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false)
  const [isOpenDeleteActivityModal, setIsOpenDeleteActivityModal] = useState(false)
  const [isOpenChangeDepartmentModal, setIsOpenChangeDepartmentModal] = useState(false)
  const [isOpenReplaceComponentModal, setIsOpenReplaceComponentModal] = useState(false)
  const [isOpenUninstallComponentModal, setIsOpenUninstallComponentModal] = useState(false)
  const [isOpenDuplicateComponentModal, setIsOpenDuplicateComponentModal] = useState(false)
  const [isOpenSaveActivityModal, setIsOpenSaveActivityModal] = useState(false)
  const [isOpenCompleteActivityModal, setIsOpenCompleteActivityModal] = useState(false)
  const [menuAnchorEl, setMenuAnchorEl] = useState(null)
  const [, onAnchorElChange] = useState(null)
  const [activityAnchorEl, setActivityAnchorEl] = useState(null)
  const [selectedComponent, setSelectedComponent] = useState(null)
  const [selectedComponentDetail, setSelectedComponentDetail] = useState(null)
  const [selectedActivity, setSelectedActivity] = useState(null)
  const [typeOptions, setTypeOptions] = useState([])
  const [priorityOptions, setPriorityOptions] = useState([])
  const [, setNewComponent] = useState(null);

  const { currentUser } = useAuth()
  const isSuperUser = useMemo(() => (currentUser.isSuperuser()), [currentUser])
  const [openedComponentsIds, setOpenedComponentsIds] = useState([])
  const [activeComponentActivity, setActiveComponentActivity] = useState(null)

  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(15)

  const [pagination, setPagination] = useState()
  const [searchTerm, setSearchTerm] = useState('')
  const [sortConfig, setSortConfig] = useState({ key: 'Description', direction: ascConst })

  const translation = useTranslation()
  const { appLanguage } = useAppSettings()
  const queryClient = useQueryClient()

  const [expandedIds, setExpandedIds] = useState([]);
  const [ , setIsDeleting] = useState(false);
  const [componentsOptions, setComponentsOptions] = useState([]);
  const [statesList, setStatesList] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const popoverId = open ? "state-popover" : undefined;
  const [isPopoverOpen, setPopoverOpen] = useState(false);
  const [selectedStateName, setSelectedStateName] = useState("");
  const [selectedStateId, setSelectedStateId] = useState(null);


  const [employeesOptions, setEmployeesOptions] = useState({ fromDepartment: [], other: [] })

  const departmentEmployees = useMemo(() => {
    if (employees && currentDepartment)
      return employees.filter(e => e.departmentId === currentDepartment.id)
    else
      return null
  }, [employees, currentDepartment])

  const closePopover = () => {
    setMenuAnchorEl(null)
    setSelectedComponent(null);
    setSelectedComponentDetail(null);
  }

  const componentList = useMemo(() => {
    return components
  }, [components])

  const listTree = useMemo(() => {
    // const tree = listToTree(componentList, 'parentComponentId');
    const list = flattenTree(components, 'subComponents')
    if (list.length) list.shift()
    return list
  }, [components])

  const expandedList = useMemo(() => {
    return listTree.filter(item =>
      item.nestingLevel === 1
      || openedComponentsIds.includes(item.id)
      || openedComponentsIds.includes(item.parentComponentId),
    )
  }, [listTree, openedComponentsIds])

  useEffect(() => {
    const onEvent = () => {
      setIsOpenNewSaveComponentModal(true)
    }

    const listener = EventEmitter.addListener('CreateNewComponent', onEvent)
    return () => {
      listener.remove()
    }
  }, [])

  const fetchComponentOptions = async () => {
    let response = await MmsService.getComponentsWithDependencies(
      currentDepartment.id
    );
    setComponentsOptions(response.data);
  };

  useEffect(() => {
    if (currentDepartment) {
      fetchComponentOptions()
      if (isCatalog)
        setComponentsCatalog([])
      else
        setComponents([])
    }
  }, [currentDepartment, isCatalog, setComponents, setComponentsCatalog])
 

  useEffect(() => {
    isCatalog ? setPageTitle(translation.catalogPageLabel) : setPageTitle(translation.equipmentPageLabel)
  }, [setPageTitle, translation, isCatalog])

  const closeMenu = () => {
    onAnchorElChange(null)
  }

  const handleRowCount = (count) => {
    setPageSize(count)
    setPageNumber(1)
    closeMenu()
  }

  const onNextPage = () => {
    setPageNumber(p => p + 1)
    window.scrollTo(0, 0)
  }

  const onPrevPage = () => {
    setPageNumber(p => p - 1)
    window.scrollTo(0, 0)
  }

  const selectedComponentsIds = useMemo(() => {
    if (expandedList.length)
      return expandedList.filter(item => !item.parentComponentId && item.selected).map(item => item.id)
    return []
  }, [expandedList])

  useEffect(() => {
    setRspSelectedComponentsIds(selectedComponentsIds)
  }, [
    selectedComponentsIds,
    setRspSelectedComponentsIds,
  ])

  useEffect(() => {
    async function fetchComponentStates() {
      if (currentCompany?.id) {
        const componentStatesRequest = await Api.get(
          `${apiMmsBaseUrl}/options/component-states`,
          {
            headers: isSuperUser ? { CompanyId: currentCompany.id } : {},
          }
        );

        if (componentStatesRequest.data) {
          setStatesList(componentStatesRequest.data);
        }
      } else {
        const componentStatesRequest = await Api.get(
          `${apiMmsBaseUrl}/options/component-states`
        );
        if (componentStatesRequest.data) {
          setStatesList(componentStatesRequest?.data);
        }
      }
    }

    fetchComponentStates();
  }, [currentCompany?.id, isSuperUser]);

  const getStatusMessage = () => {
    switch (selectedComponent?.state) {
      case "fieldReady":
        return "Field ready";
      case "toRepair":
        return "To repair";
      case "toCheck":
        return "To check";
      case "installed":
        return "Installed";
      case "disposed":
        return "Disposed";
      case "removed":
        return "Removed";
      default:
        return "";
    }
  };

  const handleStateListClick = (event, compModel) => {
    setSelectedComponent(compModel);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseStateList = () => {
    setAnchorEl(null);
  };

  const handleClosePopover = () => {
    setPopoverOpen(false);
  };

  const handleStateItemClick = (stateId, stateName) => {
    handleCloseStateList();
    setSelectedStateId(stateId);
    setSelectedStateName(stateName);
    changeComponentState(selectedComponent?.id, stateId);
  };

  const changeComponentState = (stateId, stateName) => {
    MmsService.updateUnassignedComponentState(stateId, stateName)
      .then((res) => {
        if (res.data.isSuccessful) {
          queryClient.invalidateQueries("components");
          AlertToastr.showAlert("Component state successfully updated");
        }
      })

      .catch((error) => {
        // if (
        //   error &&
        //   error.response &&
        //   error.response.data &&
        //   error.response.data.message
        // )

        AlertToastr.showErrorAlert(
          "User is not authorized to update state component to catalog!"
        );
      });
  };

  const handleRowClicked = (compModel) => {
    if(compModel.isDeleting) {
      return
    }
    setOpenedComponentsIds(prevIds => {
      const clickedItemIndex = listTree.findIndex(item => item.id === compModel.id)
      if (clickedItemIndex === -1) return prevIds

      const item = listTree[clickedItemIndex]

      if (item.nestingLevel === 1) {
        return prevIds.includes(item.id) ? [] : [item.id]
      }

      const deeperIds = listTree.filter(i => i.nestingLevel < item.nestingLevel).map(i => i.id)

      return prevIds.includes(item.id)
        ? prevIds.filter(i => deeperIds.includes(i))
        : [...prevIds.filter(i => deeperIds.includes(i)), item.id]

    })
  }

    const currentDepartmentId = currentDepartment?.id;

    useEffect(() => {
        setPageNumber(1);
    }, [currentDepartmentId]);
    
    const componentsRequest = useComponentsWithPaging(currentDepartment?.id, {
    assetSortType: sortConfig.key,  //Description, Id, WorkhoursTotal, Activities, Status
    isAsc: (sortConfig.direction === ascConst),
    pageSize,
    pageNumber,
    searchTerm
    })
         
    useEffect(() => {
      if (componentsRequest.isSuccess && componentsRequest.data) {
        setPagination({
          pageCount: componentsRequest?.data?.data?.pageCount || 0,
          itemsCount: componentsRequest?.data?.data?.itemsCount || 0,
          itemsTotalCount: componentsRequest?.data?.data?.itemsTotalCount || 0,
          pageNumber: componentsRequest?.data?.data?.pageNumber || 0,
          pageSize: componentsRequest?.data?.data?.pageSize || 0,
          })
          setComponents(componentsRequest?.data?.data?.items || [])
          setIsLoading(false)
          setOpenedComponentsIds(componentsRequest?.data?.data?.expandedIds|| [])
          if (expandedIds?.length > 0) {
            setOpenedComponentsIds(expandedIds);
          }   
    }
    }, [componentsRequest.isSuccess, componentsRequest.data, setComponents, expandedIds])

  const componentDetailsRequest = useComponent(selectedComponent?.id)

  useEffect(() => {
    if (componentDetailsRequest.isSuccess && componentDetailsRequest.data) {
      setSelectedComponentDetail(componentDetailsRequest.data.data)
    }
  }, [componentDetailsRequest.isSuccess, componentDetailsRequest.data, setComponents])

  useEffect(() => {
    setIsLoading(true)
    const fetchComponentsCatalog = async () => {
      try {
        const response = await MmsService.getComponentsCatalog()
        setComponentsCatalog(response.data)
        setIsLoading(false)
      } catch (error) {
        setIsLoading(false)
        if (error && error.response && error.response.data && error.response.data.message)
          AlertToastr.showErrorAlert(error.response.data.message)
      }
    }
    if (isCatalog) {
      setCurrentPage('components')
      fetchComponentsCatalog()
    } else {
      setCurrentPage('equipment')
      if (!currentDepartment)
        return
      // fetchComponents();
    }
  }, [
    setComponents,
    currentCompany,
    isSuperUser,
    currentDepartment,
    setCurrentPage,
    runComponentsUpdate,
    isCatalog,
    setComponentsCatalog
  ])
  
  useEffect(() => {
    const fetchOptions = async () => {
      let typeResponse = await MmsService.getTypesOptions(appLanguage)
      setTypeOptions(typeResponse.data)
      let priorityResponse = await MmsService.getPriorityOptions(appLanguage)
      setPriorityOptions(priorityResponse.data)
    }
    fetchOptions()
  }, [appLanguage])

  const onDeleteComponent = () => {
    setIsDeleting(true);
    MmsService.deleteComponent(selectedComponent.id).then(res => {
      if (res.data.isSuccessful) {
        if(res.data.expandedIds.length > 0) {
          setExpandedIds(res.data.expandedIds); 
        } else {
          setExpandedIds(openedComponentsIds)  
        } 
        AlertToastr.showAlert(res.data.message)
        if(res.data.message === "Asset removal successfully started") {
          setOpenedComponentsIds([]);
          setExpandedIds([]);
          setSelectedComponentDetail(null)
          setSelectedComponent(null)
        }
        queryClient.invalidateQueries(['components']).then()
        if(res.data.item.state === "removed") {
          // AlertToastr.showAlert(translation.notificationsComponentDeleted)
        } else {
          AlertToastr.showAlert(translation.notificationsAssetDeleted)
        }
        // setIsDeleting(false);
        queryClient.invalidateQueries(['components']).then()
        setRunComponentsUpdate(Math.random())
        setSelectedComponent(null)
      }
    })
      .catch((error) => {
        if (error && error.response && error.response.data && error.response.data.message)
          AlertToastr.showErrorAlert(error.response.data.message)
      })
      setIsDeleting(false);
      setSelectedComponent(null)
      setSelectedComponentDetail(null)
  }

  const requestSort = (key) => {
    let direction = ascConst
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === ascConst
    ) {
      direction = descConst
    }
    setSortConfig({ key, direction })
  }

  const getClassNamesFor = (name) => {
    if (!sortConfig || sortConfig.key !== name) {
      return
    }
    if (sortConfig.direction)
      return sortConfig.direction === ascConst ? arrowDownClassName : arrowUpClassName
  }

  const onDeleteActivity = () => {
    MmsService.deleteActivity(selectedActivity.id).then(res => {
      if (res.data.isSuccessful) {
        AlertToastr.showAlert(translation.notificationsActivityDeleted)
        queryClient.invalidateQueries(['components']).then()
        setExpandedIds(res.data.expandedIds);
        if (isCatalog) {
          const componentIndex = componentsCatalog.findIndex(c => c.id === selectedComponent.id)
          const newActivities = [...componentsCatalog[componentIndex].activities]
          const activityindex = newActivities.findIndex(a => a.id === selectedActivity.id)
          newActivities.splice(activityindex, 1)
          componentsCatalog.splice(componentIndex, 1, {
            ...componentsCatalog[componentIndex],
            activities: [...newActivities],
          })
          setComponentsCatalog([...componentsCatalog])
          queryClient.invalidateQueries(['components']).then()
          setExpandedIds(res.data.expandedIds);
        } else {
          const componentIndex = components.findIndex(c => c.id === selectedComponent.id)
          const newActivities = [...components[componentIndex].activities]
          const activityindex = newActivities.findIndex(a => a.id === selectedActivity.id)
          newActivities.splice(activityindex, 1)
          components.splice(componentIndex, 1, {
            ...components[componentIndex],
            activities: [...newActivities],
          })
          setComponents([...components])
          queryClient.invalidateQueries(['components']).then()
          setExpandedIds(res.data.expandedIds);
        }
      }
    })
      .catch((error) => {
        if (error && error.response && error.response.data && error.response.data.message)
          AlertToastr.showErrorAlert(error.response.data.message)
      })
  }

  const addSubcomponent = (component) => {
    setSelectedComponent(null)
    setSelectedComponentDetail(null)
    setMenuAnchorEl(null)
    setParentComponentId(component.id)
    setParentWorkhours(component.workhoursTotal)
    // setSelectedComponent(null);
    setIsOpenSaveComponentModal(true)
    setNewComponent({ parentComponentId: component.id });
  }

  const editComponent = (component) => {
    const parentComponent = componentList.find(c => c.id === component.parentComponentId)
    setParentWorkhours(parentComponent ? parentComponent.workhoursTotal : 0)
    setMenuAnchorEl(null)
    setIsOpenSaveComponentModal(true)
  }

  const handleSelectedIdChange = (componentId, checked) => {
    componentList.find(c => c.id === componentId).selected = checked
    if (isCatalog)
      setComponentsCatalog([...componentList])
    else
      setComponents([...componentList])
  }

  const handleSuccessSave = (newComponent) => {
    setOpenedComponentsIds((prevIds) => {
      const componentIds = [newComponent?.id];
      if (newComponent?.subComponents) {
        const subComponentIds = newComponent.subComponents.map(sub => sub.id);
        componentIds.push(...subComponentIds);
      }
      return [...prevIds, ...componentIds];
    });
    
    if (newComponent?.parentComponentId) {
      setComponents((prevComponents) => {
        const updateComponent = (components, parentComponentId, newComponent) => {
          return components.map((component) => {
            if (component.id === parentComponentId) {
              const subComponents = [...component.subComponents, newComponent];
              return { ...component, subComponents };
            }
            if (component.subComponents.length > 0) {
              const updatedSubComponents = updateComponent(component.subComponents, parentComponentId, newComponent);
              return { ...component, subComponents: updatedSubComponents };
            }
            return component;
          });
        };
      
        return updateComponent(prevComponents, newComponent.parentComponentId, newComponent);
      });
    } else {
      setComponents((prevComponents) => [...prevComponents, newComponent]);
    }
    setNewComponent(null);
  };

  const handleAllSelectedChange = (checked) => {
    const newComponents = componentList.map(c => {
      if (!c.parentComponentId)
        c.selected = checked
      return c
    })
    if (isCatalog)
      setComponentsCatalog([...newComponents])
    else
      setComponents([...newComponents])
  }

  const handleSelectedShowActivityChange = (componentId) => {
    setActiveComponentActivity(prevComponentId => prevComponentId === componentId ? null : componentId)
    // const selectedComponent = componentList.find(c => c.id === componentId);
    // selectedComponent.isShownActivity = selectedComponent.isShownActivity ? false : true;
    if (isCatalog)
      setComponentsCatalog([...componentList])
    else
      setComponents([...componentList])
  }

  const handleIsActiveChanged = (checked) => {
    selectedComponent.isActive = checked
    MmsService.setComponentActivity(selectedComponent.id, checked).then((response) => {
      if (response.data.isSuccessful) {
        componentList.find(c => c.id === selectedComponent.id).isActive = checked
        if (isCatalog)
          setComponentsCatalog([...componentList])
        else
          setComponents([...componentList])
      }
    })
      .catch((error) => {
        selectedComponent.isActive = !checked
        if (error && error.response && error.response.data && error.response.data.message)
          AlertToastr.showErrorAlert(error.response.data.message)
      })
  }

  const handleLiveData = (data) => {
    const {componentId, workhours} = data;
    setComponents(prev => {
      prev.forEach(component => {
        setWorkhoursSubcomponents(component, data)
      });
      return prev.map(component => component.id === componentId ? {...component, workhoursTotal: workhours} : {...component})
    })
  }
  
  const setWorkhoursSubcomponents = (component, data) => {
    const {componentId, workhours} = data;
    if (component.id === componentId) {
      component.workhoursTotal = workhours;
      return;
    } else {
      component.subComponents.forEach(subComponent => {
        setWorkhoursSubcomponents(subComponent, data);
      });
    }
  }

  const updateList = useCallback(() => {
    queryClient.invalidateQueries(['components']).then()
  }, [queryClient])

  const employeesByDepartmentIdRequest = useEmployeesByDepartmentId(currentDepartment?.id)

  useEffect(() => {
    if (employeesByDepartmentIdRequest.isSuccess) {
      setEmployeesOptions(employeesByDepartmentIdRequest.data.data)
    }
  }, [
    employeesByDepartmentIdRequest.isSuccess, employeesByDepartmentIdRequest.data
  ])

  const renderEmptyListText = () => {
    if (components.length || isLoading) return null
    return (
      <div className="emptyInfoHolder">
        <p className="textGray text-center">{translation.activitiesPagePartsEmptyListPlaceholderLabel}</p>
      </div>
    )
  }

  if (!isSuperUser && isCatalog)
    return <Redirect to="/companies"/>  

  return (
    <>
      <div className="searchBar">
        <SearchInput
          search={searchTerm}
          onChange={setSearchTerm}
          id="assetsSearchField"
        />
        <div className='searchBarMessage'>{componentsRequest.data?.data?.message}</div>
      </div>
      <div className="pmTableHolder">
        <table className="pmTable">
          <thead>
            <tr>
              <th className="checkBoxCell">
                <Checkbox
                  checked={
                    componentList
                      ? componentList
                          .filter((c) => !c?.parentComponentId)
                          .every((c) => c.selected)
                      : false
                  }
                  onChange={(e) => handleAllSelectedChange(e.target.checked)}
                  color="default"
                  id="assetsTblCheckBoxAllRows"
                  size="small"
                />
              </th>
              <th
                onClick={() => requestSort("Description")}
                className={getClassNamesFor("Description")}
                id="assetsTblHeaderDescription"
              >
                {translation.equipmentPageMyEquipmentNameHeader}
              </th>
              <th
                onClick={() => requestSort("Id")}
                className={getClassNamesFor("Id")}
                id="assetsTblHeaderID"
              >
                {translation.equipmentPageMyEquipmentIdHeader}
              </th>
              {components.length > 0 &&
              expandedList.some(
                (compModel) => compModel.uId && compModel.uId.length > 0
              ) ? (
                <th
                  onClick={() => requestSort("SerialNumber")}
                  // className={`${getClassNamesFor(
                  //   "SerialNumber"
                  // )} slide-in-from-left`}
                  id="assetsTblHeaderSerialNumber"
                >
                  {translation.equipmentPageMyEquipmentSerialNumberHeader}
                </th>
              ) : (
                <th></th>
              )}
              <th
                onClick={() => requestSort("Note")}
                className={getClassNamesFor("Note")}
                id="assetsTblHeaderNote"
              >
                {translation.equipmentPageMyEquipmentNoteHeader}
              </th>
              {isCatalog && (
                <th
                  onClick={() => requestSort("manufacturerName")}
                  className={getClassNamesFor("manufacturerName")}
                >
                  {translation.equipmentPageMyEquipmentManufacturerHeader}
                </th>
              )}
              {!isCatalog && (
                <th
                  onClick={() => requestSort("WorkhoursTotal")}
                  className={getClassNamesFor("WorkhoursTotal")}
                  id="assetsTblHeaderWorkhours"
                >
                  {translation.equipmentPageMyEquipmentWorkhoursTotalHeader}
                </th>
              )}
              <th
                onClick={() => requestSort("Activities")}
                className={getClassNamesFor("Activities")}
                id="assetsTblHeaderActivities"
              >
                {translation.equipmentPageMyEquipmentActivitiesHeader}
              </th>
              {/* <th
                colSpan="2"
                style={{ textAlign: "right" }}
                onClick={() => requestSort("Status")}
                className={getClassNamesFor("Status")}
                id="assetsTblHeaderOperationalStatus"
              >
                {translation.equipmentPageMyEquipmentStatusHeader}
              </th> */}
               <th
                      onClick={() => requestSort("State")}
                      className={getClassNamesFor("State")}
                    >
                      {translation.unassignedComponentsPageStateHeader}
                    </th>
            </tr>
          </thead>
          <tbody>
            {components.length > 0
              ? expandedList.map((compModel) => {
                  return (
                    <Fragment key={compModel.id}>
                      <tr
                        className={
                          compModel.parentComponentId ? "innerRow" : "" 
                          // (!compModel.isActive || compModel.parentComponentId) && (!compModel.isActive || !compModel.parentComponentId) ? " deletedDepartment" : ""
                        }
                      >
                        <td className="checkBoxCell">
                          {!compModel.parentComponentId && (
                            <Checkbox
                              checked={
                                compModel.selected ? compModel.selected : false
                              }
                              id="assetsTblCheckBoxAsset"
                              disabled={compModel.isDeleting}
                              className={compModel.isDeleting ? 'deletedDepartment' : ''}
                              onChange={(e) =>
                                handleSelectedIdChange(
                                  compModel.id,
                                  e.target.checked
                                )
                              }
                              color="default"
                              size="small"
                            />
                          )}
                        </td>
                        <td
                          className={compModel.isDeleting ? 'deletedDepartment' : 'text-semi-bold'}
                          onClick={() => handleRowClicked(compModel)}
                          style={{minWidth: "240px"}}
                        >
                          {compModel.parentComponentId && (
                            <div
                              className='hasInnerRowIcon slide-in-from-top'
                              style={{
                                paddingLeft: compModel.nestingLevel === 2 ? 0 : compModel.nestingLevel * 8,
                              }}
                            >
                              <div  className='icoInnerRow'/>
                              <div className="caption">
                                {`${compModel.name}`}
                              </div>
                            </div>
                          )}
                          {!compModel.parentComponentId && `${compModel.name} `}
                        </td>
                        <td style={{minWidth: "180px"}} onClick={() => handleRowClicked(compModel)} className={compModel.isDeleting ? 'deletedDepartment' : ''}>
                          {compModel.number}
                        </td>
                        {compModel.uId ? (
                          <td onClick={() => handleRowClicked(compModel)} style={{minWidth: "180px"}}>
                            <div className={compModel.isDeleting ? 'deletedDepartment' : ''}>
                              {compModel.uId}
                            </div>
                          </td>
                        ) : (
                          <td></td>
                        )}
                        <td className={compModel.isDeleting ? 'deletedDepartment pr10' : 'pr10'} style={{minWidth: "180px"}}>
                          {!compModel.parentComponentId ? (
                            <EquipmentInlineNotesForm
                              model={compModel}
                              onSuccessUpdate={() => {
                                queryClient
                                  .invalidateQueries(["components"])
                                  .then();
                              }}
                            />
                          ) : (
                            <>&nbsp;</>
                          )}
                        </td>
                        {!isCatalog && (
                          <td className={compModel.isDeleting ? 'deletedDepartment pr10' : 'pr10'} style={{minWidth: "150px"}}>
                            <EquipmentInlineWorkhoursForm
                              setExpandedIds={setExpandedIds}
                              openedComponentsIds={openedComponentsIds}
                              disabled={compModel.isDeleting}
                              model={compModel}
                              onSuccessUpdate={() => {
                                queryClient
                                  .invalidateQueries(["components"])
                                  .then();
                              }}
                            />
                          </td>
                        )}
                        {isCatalog && (
                          <td
                            onClick={() => handleRowClicked(compModel)}
                            className={compModel.isDeleting ? 'deletedDepartment pr10' : 'pr10'}
                            style={{minWidth: "200px"}}
                          >
                            {compModel.manufacturerName}
                          </td>
                        )}
                        <td className={compModel.isDeleting ? 'deletedDepartment textBtnCell' : 'textBtnCell'} style={{minWidth: "150px"}}>
                          <Button
                            className="activitiesItem"
                            disabled={compModel.isDeleting}
                            id="assetsTblActivityShowMenuBtn"
                            onClick={() =>
                              handleSelectedShowActivityChange(compModel.id)
                            }
                          >{`${
                            translation.equipmentPageMyEquipmentActivitiesLabel
                          } (${
                            compModel.activities
                              ? compModel.activities.length
                              : 0
                          })`}</Button>
                        </td>
                        {/* {!compModel.parentComponentId && (
                          <td onClick={() => handleRowClicked(compModel)} className={compModel.isDeleting ? 'deletedDepartment' : ''}>
                            {compModel.isActive && (
                              <div className="statusItem on">
                                {
                                  translation.equipmentPageMyEquipmentStatusLabelActive
                                }
                              </div>
                            )}
                            {!compModel.isActive && (
                              <div className="statusItem off">
                                {
                                  translation.equipmentPageMyEquipmentStatusLabelInActive
                                }
                              </div>
                            )}
                          </td>
                        )} */}
                        {!compModel.parentComponentId ? (
                                <td
                                  className={
                                    compModel.isDeleting
                                      ? 'deletedDepartment p0"'
                                      : "p0"
                                  }
                                >
                                  {!compModel.isActive ? (
                                       <div className="statusItem off" style={{textAlign: 'left'}}>
                                       {
                                         translation.equipmentPageMyEquipmentStatusLabelInActive
                                       }
                                     </div>
                                  ) : (
                                      <Button
                                          onClick={(event) => handleStateListClick(event, compModel)}
                                          aria-describedby={popoverId}
                                          id="ucTblShowStatesBtn"
                                          disabled={compModel.parentComponentId || compModel.isDeleting}
                                          className={
                                              compModel.parentComponentId
                                                  ? `state-item ${compModel.state}`
                                                  : `state-item-table ${compModel.state}`
                                          }
                                      >
                                          {statesList.find((stateObj) => stateObj.componentState === compModel.state)?.name || compModel.state}
                                      </Button>
                                  )}
                                  <Popover
                                    id={popoverId}
                                    classes={{
                                      paper: "statePopover",
                                    }}
                                    open={open}
                                    anchorEl={anchorEl}
                                    onClose={handleCloseStateList}
                                    anchorOrigin={{
                                      vertical: "bottom",
                                      horizontal: "left",
                                    }}
                                    transformOrigin={{
                                      vertical: "top",
                                      horizontal: "left",
                                    }}
                                  >
                                    {statesList
                                      .filter(state => state.componentState !== 'disposed')
                                      .map((state, index) => (
                                        <MenuItem
                                          key={index}
                                          id="ucTblChooseStatesBtn"
                                          onClick={() => {
                                            handleClosePopover();
                                            handleStateItemClick(
                                              state.componentState,
                                              state.name
                                            );
                                          }}
                                          className={`state-item ${state.componentState} mb16`}
                                        >
                                          {state.name}
                                        </MenuItem>
                                    ))}
                                  </Popover>
                                </td>
                              ) : (
                                null
                              )}
                        {compModel.parentComponentId && <td></td>}
                        <td className={compModel.isDeleting ? 'deletedDepartment btnCell' : 'btnCell'}>
                          <IconButton
                            aria-label="delete"
                            disabled={compModel.isDeleting}
                            size="small"
                            className="dropDownBtn mobile"
                            id="assetsTblDropDownBtn"
                            onClick={(e) => {
                              e.preventDefault();
                              setMenuAnchorEl(e.target);
                              setSelectedComponent(compModel);
                            }}
                          >
                            <MoreVertIcon />
                          </IconButton>
                        </td>
                      </tr>
                      {compModel.id === activeComponentActivity && (
                        <tr className="activitiesRow">
                          <td colSpan="100" className="activitiesRowContent" style={{
                                paddingLeft: compModel.nestingLevel * 25,
                              }}>
                            <ActivitiesTable
                              activities={compModel.activities}
                              disabled={compModel.isDeleting}
                              typeOptions={typeOptions}
                              priorityOptions={priorityOptions}
                              setExpandedIds={setExpandedIds}
                              onAddActivity={() => {
                                if (!isSuperUser && isCatalog) return;
                                setSelectedComponent(
                                  componentList.find(
                                    (c) => c.id === compModel.id
                                  )
                                );
                                setIsOpenSaveActivityModal(true);
                                setSelectedComponent(compModel);
                                setSelectedActivity(null);
                              }}
                              onEditActivity={(e, activity) => {
                                setActivityAnchorEl(e.target);
                                setSelectedActivity(activity);
                                setSelectedComponent(
                                  componentList.find(
                                    (c) => c.id === compModel.id
                                  )
                                );
                              }}
                              disableAdd={!isSuperUser && isCatalog}
                              compModel={compModel}
                            />
                          </td>
                        </tr>
                      )}
                    </Fragment>
                  );
                })
              : null}
          </tbody>
        </table>
        {renderEmptyListText()}
        {isLoading ? (
          <div className="loaderHolder">
            <Fade in={isLoading} unmountOnExit>
              <CircularProgress />
            </Fade>
          </div>
        ) : null}
      </div>
      {components && components.length > 0 && (
        <TablePagination
          pagination={pagination}
          onPrevPage={onPrevPage}
          onNextPage={onNextPage}
          handleRowCount={handleRowCount}
          loading={componentsRequest.isLoading}
        />
      )}
      <Popover
        anchorEl={menuAnchorEl}
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        disableScrollLock={true}
        open={Boolean(menuAnchorEl)}
        onClose={closePopover}
        className="pmPopover equipmentSubMenu"
      >
        {(isSuperUser || !isCatalog) &&
          selectedComponent &&
          !selectedComponent?.parentComponentId && (
            <div className="reverseSwitchHolder">
              <Switch
                checked={Boolean(
                  selectedComponent ? selectedComponent.isActive : false
                )}
                onChange={(e) => handleIsActiveChanged(e.target.checked)}
                color="primary"
                id="assetsTblMenuActiveBtn"
              />
              <div>{translation.popoverActiveLabel}</div>
            </div>
          )}
        {(isSuperUser || !isCatalog) && (
          <MenuItem
            key={"add"}
            onClick={() => addSubcomponent(selectedComponent)}
            className="menuItem add"
            id="assetsTblMenuAddComponentBtn"
          >
            {!selectedComponent?.parentComponentId
              ? translation.popoverAddComponentLabel
              : translation.popoverAddSubcomponentLabel}
          </MenuItem>
        )}
        {(isSuperUser || !isCatalog) && (
          <MenuItem
            key={"edit"}
            onClick={() => editComponent(selectedComponent)}
            className="menuItem edit"
            id="assetsTblMenuDetailsBtn"
          >
            {translation.popoverDetailsLabel}
          </MenuItem>
        )}
        {!isCatalog &&
          selectedComponent &&
          !selectedComponent?.parentComponentId && (
            <MenuItem
              key={"duplicate"}
              onClick={() => {
                setIsOpenNewSaveComponentModal(true);
                // setIsOpenDuplicateComponentModal(true)
                setMenuAnchorEl(null);
              }}
              id="assetsTblMenuDublicateBtn"
              className="menuItem duplicate"
            >
              {translation.popoverDuplicateLabel}
            </MenuItem>
          )}
        {!isCatalog &&
          selectedComponent &&
          !selectedComponent?.parentComponentId && (
            <MenuItem
              key={"change"}
              id="assetsTblMenuChangeDepBtn"
              onClick={() => {
                setIsOpenChangeDepartmentModal(true);
                setMenuAnchorEl(null);
              }}
              className="menuItem changeDepartment"
            >
              {translation.popoverChangeDepartmentLabel}
            </MenuItem>
          )}
        {!isCatalog &&
          selectedComponent &&
          selectedComponent?.parentComponentId && (
            <MenuItem
              key={"replace"}
              id="assetsTblMenuReplaceModalBtn"
              onClick={() => {
                setIsOpenReplaceComponentModal(true);
                setMenuAnchorEl(null);
              }}
              className="menuItem replace"
            >
              {translation.popoverReplaceLabel}
            </MenuItem>
          )}
        {!isCatalog &&
          selectedComponent &&
          selectedComponent?.parentComponentId && (
            <MenuItem
              key={"uninstall"}
              id="assetsTblMenuUninstallModalBtn"
              onClick={() => {
                setIsOpenUninstallComponentModal(true);
                setMenuAnchorEl(null);
              }}
              className="menuItem uninstall"
            >
              {translation.popoverUninstallLabel}
            </MenuItem>
          )}  
        {(isSuperUser || !isCatalog) && (
          <MenuItem
            key={"delete"}
            onClick={() => {
              setIsOpenDeleteModal(true);
              setMenuAnchorEl(null);
            }}
            id="assetsTblMenuRemoveBtn"
            className="menuItem delete"
          >
            {translation.popoverRemoveLabel}
          </MenuItem>
        )}
        {!isSuperUser && isCatalog && (
          <>
            <MenuItem
              key={"take"}
              onClick={() => {
                setIsOpenCloneToEquipmentModal(true);
                setMenuAnchorEl(null);
              }}
              className="menuItem change"
            >
              {translation.popoverTakeToMyEquipmentLabel}
            </MenuItem>
            <MenuItem
              key={"show"}
              onClick={() => editComponent(selectedComponent)}
              className="menuItem edit"
            >
              {translation.popoverShowDetailsLabel}
            </MenuItem>
          </>
        )}
      </Popover>
      <Popover
        anchorEl={activityAnchorEl}
        id="assetsTblActivitySideMenuBtn"
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        disableScrollLock={true}
        open={Boolean(activityAnchorEl)}
        onClose={() => setActivityAnchorEl(null)}
        className="pmPopover equipmentSubMenu"
      >
        <MenuItem
          disabled={selectedActivity && selectedActivity.status !== "opened"}
          key={"edit"}
          id="assetsTblActivitySideMenuDetailsBtn"
          onClick={() => {
            setActivityAnchorEl(null);
            setIsOpenSaveActivityModal(true);
          }}
          className="menuItem edit"
        >
          {translation.popoverDetailsLabel}
        </MenuItem>
        <MenuItem
          key={"delete"}
          id="assetsTblActivitySideMenuRemoveBtn"
          onClick={() => {
            setIsOpenDeleteActivityModal(true);
            setActivityAnchorEl(null);
          }}
          className="menuItem delete"
        >
          {translation.popoverRemoveLabel}
        </MenuItem>
      </Popover>

      <SaveComponentModal
        open={isOpenNewSaveComponentModal}
        handleClose={(e) => {
          setSelectedComponent(null);
          setSelectedComponentDetail(null);
          setIsOpenNewSaveComponentModal(false);
          setIsOpenSaveComponentModal(false);
        }}
        isCatalog={false}
        duplicateBasedOnComponent={selectedComponentDetail}
        onSuccessSave={(item) => {}}
        parentComponentId={selectedComponentDetail?.parentComponentId}
        openedComponentsIds={openedComponentsIds}
        setExpandedIds={setExpandedIds}
      />

      <SaveComponentModal
        open={isOpenSaveComponentModal}
        handleClose={() => {
          setParentComponentId(null);
          setSelectedComponent(null);
          setSelectedComponentDetail(null);
          setIsOpenSaveComponentModal(false);
          setIsOpenNewSaveComponentModal(false);
        }}
        component={selectedComponentDetail}
        parentComponentId={parentComponentId}
        parentWorkhours={parentWorkhours}
        isCatalog={isCatalog}
        isReadOnly={!isSuperUser && isCatalog}
        loading={componentDetailsRequest.isLoading}
        onSuccessSave={handleSuccessSave}
        setExpandedIds={setExpandedIds}
      />
      <ChangeDepartmentModal
        open={isOpenChangeDepartmentModal}
        handleClose={() => setIsOpenChangeDepartmentModal(false)}
        department={currentDepartment}
        componentIds={[selectedComponent?.id]}
        onSuccessChange={updateList}
      />
      <DuplicateComponentModal
        parentComponentId={selectedComponent?.parentComponentId}
        isOpen={isOpenDuplicateComponentModal}
        isOpenManager={setIsOpenDuplicateComponentModal}
        componentIds={[selectedComponent?.id]}
        departmentId={currentDepartment?.id}
        isCatalog={isCatalog}
        onSuccessDuplicate={updateList}
      />
      <ReplaceComponentModal
        componentIds={[selectedComponent?.id]}
        isOpen={isOpenReplaceComponentModal}
        isOpenManager={setIsOpenReplaceComponentModal}
        handleClose={() => {
          setIsOpenReplaceComponentModal(false);
        }}
        addedComponent={[selectedComponent]}
        departmentId={currentDepartment?.id}
        onSuccessReplace={updateList}
        setExpandedIds={setExpandedIds}
        components={components}
      />
      <UninstallComponentModal
        componentIds={[selectedComponent?.id]}
        isOpen={isOpenUninstallComponentModal}
        isOpenManager={setIsOpenUninstallComponentModal}
        handleClose={() => {
          setIsOpenUninstallComponentModal(false);
        }}
        addedComponent={[selectedComponent]}
        departmentId={currentDepartment?.id}
        onSuccessReplace={updateList}
        setExpandedIds={setExpandedIds}
        components={components}
      />
      <CloneToEquipmentModal
        isOpen={isOpenCloneToEquipmentModal}
        isOpenManager={setIsOpenCloneToEquipmentModal}
        ids={[selectedComponent?.id]}
        departmentId={currentDepartment?.id}
        instance={"components"}
      />
      <DeleteItemModal
        isOpen={isOpenDeleteModal}
        isOpenManager={setIsOpenDeleteModal}
        message={translation.removeComponentModalMessageLabel}
        onDeleteModalClose={() => {
          onDeleteComponent();
        }}
      />
      <SaveActivityModal
        componentsOptions={componentsOptions}
        isOpen={isOpenSaveActivityModal}
        isOpenManager={setIsOpenSaveActivityModal}
        isCatalog={isCatalog}
        componentId={selectedComponent?.id}
        activity={selectedActivity}
        componentWorkHours={selectedComponent?.workhoursTotal}
        onSuccessSave={updateList}
        hideCompleted={!!selectedActivity}
        setExpandedIds={setExpandedIds}
        setSelectedComponentDetail={setSelectedComponentDetail}
      />
      <CompleteActivityModal
        employeesOptions={employeesOptions}
        isOpen={isOpenCompleteActivityModal}
        isOpenManager={setIsOpenCompleteActivityModal}
        activity={selectedActivity}
        employees={departmentEmployees}
        handleCompleteUpdate={async () => {
          try {
            const response = await MmsService.getComponents(
              currentDepartment.id
            );
            setIsLoading(false);
            setComponents(response.data);
          } catch (error) {
            setIsLoading(false);
            if (
              error &&
              error.response &&
              error.response.data &&
              error.response.data.message
            )
              AlertToastr.showErrorAlert(error.response.data.message);
          }
        }}
      />
      <DeleteItemModal
        isOpen={isOpenDeleteActivityModal}
        isOpenManager={setIsOpenDeleteActivityModal}
        message={translation.removeActivityModalMessageLabel}
        onDeleteModalClose={() => {
          onDeleteActivity();
        }}
      />

      {!isCatalog && (
        <RecommendedSparePartsComponentBlock
          rspSelectedComponentsIds={selectedComponentsIds}
        />
      )}
      {currentDepartment && (
        <MmsAssetsSignalR
          departmentId={currentDepartment?.id}
          onIncomingData={handleLiveData}
          token={token}
          companyId={currentCompany?.id}
        />
      )}
    </>
  );
}

const mapStateToProps = ({ mms, dashboard }) => ({
  currentCompany: dashboard.currentCompany,
  currentDepartment: mms.currentDepartment,
  runComponentsUpdate: mms.runComponentsUpdate,
  componentsCatalog: mms.componentsCatalog,
  employees: mms.employees,
})

const mapDispatchToProps = {
  setCurrentPage,
  setRunComponentsUpdate,
  setComponentsCatalog,
  setPageTitle,
  setRspSelectedComponentsIds,
}

export default connect(mapStateToProps, mapDispatchToProps)(EquipmentComponent)

