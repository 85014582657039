import React, { useState, useMemo } from 'react'
import { connect } from 'react-redux'
import { ClickAwayListener, TextField } from '@material-ui/core'
import BoxHeaderContextMenuComponent from 'Components/Box/BoxHeaderContextMenu.component'
import IconButton from '@material-ui/core/IconButton'
import { editBox } from 'Store/modules/dashboard/actions'
import { useAuth } from 'Contexts/AuthProvider'

const EditIcon = () => <div className='editIcon' />
const SaveIcon = () => <div className='saveIcon' />
const DragIcon = () => <svg className='dragIcon' />

const BoxHeader = props => {
    const {
        box,
        type,
        handleEdit,
        onManageAxesSettings,
        onManageDateFormat,
        onExportToCsv,
        onNewWindowOpen,
        onRemove,
        tabId,
        itemId,
        editBox,
        dragHandleProps
    } = props

    const { currentUser } = useAuth()
    const allowExport = useMemo(() => currentUser.canExportCsv(), [currentUser])
    const [editName, setEditName] = useState(false)
    const [newBoxName, setNewBoxName] = useState(box.name)

    return (
        <div className="boxHeader formHolder">
            <div className="pageBlockHeadingPart">
                {
                    editName ?
                        <ClickAwayListener onClickAway={() => {
                            editBox({ ...box, name: newBoxName })
                            setEditName(false)
                        }}>
                            <TextField
                                defaultValue={box.name || 'Box'}
                                className='setHeadingInput'
                                autoFocus={true}
                                onChange={(e) => setNewBoxName(e.target.value)}
                            />
                        </ClickAwayListener> :
                        <h2 className="pageBlockHeading">{box.name || 'Box'}</h2>
                }
                {
                    editName ?
                        <IconButton onClick={(e) => {
                            editBox({ ...box, name: newBoxName })
                            setEditName(false)
                        }}>
                            <SaveIcon />
                        </IconButton> :
                        <IconButton onClick={(e) => {
                            setEditName(true)
                        }}>
                            <EditIcon />
                        </IconButton>
                }
            </div>
            <div className="contextMenu">
                <IconButton
                    {...dragHandleProps}
                >
                    <DragIcon class="contextMenuIcon" />
                </IconButton>
            </div>
            <div className="contextMenu">
                <BoxHeaderContextMenuComponent
                    onNewWindowOpen={onNewWindowOpen}
                    onManageChannel={handleEdit}
                    onRemove={onRemove}
                    onManageAxesSettings={onManageAxesSettings}
                    onManageDateFormat={onManageDateFormat}
                    onExportToCsv={onExportToCsv}
                    allowExport={allowExport}
                    type={type}
                    tabId={tabId}
                    itemId={itemId}
                />
            </div>
        </div>
    )
}

const mapDispatchToProps = {
    editBox
}

export default connect(null, mapDispatchToProps)(BoxHeader)
