import React, { Fragment, useState, useEffect, useMemo } from 'react'
import { Button, Dialog, MenuItem, Switch, TextField } from '@material-ui/core'
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import ManageDateFormat from 'Components/modals/ManageDateFormatModal/ManageDateFormat'
import useTranslation from 'Hooks/useTranslation'
import RadioLabel from '../../RadioLabel';

const ManageAxesModal = ({
    isOpen,
    setOpen,
    editedModel,
    reverseChart,
    onSave,
    // onCancel,
    timeFormat,
    timeType }) => {

    const [yAxisSettings, setYAxisSettings] = useState([])
    const [xAxisSettings, setxAxisSettings] = useState(null)
    const [chartType, setChartType] = useState('')
    const [selectedTimeFormat, setSelectedTimeFormat] = useState('')
    const [selectedTimeType, setSelectedTimeType] = useState('')
    const decimalRegEx = new RegExp(`^[+-]?((\\d+(\\.\\d*)?))$`)
    const translation = useTranslation();

    useEffect(() => {
        if (editedModel) {
            const newValues = editedModel.yAxisSettings
                ? editedModel.yAxisSettings.map(s => {
                    return {
                        unit: s.unit,
                        autoScaling: s.autoScaling,
                        min: s.min,
                        max: s.max
                    }
                })
                : []
            setYAxisSettings(newValues)
            setxAxisSettings(editedModel.xAxisSettings)
            setChartType(editedModel.chartType)
        }
    }, [editedModel, isOpen])

    useEffect(() => {
        setSelectedTimeFormat(timeFormat)
        setSelectedTimeType(timeType)
    }, [timeType, timeFormat])

    const handleCancel = () => {
        setSelectedTimeFormat(timeFormat)
        setSelectedTimeType(timeType)
        setOpen(false)
    }

    const handleSave = () => {
        setOpen(false)
        onSave(yAxisSettings, xAxisSettings, selectedTimeType, selectedTimeFormat)
    }

    const canSave = useMemo(() => {
        if (!xAxisSettings || !yAxisSettings)
            return false
        if (!xAxisSettings.autoScaling && (xAxisSettings.count <= 0 || xAxisSettings.count === undefined))
            return false
        let canSave = true
        yAxisSettings.map((setting) => {
            if (!setting.autoScaling && (isNaN(Number(setting.min)) || isNaN(Number(setting.max)) ||
                setting.min === undefined || setting.max === undefined || setting.min >= setting.max))
                canSave = false
            return setting
        })
        return canSave
    }, [xAxisSettings, yAxisSettings])

    const dialogContent = (
        <Fragment>
            <div className="formContentHolder">
                <div className="modalTitleHolder hasBackLink">
                    <h1 className='modalTitle text-center'>{translation.manageAxesModalManageAxesLabel}</h1>
                </div>
                <div className="formContent">
                    <div className='axisSettings'>
                        <div className="axisSettingsTableLike">
                            {(chartType === 'timeBased') && <>
                                <div className="tr">
                                    <div className="td main fullRow text-bold">{reverseChart ? translation.manageAxesModalYAxisLabel : translation.manageAxesModalXAxisLabel}</div>
                                </div>
                                <div className="tr innerLikeRow">
                                    <div className="td text-bold range">
                                        <RadioLabel label={translation.manageAxesModalRangeLabel} />
                                    </div>
                                    <div className="td main">
                                        <RadioGroup row aria-label="xAxisSettings" name="xAxisSettings" defaultValue="all"
                                            value={xAxisSettings && !xAxisSettings.autoScaling ? 'last' : 'all'}
                                            onChange={e => {
                                                if (e.target.value === 'last') {
                                                    setxAxisSettings({ ...xAxisSettings, autoScaling: !xAxisSettings.autoScaling, count: undefined })
                                                } else {
                                                    setxAxisSettings({ ...xAxisSettings, autoScaling: !xAxisSettings.autoScaling, count: undefined })
                                                }
                                            }}>
                                            <FormControlLabel
                                                value="all"
                                                control={<Radio color="default" />}
                                                label={<RadioLabel label={translation.manageAxesModalAllLabel} />}
                                            />
                                            <FormControlLabel
                                                value="last"
                                                control={<Radio color="default" />}
                                                label={<RadioLabel label={translation.manageAxesModalLastLabel} />}
                                            />
                                        </RadioGroup>
                                    </div>
                                    <div className="td">
                                        <div className="doubleInput hasSelect">
                                            <TextField
                                                style={{minWidth: '32px'}}
                                                inputProps={{ min: 0, max: 50000 }}
                                                disabled={xAxisSettings && xAxisSettings.autoScaling}
                                                variant="outlined"
                                                value={(xAxisSettings.autoScaling || xAxisSettings.count === undefined) ? "" : xAxisSettings.count}
                                                onChange={e => {
                                                    if (Number(e.target.value) >= e.target.min && Number(e.target.value) <= e.target.max)
                                                        setxAxisSettings({ ...xAxisSettings, count: Number(e.target.value) })
                                                }}
                                            />
                                            <TextField
                                                style={{minWidth: '53px'}}
                                                disabled={xAxisSettings && xAxisSettings.autoScaling}
                                                variant="outlined"
                                                select
                                                value={xAxisSettings && xAxisSettings.unit}
                                                onChange={e => setxAxisSettings({ ...xAxisSettings, unit: e.target.value })}
                                                className='last'
                                            >
                                                <MenuItem value='sec'>sec</MenuItem>
                                                <MenuItem value='min'>min</MenuItem>
                                                <MenuItem value='hr'>hr</MenuItem>
                                            </TextField>
                                        </div>
                                    </div>
                                </div>
                            </>
                            }
                            {(chartType === 'dataBased') &&
                                <div className="tr">
                                    <div className="td main fullRow text-bold">{reverseChart ? translation.manageAxesModalYAxisLabel : translation.manageAxesModalXAxisLabel}</div>
                                </div>}
                            {(chartType === 'dataBased') &&
                                <div className="tr hasBorderBottom innerLikeRow">
                                    <div className="td main">{xAxisSettings.unit}</div>
                                    <div className="td">
                                        <Switch
                                            checked={xAxisSettings && !xAxisSettings.autoScaling}
                                            onChange={e => {
                                                setxAxisSettings({ ...xAxisSettings, autoScaling: !xAxisSettings.autoScaling })
                                            }}
                                            color="primary" />
                                    </div>
                                    <div className="td">
                                        <div className="doubleInput">
                                            <TextField
                                                type="number"
                                                inputProps={{ min: -50000, max: 50000 }}
                                                disabled={xAxisSettings.autoScaling}
                                                variant="outlined"
                                                value={xAxisSettings.autoScaling || xAxisSettings.min === undefined ? "" : xAxisSettings.min}
                                                onChange={e => {
                                                    if (Number(e.target.value) >= e.target.min && Number(e.target.value) <= e.target.max)
                                                        setxAxisSettings({ ...xAxisSettings, min: Number(e.target.value) })
                                                }} />
                                            <TextField
                                                type="number"
                                                inputProps={{ min: -50000, max: 50000 }}
                                                className='last'
                                                disabled={xAxisSettings.autoScaling}
                                                variant="outlined"
                                                value={xAxisSettings.autoScaling || xAxisSettings.min === undefined ? "" : xAxisSettings.max}
                                                onChange={e => {
                                                    if (Number(e.target.value) >= e.target.min && Number(e.target.value) <= e.target.max)
                                                        setxAxisSettings({ ...xAxisSettings, max: Number(e.target.value) })
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            }
                            <ManageDateFormat
                                selectedTimeFormat={selectedTimeFormat}
                                setSelectedTimeFormat={setSelectedTimeFormat}
                                selectedTimeType={selectedTimeType}
                                setSelectedTimeType={setSelectedTimeType}
                                type={editedModel.type}
                                chartType={chartType}
                            />
                            <div className="tr">
                                <div className="td main text-bold" style={{marginRight: '115px'}}>{reverseChart ? translation.manageAxesModalXAxisLabel : translation.manageAxesModalYAxisLabel}</div>
                                <div className="td text-bold">{translation.manageAxesModalRangeSwitchLabel}</div>
                                <div className="td text-bold">{translation.manageAxesModalValueLabel}</div>
                            </div>
                            {
                                yAxisSettings.map((setting, index) => (
                                    <div key={setting.unit} className={index === yAxisSettings.length - 1 ? "tr innerLikeRow" : "tr innerLikeRow"}>
                                        <div className="td main text-bold" style={{marginRight: '25px'}}>{setting.unit}</div>
                                        <div className="td">
                                            <Switch
                                                checked={!setting.autoScaling}
                                                onChange={e => {
                                                    let newSettings = [...yAxisSettings]
                                                    newSettings.find(x => x.unit === setting.unit).autoScaling = !setting.autoScaling
                                                    // if (e.target.checked) {
                                                        newSettings.find(x => x.unit === setting.unit).min = undefined
                                                        newSettings.find(x => x.unit === setting.unit).max = undefined
                                                    // }
                                                    setYAxisSettings(newSettings)
                                                }
                                                }
                                                color="primary" />
                                        </div>
                                        <div className="td">
                                            <div className="doubleInput" style={{maxWidth: '100px'}}>
                                                <TextField
                                                    inputProps={{ min: -50000, max: 50000 }}
                                                    disabled={setting.autoScaling}
                                                    variant="outlined"
                                                    value={setting.autoScaling || setting.min === undefined ? '' : setting.min}
                                                    onChange={e => {
                                                        let newSettings = [...yAxisSettings]
                                                        const currSetting = newSettings.find(x => x.unit === setting.unit)
                                                        if (e.target.value === '')
                                                            currSetting.min = undefined
                                                        if (e.target.value === '-' ||
                                                            (decimalRegEx.test(e.target.value) && Number(e.target.value) >= e.target.min && Number(e.target.value) <= e.target.max))
                                                            currSetting.min = e.target.value
                                                        setYAxisSettings(newSettings)
                                                    }}
                                                    onBlur={e => {
                                                        let newSettings = [...yAxisSettings]
                                                        const currSetting = newSettings.find(x => x.unit === setting.unit)
                                                        if (!isNaN(Number(e.target.value)) && e.target.value !== '')
                                                            currSetting.min = Number(e.target.value)
                                                        else
                                                            currSetting.min = undefined
                                                        setYAxisSettings(newSettings)
                                                    }} />
                                                <TextField
                                                    inputProps={{ min: -50000, max: 50000 }}
                                                    className='last'
                                                    disabled={setting.autoScaling}
                                                    variant="outlined"
                                                    value={setting.autoScaling || setting.max === undefined ? "" : setting.max}
                                                    onChange={e => {
                                                        let newSettings = [...yAxisSettings]
                                                        const currSetting = newSettings.find(x => x.unit === setting.unit)
                                                        if (e.target.value === '')
                                                            currSetting.max = undefined
                                                        if (e.target.value === '-' ||
                                                            (decimalRegEx.test(e.target.value) && Number(e.target.value) >= e.target.min && Number(e.target.value) <= e.target.max)) {
                                                            currSetting.max = e.target.value
                                                        }
                                                        setYAxisSettings(newSettings)
                                                    }}
                                                    onBlur={e => {
                                                        let newSettings = [...yAxisSettings]
                                                        const currSetting = newSettings.find(x => x.unit === setting.unit)
                                                        if (!isNaN(Number(e.target.value)) && e.target.value !== '') {
                                                            currSetting.max = Number(e.target.value)
                                                        } else {
                                                            currSetting.max = undefined
                                                        }
                                                        setYAxisSettings(newSettings)
                                                    }} />
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
                <div className="btnHolder end fixedWidthBtn">
                    <Button variant="outlined" onClick={handleCancel}>{translation.cancelButtonLabel}</Button>
                    <Button
                        disabled={!canSave}
                        variant="contained"
                        onClick={handleSave}
                        color="primary">
                        {translation.saveButtonLabel}
                    </Button>
                </div>
            </div>
        </Fragment>
    )

    return <Dialog open={isOpen} className='respModal scrollContentModal autoHeight'>{dialogContent}</Dialog>

}

export default ManageAxesModal
