import React, { useState, useEffect, useMemo } from 'react'
import MmsService from 'Services/MMS/mms-api.service'
import CircularProgress from '@material-ui/core/CircularProgress'
import Fade from '@material-ui/core/Fade'
import { MenuItem } from '@material-ui/core'
import AlertToastr from 'utils/alert'
import { setComponents } from '../../../Store/modules/mms/actions'
import { connect } from 'react-redux'
import useTranslation from 'Hooks/useTranslation'
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Popover from "@material-ui/core/Popover";

const ArrowLeft = () => <div className='arrowLeftIcon' />
const ArrowRight = () => <div className='arrowRightIcon' />

const RecommendedSparePartsComponentBlock = ({ parts, currentDepartment, components, rspSelectedComponentsIds }) => {
    const translation = useTranslation();
    const [recommendedParts, setRecommendedParts] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [firstIndex, setFirstIndex] = useState(0)
    const [lastIndex, setLastIndex] = useState(0)
    const [totalRows, setTotalRows] = useState(0)
    const [rowsOnPage, setRowsOnPage] = useState(20)
    const [pageNumber, setPageNumber] = useState(1)
    const [anchorEl, onAnchorElChange] = useState(null)

    useEffect(() => {
        const fetchRecommendedParts = async () => {
            try {
                const response = await MmsService.getRecommendedSpareParts(currentDepartment.id, rspSelectedComponentsIds)
                setIsLoading(false)
                setRecommendedParts(response.data)
            }
            catch (error) {
                setIsLoading(false)
                if (error && error.response && error.response.data && error.response.data.message)
                    AlertToastr.showErrorAlert(error.response.data.message)
            }
        }
        if (rspSelectedComponentsIds.length === 0) {
            setRecommendedParts([])
            return
        }
        setIsLoading(true)
        fetchRecommendedParts()
    }, [currentDepartment, rspSelectedComponentsIds])

    const pageData = useMemo(() => {
        let pageData = rspSelectedComponentsIds.length ? [...recommendedParts] : [];
        const totalLength = pageData.length

        if ((pageNumber - 1) * rowsOnPage + 1 > totalLength) {
            setPageNumber(1)
        }
        setTotalRows(totalLength)
        setFirstIndex(Math.min(((pageNumber - 1) * rowsOnPage + 1), totalLength))
        setLastIndex(Math.min((pageNumber * rowsOnPage), totalLength))
        let tableData = pageData.splice((pageNumber - 1) * rowsOnPage, Math.min((pageNumber * rowsOnPage), totalLength))
        return [...tableData]
    }, [
        rspSelectedComponentsIds,
        recommendedParts,
        rowsOnPage,
        pageNumber
    ])

    const closePaginationMenu = () => {
        onAnchorElChange(null)
    }

    const handleRowCount = (count) => {
        setRowsOnPage(count)
        setPageNumber(1)
        closePaginationMenu()
    }

    const onNextPage = () => {
        setPageNumber(pageNumber + 1)
        window.scrollTo(0, 0)
    }

    const onPrevPage = () => {
        setPageNumber(pageNumber - 1)
        window.scrollTo(0, 0)
    }

    const renderPagination = () => {
        if (!pageData || !pageData.length) return null

        return (
            <div className="tableControlsHolder">
                <div className="sortHolder">
                    <div className="caption">Rows per page:</div>
                    <Button onClick={(e) => onAnchorElChange(e.target)}
                            endIcon={<ArrowDropDownIcon />}
                            className='dropDownBtn'>
                        {rowsOnPage}
                    </Button>

                    <Popover
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={closePaginationMenu}
                        disableScrollLock={true}
                        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }}>
                        <MenuItem onClick={() => handleRowCount(20)}>20</MenuItem>
                        <MenuItem onClick={() => handleRowCount(50)}>50</MenuItem>
                        <MenuItem onClick={() => handleRowCount(100)}>100</MenuItem>
                    </Popover>
                </div>
                <div className="paginationHolder mms">
                    <div className="caption">{`${firstIndex}-${lastIndex} of ${totalRows}`}</div>
                    <div className="paginationBtn">
                        <IconButton
                            disabled={firstIndex === 1 || firstIndex === 0}
                            onClick={() => onPrevPage()} >
                            <ArrowLeft />
                        </IconButton>
                        <IconButton
                            disabled={lastIndex === totalRows}
                            onClick={() => onNextPage()}>
                            <ArrowRight />
                        </IconButton>
                    </div>
                </div>
            </div>
        )
    }

    const renderEmptyListText = () => {
        if (pageData && pageData.length) return null
        return (
            <p className="textGray text-center">{translation.recommendedSparePartsEmptyListPlaceholderLabel}</p>
        )
    }

    return (
        <>
            <div className="subTitleWithFilter mb15">
                <p className="textGray">{translation.saveComponentModalRecommendedSparePartsLabel}</p>
            </div>
            <div className="pmTableHolder mb15">
                <table className="pmTable">
                    <thead>
                        <tr>
                            <th>{translation.equipmentPageRecommendedSparePartsPartHeader}</th>
                            <th>{translation.equipmentPageRecommendedSparePartsManufacturerHeader}</th>
                            {/* <th>{translation.equipmentPageRecommendedSparePartsManufacturerTypeHeader}</th> */}
                            {/* <th>{translation.equipmentPageRecommendedSparePartsManufacturerPartNumberHeader}</th> */}
                            <th>{translation.activitiesPagePartsTableAlternativePartHeader}</th>
                            <th>{translation.equipmentPageRecommendedSparePartsQuantityHeader}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {pageData ? pageData.map(part => {
                                let altPartLabel = '-'
                                if (parts && parts.length && part.alternativePartId) {
                                    const altPartModel = parts.find(item => item.id === part.alternativePartId);
                                    if (altPartModel) {
                                        altPartLabel  = altPartModel.fullName;
                                    }
                                }

                                return (
                                    <tr key={part.id}>
                                        <td className='text-semi-bold'>{part.fullName}</td>
                                        <td>{part.manufacturerName || '-'}</td>
                                        {/* <td>{part.manufacturerType || '-'}</td> */}
                                        {/* <td>{part.manufacturerPartNumber || '-'}</td> */}
                                        <td>{altPartLabel}</td>
                                        <td>{part.quantity || '-'}</td>
                                    </tr>
                                )
                            }) : null}
                    </tbody>
                </table>
                <div className='loaderHolder'>
                    <Fade
                        in={isLoading}
                        unmountOnExit>
                        <CircularProgress />
                    </Fade>
                </div>
            </div>
            {renderEmptyListText()}
            {renderPagination()}
        </>
    )
}

const mapStateToProps = ({ mms }) => ({
    currentDepartment: mms.currentDepartment,
    components: mms.components,
    parts: mms.parts
})

const mapDispatchToProps = {
    setComponents,
}

export default connect(mapStateToProps, mapDispatchToProps)(RecommendedSparePartsComponentBlock)
