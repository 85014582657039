export default class User {
    constructor({ name, role, permissions, companyId, isOneJobPassword, serverPermissions, defaultCompanyId }) {

        this.name = name;
        this.role = role;
        this.companyId = companyId;
        this.isOneJobPassword = isOneJobPassword;
        this.defaultCompanyId = defaultCompanyId;

        if (!permissions) {
            this.permissions = [];
        } else {
            this.permissions = typeof permissions === 'string' ? [permissions] : permissions;
        }

        if (!serverPermissions) {
            this.serverPermissions = [];
        } else {
            this.serverPermissions = typeof serverPermissions === 'string' ? [serverPermissions] : serverPermissions;
        }
    }

    isSuperuser() {
        return this.role === 'superuser' || this.role === 'master';
    }

    isRoot() {
        return this.role === 'root';
    }

    isMaster() {
        return this.role === 'master';
    }

    isAdmin() {
        return this.role === 'admin';
    }

    canExportCsv() {
        return this.permissions.includes('export_csv');
    }

    canManageMasters() {
        return this.permissions.includes('data_usage');
    }

    canManageTemplates() {
        return this.permissions.includes('manage_templates');
    }

    allowDataUsage() {
        return this.permissions.includes('data_usage');
    }

    allowMms() {
        return this.permissions.includes('mms') && this.serverPermissions.includes('mms');
    }

    allowUiMms() {
        return this.permissions.includes('mms');
    }
}
