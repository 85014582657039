import React, { useEffect, useMemo, useState } from 'react'
import { Link, NavLink, useHistory } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import { Box, MenuItem, TextField } from '@material-ui/core'
import Drawer from '@material-ui/core/Drawer'
import MenuIcon from '@material-ui/icons/Menu'
import IconButton from '@material-ui/core/IconButton'
import { userHasPermission } from 'helpers'
import { clearDashboard, fetchJobsSuccess, setCompanies, setCurrentCompany } from 'Store/modules/dashboard/actions'
import { connect } from 'react-redux'
import Popover from '@material-ui/core/Popover'
import { useAuth } from 'Contexts/AuthProvider'
import { Redirect } from 'react-router'
import dmsApiService from 'Services/DMS/dms-api.service'
import { setDepartments, setEmployees, setCurrentDepartment } from 'Store/modules/mms/actions'
import useTranslation from 'Hooks/useTranslation'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Collapse from '@material-ui/core/Collapse'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import 'moment/min/locales'
import moment from 'moment'
import 'react-circular-progressbar/dist/styles.css'
import AlertToastr from '../../utils/alert'
import { getStoredCompany, setStoredCompany } from '../../storage/company-storage'
import UserAvatar from '../UserAvatar'
import { appName } from 'config'
import { setStoredDepartment } from '../../storage/department-storage'
import MyIcons from 'assets/img/Icons/MyIcons'
import DeleteItemModal from 'Components/modals/delete-item-modal/delete-item-modal.component'
import EventEmitter from '../../utils/EventEmitter'

const UserIcon = ({ name = 'John Doe' }) => <UserAvatar name={name}/>
const Header = ({
                  clearDashboard,
                  departments,
                  setCurrentDepartment,
                  companies,
                  currentCompany,
                  setCurrentCompany,
                  fetchJobsSuccess,
                  setCompanies,
                  // setDepartments,
                  setEmployees,
                }) => {

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  })

  const history = useHistory()
  const { isLoggedIn, currentUser, logout } = useAuth()
  const translation = useTranslation()

  const [redirectToChangePassword, setRedirectToChangePassword] = useState(false)
  const allowUsers = useMemo(() => userHasPermission(currentUser, 'users'), [currentUser])
  const allowMms = useMemo(() => currentUser.allowUiMms(), [currentUser])
  const allowServerMms = useMemo(() => currentUser.allowMms(), [currentUser])
  const allowDataUsage = useMemo(() => currentUser.allowDataUsage(), [currentUser])
  const isSuperUser = useMemo(() => currentUser.isSuperuser(), [currentUser])
  const showCompanySelector = useMemo(() => currentUser.isSuperuser() && !currentUser.isMaster(), [currentUser])
  const isNotOneJobPassword = useMemo(() => (currentUser && !currentUser.isOneJobPassword), [currentUser])

  const [isOpenNavMenu, setIsOpenNavMenu] = useState(false)
  const [isOpenSettingsMenu, setIsOpenSettingsMenu] = useState(false)
  const [isOpenChangeCompanyMenu, setIsOpenChangeCompanyMenu] = useState(false)
  const [isOpenChangeDepartmentMenu, setIsOpenChangeDepartmentMenu] = useState(false)
  const [isOpenMyCompanyMenu, setIsOpenMyCompanyMenu] = useState(false)
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false)

  useEffect(() => {
    let locale
    if (navigator.languages !== undefined)
      locale = navigator.languages[0]
    else
      locale = navigator.language
    moment.locale(locale)
  }, [])

  useEffect(() => {
    async function fetchCompanies () {
      let res = await dmsApiService.getCompanies()
      setCompanies(res)
      if (res.length === 0)
        return
      const storedCompanyId = getStoredCompany()
      const storedCompanyModel = res.find(company => company.id === storedCompanyId)
      let defaultCompany
      if (storedCompanyModel) {
        defaultCompany = storedCompanyModel
      } else {
        if (currentUser.defaultCompanyId) {
          defaultCompany = res.find(company => company.id === currentUser.defaultCompanyId)
        }
        if (!defaultCompany) {
          defaultCompany = res[0]
        }
        setStoredCompany(defaultCompany.id)
      }
      setCurrentCompany(defaultCompany)
      // let jobsRes = await dmsApiService.getCompanyJobsShort(defaultCompany.id)
      // fetchJobsSuccess(jobsRes)
    }

    async function fetchJobs () {
      let jobsRes = await dmsApiService.getJobsShort()
      fetchJobsSuccess(jobsRes)
    }

    if (currentCompany)
      return
    if (isSuperUser) {
      fetchCompanies().then()
    } else {
      fetchJobs().then()
    }

  }, [currentUser, isSuperUser, fetchJobsSuccess, setCurrentCompany, setCompanies, currentCompany])

  useEffect(() => {
    async function fetchJobs () {
      let jobsRes = await dmsApiService.getCompanyJobsShort(currentCompany.id)
      fetchJobsSuccess(jobsRes)
    }

    if (currentCompany)
      fetchJobs().then()
  }, [currentCompany, fetchJobsSuccess])

  const toggleDrawer = (side, open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }

    setState({ ...state, [side]: open })
  }
  const toggleDrawer2 = (side, open) => {
    setState({ ...state, [side]: open })
  }

  const t = useTranslation()
  const { dbSizeInfo } = useAuth()
  const label = dbSizeInfo ? t.formatString(t.dbSizeInfo, {
    used: dbSizeInfo?.sizeGb,
    limit: dbSizeInfo?.limitGb
  }) : ''

  const sideList = side => (
    <div className="menuListWrapper">
      <ul className="mainMenu mobile">
        <List>
          <ListItem onClick={e => {
            toggleDrawer2(side, false)
            history.push('/')
          }}
                    onKeyDown={toggleDrawer(side, false)}><MyIcons icon="jobsIcon"/>&nbsp;&nbsp;
            <ListItemText primary={translation.headerDmsCloudLabel}/>
          </ListItem>
          {
            isNotOneJobPassword && (
              <ListItem onClick={e => {
                toggleDrawer2(side, false)
                history.push('/units')
              }}
                        onKeyDown={toggleDrawer(side, false)}><MyIcons icon="unitsIcon"/>&nbsp;&nbsp;
                <ListItemText primary={translation.headerUnitsLabel}/>
              </ListItem>
            )
          }
          {
            isNotOneJobPassword && (
              <ListItem onClick={e => {
                toggleDrawer2(side, false)
                history.push('/locations')
              }}
                        onKeyDown={toggleDrawer(side, false)}><MyIcons icon="locationIcon"/>&nbsp;&nbsp;
                <ListItemText primary={translation.headerLocationsLabel}/>
              </ListItem>
            )
          }
          {renderMobileMmsLink()}
          <Collapse in={isOpenNavMenu} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem onClick={e => {
                setIsOpenMyCompanyMenu(!isOpenMyCompanyMenu)
              }}
                        onKeyDown={toggleDrawer(side, false)}>
                <ListItemText primary={translation.siderMyCompanyLabel}/>
                {isOpenMyCompanyMenu ? <ExpandLess/> : <ExpandMore/>}
              </ListItem>
              <Collapse in={isOpenMyCompanyMenu} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem onClick={e => {
                    toggleDrawer2(side, false)
                    history.push('/companies')
                  }}
                            onKeyDown={toggleDrawer(side, false)}>
                    <ListItemText primary={translation.siderCompanyLabel}/>
                  </ListItem>
                </List>
                <List component="div" disablePadding>
                  <ListItem onClick={e => {
                    toggleDrawer2(side, false)
                    history.push('/departments')
                  }}
                            onKeyDown={toggleDrawer(side, false)}>
                    <ListItemText primary={translation.myCompanyDepartmentsLabel}/>
                  </ListItem>
                </List>
                <List component="div" disablePadding>
                  <ListItem onClick={e => {
                    toggleDrawer2(side, false)
                    history.push('/employees')
                  }}
                            onKeyDown={toggleDrawer(side, false)}>
                    <ListItemText primary={translation.myCompanyEmployeesLabel}/>
                  </ListItem>
                </List>
                <List component="div" disablePadding>
                  <ListItem onClick={e => {
                    toggleDrawer2(side, false)
                    history.push('/parts')
                  }}
                            onKeyDown={toggleDrawer(side, false)}>
                    <ListItemText primary={translation.equipmentPagePartsLabel}/>
                  </ListItem>
                </List>
              </Collapse>


              <ListItem onClick={e => {
                setIsOpenChangeDepartmentMenu(!isOpenChangeDepartmentMenu)
              }}
                        disabled={!departments || departments.length === 0}>
                <ListItemText primary={translation.popoverChangeDepartmentLabel}/>
                {isOpenChangeDepartmentMenu ? <ExpandLess/> : <ExpandMore/>}
              </ListItem>
              <Collapse in={isOpenChangeDepartmentMenu} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {departments && departments.map(department =>
                    <ListItem
                      key={department.id}
                      value={department}
                      onClick={e => {
                        toggleDrawer2(side, false)
                        setCurrentDepartment(department)
                        setStoredDepartment(department.id)
                      }}
                      onKeyDown={toggleDrawer(side, false)}
                      className="changeDepartmentItem"
                    >{department.name}
                    </ListItem>)}
                </List>
              </Collapse>


              <ListItem onClick={e => {
                toggleDrawer2(side, false)
                history.push('/equipment')
              }}
                        onKeyDown={toggleDrawer(side, false)}>
                <ListItemText primary={translation.siderEquipmentLabel}/>
              </ListItem>
              {/* {isSuperUser && <ListItem onClick={e => {
                                toggleDrawer2(side, false);
                                history.push('/catalog');
                            }}
                                                      onKeyDown={toggleDrawer(side, false)}>
                                <ListItemText primary={translation.siderCatalogLabel} />
                            </ListItem>} */}
              <ListItem onClick={e => {
                toggleDrawer2(side, false)
                history.push('/unassigned-components')
              }}
                        onKeyDown={toggleDrawer(side, false)}>
                <ListItemText primary={translation.siderUnassignedComponentsLabel}/>
              </ListItem>
              <ListItem onClick={e => {
                toggleDrawer2(side, false)
                history.push('/activities')
              }}
                        onKeyDown={toggleDrawer(side, false)}>
                <ListItemText primary={translation.siderActivitiesLabel}/>
              </ListItem>
              <ListItem onClick={e => {
                toggleDrawer2(side, false)
                history.push('/reports')
              }}
                        onKeyDown={toggleDrawer(side, false)}>
                <ListItemText primary={translation.siderScheduledReportsLabel}/>
              </ListItem>
              {/* <ListItem onClick={e => {
                toggleDrawer2(side, false)
                history.push('/notifications')
              }}
                        onKeyDown={toggleDrawer(side, false)}>
                <ListItemText primary={translation.siderNotificationsLabel}/>
              </ListItem> */}
            </List>
          </Collapse>
          <ListItem onClick={e => {
            setIsOpenSettingsMenu(!isOpenSettingsMenu)
          }}><MyIcons icon="settingIcon"/>&nbsp;&nbsp;
            <ListItemText primary={translation.headerSettings}/>
            {isOpenSettingsMenu ? <ExpandLess/> : <ExpandMore/>}
          </ListItem>
          <Collapse in={isOpenSettingsMenu} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem onClick={e => {
                toggleDrawer2(side, false)
                history.push('/settings')
              }}
                        onKeyDown={toggleDrawer(side, false)}>
                <ListItemText primary={translation.settingsTablesUsersLabel}/>
              </ListItem>
              <ListItem onClick={e => {
                toggleDrawer2(side, false)
                history.push('/settings/one-job-password')
              }}
                        onKeyDown={toggleDrawer(side, false)}>
                <ListItemText primary={translation.settingsTablesOneJobPasswordsLabel}/>
              </ListItem>
              <ListItem onClick={e => {
                toggleDrawer2(side, false)
                history.push('/settings/db-management')
              }}
                        onKeyDown={toggleDrawer(side, false)}>
                <ListItemText primary={translation.settingsDbManagementLabel}/>
              </ListItem>
              <ListItem onClick={e => {
                toggleDrawer2(side, false)
                history.push('/settings/templates-management')
              }}
                        onKeyDown={toggleDrawer(side, false)}>
                <ListItemText primary={translation.settingsTemplatesManagementLabel}/>
              </ListItem>

            </List>
          </Collapse>
          {/* {allowUsers && <ListItem onClick={e => {
                        toggleDrawer2(side, false);
                        history.push('/settings');
                    }}
                                             onKeyDown={toggleDrawer(side, false)}>
                        <ListItemText primary={translation.headerSettings} />
                    </ListItem>} */}
          {
            allowDataUsage && (
              <ListItem onClick={e => {
                toggleDrawer2(side, false)
                history.push('/data-usage')
              }}
                        onKeyDown={toggleDrawer(side, false)}><MyIcons icon="dataUsageIcon"/>&nbsp;&nbsp;
                <ListItemText primary={translation.settingsDataUsageLabel}/>
              </ListItem>
            )
          }
          {showCompanySelector && (
            <>
              <ListItem onClick={e => {
                setIsOpenChangeCompanyMenu(!isOpenChangeCompanyMenu)
              }}><MyIcons icon="apartmentIcon"/>&nbsp;&nbsp;
                <ListItemText primary={translation.headerChangeCompanyLabel}/>
                {isOpenChangeCompanyMenu ? <ExpandLess/> : <ExpandMore/>}
              </ListItem>
              <Collapse in={isOpenChangeCompanyMenu} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {companies && companies.map((company) => <ListItem key={company.id}
                                                                     value={company}
                                                                     onClick={e => {
                                                                       toggleDrawer2(side, false)
                                                                       setStoredCompany(company.id)
                                                                       setCurrentCompany(company)
                                                                       clearDashboard()
                                                                       setDepartments(null)
                                                                       setEmployees(null)
                                                                     }}
                                                                     className="changeCompanyItem"
                  >{company.name}</ListItem>)}
                </List>
              </Collapse>
            </>
          )}
        </List>
        < div className="mobileMenuFooter">
          <hr className="mobileMenuLine"></hr>
          {currentUser && !currentUser.isOneJobPassword &&
            <ListItem onClick={() => setRedirectToChangePassword(true)}
            ><MyIcons icon="passwordIcon"/>&nbsp;&nbsp;<ListItemText
              primary={translation.headerMenuChangePasswordLabel}/></ListItem>
          }
          <ListItem href="#/action-2" onClick={handleSignOut}
          ><MyIcons icon="logOutIcon"/>&nbsp;&nbsp;<ListItemText
            primary={translation.headerMenuSignOutLabel}/></ListItem>
          <ListItem>
            <div className="mobileCopyright">{label}&nbsp;&nbsp;@{appName}</div>
          </ListItem>
        </div>
      </ul>
    </div>
  )

  const handleSignOut = () => {
    setIsOpenDeleteModal(true)
  }

  const handleDeleteModalConfirm = () => {
    clearDashboard()
    logout()
  }

  const [userInfoMenuAchorEl, onUserInfoMenuAnchorElChange] = useState(null)
  const closeUserInfoMenu = () => {
    onUserInfoMenuAnchorElChange(null)
  }

  if (redirectToChangePassword)
    return <Redirect to="/change-password"/>

  const renderMmsLink = () => {

    if (allowServerMms) {
      return (
        <li>
          <NavLink
            isActive={(match, location) =>
              location.pathname === '/companies' ||
              location.pathname === '/departments' ||
              location.pathname === '/employees' ||
              location.pathname === '/parts' ||
              location.pathname === '/equipment' ||
              location.pathname === '/catalog' ||
              location.pathname === '/activities' ||
              location.pathname === '/reports'

            }
            to="/companies"
            id="mainHeaderMmsBtn"
            exact activeClassName="active">
            {translation.headerMmsLabel}
          </NavLink>
        </li>
      )
    }

    if (allowMms) {
      return (
        <li>
          <NavLink
            onClick={e => {
              e.preventDefault()
              AlertToastr.showErrorAlert(translation.mmsAccessError)
            }}
            to="/companies"
            exact activeClassName="active">
            {translation.headerMmsLabel}
          </NavLink>
        </li>
      )
    }

    return null
  }

  const renderMobileMmsLink = () => {
    if (allowServerMms) {
      return (
        <ListItem onClick={e => {
          setIsOpenNavMenu(!isOpenNavMenu)
        }}><MyIcons icon="domainIcon"/>&nbsp;&nbsp;
          <ListItemText primary={translation.headerMmsLabel}/>
          {isOpenNavMenu ? <ExpandLess/> : <ExpandMore/>}
        </ListItem>
      )
    }

    if (allowMms) {
      return (
        <ListItem onClick={e => {
          e.preventDefault()
          AlertToastr.showErrorAlert(translation.mmsAccessError)
        }}>
          <ListItemText primary={translation.headerMmsLabel}/>
        </ListItem>
      )
    }
  }

  return (
    <header className="mainHeader">
      <div className="wrapper align-center">
        <div className="mainMenuHolder">
          <Link to="/" className="logo"/>
        </div>
        {/*<DbSizeInfo/>*/}
        <Box display="flex">
          <ul className="mainMenu">
            <li>
              <NavLink
                to="/"
                exact
                activeClassName="active"
              >
                {translation.headerDmsCloudLabel}
              </NavLink>
            </li>
            {
              isNotOneJobPassword && (
                <li>
                  <NavLink
                    to="/units"
                    exact
                    activeClassName="active"
                  >
                    {translation.headerUnitsLabel}
                  </NavLink>
                </li>
              )
            }
            {
              isNotOneJobPassword && (
                <li>
                  <NavLink
                    to="/locations"
                    exact
                    activeClassName="active"
                  >
                    {translation.headerLocationsLabel}
                  </NavLink>
                </li>
              )
            }

            {renderMmsLink()}
            {allowUsers &&
              <li>
                <NavLink
                  to="/settings"
                  activeClassName="active"
                >
                  {translation.headerSettings}
                </NavLink>
              </li>}
            {allowDataUsage &&
              <li>
                <NavLink
                  to="/data-usage"
                  activeClassName="active"
                >
                  {translation.settingsDataUsageLabel}
                </NavLink>
              </li>}
          </ul>
          {isLoggedIn && (
            <div className="userInfoHolder">
              {showCompanySelector && (
                <div className="changeCompanyHeader">
                  {currentCompany && <TextField
                    variant="outlined"
                    margin="none"
                    select
                    fullWidth
                    label={translation.headerCompanyLabel}
                    name="company"
                    onChange={e => {

                      const company = e.target.value
                      setStoredCompany(company.id)
                      setCurrentCompany(company)
                      EventEmitter.emit('CompanyChanged', company)
                      clearDashboard()
                      setDepartments(null)
                      setEmployees(null)
                      window.location.reload();
                    }}
                    value={currentCompany}
                  >
                    {companies && companies.map((company) => <MenuItem key={company.id}
                                                                       value={company}
                                                                       className="filterMenuItem">{company.name}</MenuItem>)}
                  </TextField>
                  }
                </div>
              )}
              <Button className="dropDownBtn desktop"
                      onClick={(e) => onUserInfoMenuAnchorElChange(e.target)}
                // onClick={() => history.push('/company-settings')}
                      startIcon={<UserIcon name={currentUser?.name}/>}>
                <div className="name">{currentUser && currentUser.name}</div>
              </Button>
              <IconButton className="dropDownBtn mobile"
                          onClick={(e) => onUserInfoMenuAnchorElChange(e.target)}>
                <UserIcon name={currentUser?.name}/>
              </IconButton>
              <Popover
                anchorEl={userInfoMenuAchorEl}
                keepMounted
                open={Boolean(userInfoMenuAchorEl)}
                onClose={closeUserInfoMenu}
                disableScrollLock={true}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
              >
                {currentUser && !currentUser.isOneJobPassword &&
                  <MenuItem onClick={() => setRedirectToChangePassword(true)}
                            className="filterMenuItem">{translation.headerMenuChangePasswordLabel}</MenuItem>
                }
                <MenuItem href="#/action-2" onClick={handleSignOut}
                          className="filterMenuItem">{translation.headerMenuSignOutLabel}</MenuItem>
              </Popover>
            </div>
          )}
        </Box>

        <div className={'mobileDrawer'}>
          <IconButton onClick={toggleDrawer('left', true)}>
            <MenuIcon/>
          </IconButton>
          <Drawer open={state.left} onClose={toggleDrawer('left', false)}>
            {sideList('left')}
          </Drawer>
        </div>
      </div>
      <DeleteItemModal
        isOpen={isOpenDeleteModal}
        isOpenManager={setIsOpenDeleteModal}
        message={translation.headerMenuSignOutMessageLabel}
        onDeleteModalClose={handleDeleteModalConfirm}
        confirmButtonLabel={translation.headerMenuSignOutBtnLabel}
      />

    </header>
  )
}

const mapDispatchToProps = {
  clearDashboard,
  setCurrentCompany,
  setCurrentDepartment,
  fetchJobsSuccess,
  setCompanies,
  setDepartments,
  setEmployees,
}

const mapStateToProps = ({ mms, dashboard }) => ({
  departments: mms.departments,
  companies: dashboard.companies,
  currentCompany: dashboard.currentCompany,
})

export default connect(mapStateToProps, mapDispatchToProps)(Header)
