import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import { ArctionDashboard, arctionHelper } from '../../ArctionDashboard';
import { useAppSettings } from '../../Contexts/AppSettingsProvider';
// import { AxisScrollStrategies } from '@arction/lcjs';
import { formatPointTime } from '../../utils/functions';
import { ColorHEX, SolidFill } from '@arction/lcjs';
import { useBoxes } from '../../Contexts/BoxContext';
import { chartHelper } from '../../helpers';
// import { chartHelper } from '../../helpers';

const UnitTimeBasedChart = props => {

    const {
        id,
        startTime,
        chartHeight,
        channels,
        channelsData,
        timeType,
        timeFormat,
        dateTimeFormat,
        yAxisSettings,
        xAxisSettings,
    } = props;


    const { appTheme: theme } = useAppSettings();
    const refLegendLayout = useRef(undefined);
    const [, setDashboard] = useState(null);
    const [chart, setChart] = useState(null);
    const [series, setSeries] = useState({});
    const [groupUnits] = useState(true);
    const [, setLegend] = useState(undefined);
    const [dataCounter, setDataCounter] = useState(0);
    const [autoFitXDisabled, setAutoFitXDisabled] = useState(false);
    const [autoFitYDisabled, setAutoFitYDisabled] = useState(false);
    const [autoFitYDisabledKeys, setAutoFitYDisabledKeys] = useState(null);
    const {unitCodeColors, updateChartDateRange} = useBoxes();

    // const timeZone = useMemo(() => job ? job.timeZone : null, []);
    const timeZone = useMemo(() => null, []);

    useEffect(() => {
        if (!chartHeight) return;
        if (!startTime) return;

        //**sync channels with colors**
        const channelColorMap = {};
        let hasChannelWithoutColor = false;
        channels.forEach((channel) => {
            const color = chartHelper.colors(channel.code, unitCodeColors)
            if (color) {
                channelColorMap[channel.code] = color;
            } else {
                hasChannelWithoutColor = true;
            }
        })

        if (hasChannelWithoutColor) return;
        //**sync channels with colors - end**

        const originDate = new Date(startTime);

        const arction = new ArctionDashboard({
            id,
            theme,
            height: chartHeight,
            startTime: originDate,
            updateChartDateRange
        });

        const dashboard = arction.createDashboard();
        const chart = arction.createChart();

        const groupedUnitAxes = {};
        const ungroupUnitAxes = [];
        const series = {};

        //mapping channel id -> index: uses for series colors
        // const sortedChannelIndexes = {};
        // const sortedChannelIds = channelsData.map(chData => chData.channel.id).sort();
        // sortedChannelIds.forEach((id, index) => {
        //     sortedChannelIndexes[id] = index;
        // });

        channels.forEach((channel, index) => {
            let channelAxes;

            if (groupUnits) {
                if (!groupedUnitAxes[channel.unit]) {
                    groupedUnitAxes[channel.unit] = arction.addYAxis({
                        opposite: !!(Object.keys(groupedUnitAxes).length % 2),
                        title: `[${channel.unit}]`,
                    });
                }
                channelAxes = groupedUnitAxes[channel.unit];
            } else {
                channelAxes = arction.addYAxis({
                    opposite: !!(ungroupUnitAxes.length % 2),
                    title: `${channel.nameLocalized} [${channel.unit}]`,
                });
                ungroupUnitAxes.push(channelAxes);
            }

            series[channel.code] = chart
                .addLineSeries({
                    dataPattern: { pattern: 'ProgressiveX' },
                    yAxis: channelAxes,
                })
                .setStrokeStyle(style => style.setFillStyle(new SolidFill({ color: ColorHEX(channelColorMap[channel.code]) })))
                .setName(`${channel.nameLocalized} [${channel.unit}]`);
        });

        setDashboard(dashboard);
        setChart(chart);
        setSeries(series);

        dashboard.engine.layout();
        chart.engine.layout();

        return () => {
            chart.dispose();
            dashboard.dispose();
            setLegend(undefined);
            setSeries(undefined);
            setChart(undefined);
            setDashboard(undefined);
        };
    }, [id, chartHeight, theme, channels, groupUnits, startTime, unitCodeColors, updateChartDateRange]);

    useEffect(() => {
        if (!chart) return;
        if (!startTime) return;

        let timeDiff = null;
        if (timeType === 'elapsed') {
            timeDiff = startTime
        }

        const vcb = (value) => {
            return formatPointTime(
                value,
                timeType,
                timeFormat,
                timeDiff,
                timeZone,
                dateTimeFormat,
            );
        };

        arctionHelper.changeDateTimeFormat(chart, vcb);
    }, [
        chart,
        startTime,
        timeType,
        timeFormat,
        dateTimeFormat,
        timeZone,
    ]);



    useEffect(() => {
        if (!chart) return;
        if (!yAxisSettings || !yAxisSettings.length) return;

        arctionHelper.applyAxisSettings(chart, {
            xAxisSettings,
            yAxisSettings,
            autoFitXDisabled,
            autoFitYDisabled,
            autoFitYDisabledKeys,
        })

        const axesDrag = [];
        chart.forEachAxisY(a => {
            const dragToken = a.onAxisInteractionAreaMouseDragStart((axis) => {
                const axisTitle = axis.getTitle();
                setAutoFitYDisabledKeys(prevState => {
                    if (prevState) {
                        return { ...prevState, [axisTitle]: true }
                    }
                    return {[axisTitle]: true }
                });
            });

            axesDrag.push({
                axis: a,
                token: dragToken,
            });
        });

        const onDragToken = chart.onSeriesBackgroundMouseDrag(() => {
            setAutoFitXDisabled(true);
            setAutoFitYDisabled(true);
        });

        const seriesBackgroundMouseDoubleClickToken = chart.onSeriesBackgroundMouseDoubleClick(handler => {
            setAutoFitXDisabled(false);
            setAutoFitYDisabled(false);
            setAutoFitYDisabledKeys(null);

            // arctionHelper.applyAxisSettings(chart, {
            //     xAxisSettings,
            //     yAxisSettings,
            //     autoFitXDisabled: false,
            //     autoFitYDisabled: false,
            //     autoFitYDisabledKeys: {},
            // })
        });

        return () => {
            axesDrag.forEach(i => {
                i.axis.offAxisInteractionAreaMouseDragStart(i.token);
            });
            chart.offSeriesBackgroundMouseDoubleClick(seriesBackgroundMouseDoubleClickToken);
            chart.offSeriesBackgroundMouseDrag(onDragToken);
        };

    }, [
        dataCounter,
        autoFitXDisabled,
        autoFitYDisabled,
        autoFitYDisabledKeys,
        xAxisSettings,
        yAxisSettings,
        chart,
    ]);

    useEffect(() => {
        if (!chart) return;

        refLegendLayout.current = arctionHelper.createLegendLayout(chart);
        arctionHelper.generateAdaptiveLegend(refLegendLayout.current, chart);

        const rt = chart.onResize(chart => {
            if (refLegendLayout.current) {
                refLegendLayout.current.dispose();
                refLegendLayout.current = undefined;
            }
            refLegendLayout.current = arctionHelper.createLegendLayout(chart);
            arctionHelper.generateAdaptiveLegend(refLegendLayout.current, chart);
        });

        return () => {
            chart.offResize(rt);
            if (refLegendLayout.current) {
                refLegendLayout.current.dispose();
                refLegendLayout.current = undefined;
            }
        };
    }, [chart]);

    useEffect(() => {
        if (!chart) return;
        if (!channelsData) return;
        if (!startTime) return;

        // const originDate = new Date(startTime);
        // const originTime = originDate.getTime();
        const originTime = startTime;

        Object.keys(series).forEach(channelCode => {
            if (channelsData[channelCode]) {
                series[channelCode].clear().add(
                    channelsData[channelCode].map(([x, y]) => ({ x: x - originTime, y })),
                );

            }
        });

        setDataCounter(c => c + 1);
        const onMouseWheelZoomHandler = () => {
            setAutoFitXDisabled(true);
            setAutoFitYDisabled(true);
        };

        chart.engine.container.addEventListener('wheel', onMouseWheelZoomHandler);

        return () => {
            chart.engine.container.removeEventListener('wheel', onMouseWheelZoomHandler);
        };
    }, [chart, channelsData, series, startTime]);


    useEffect(() => {
        setAutoFitXDisabled(false);
        setAutoFitYDisabled(false);
        setAutoFitYDisabledKeys(null);
    }, [yAxisSettings, xAxisSettings]);

    return (
        <div
            id={id}
            style={{ height: '100%', width: '100%' }}
        />
    );
};

export default memo(UnitTimeBasedChart);
