import React, { useEffect, useMemo, useState, useRef } from 'react';
import Api from '../../Services/Api.service'
import Header from './Header.component';
import CssBaseline from '@material-ui/core/CssBaseline';
import MmsService from 'Services/MMS/mms-api.service';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { useAuth } from 'Contexts/AuthProvider';
import { MenuItem, Popover } from '@material-ui/core';
import AlertToastr from 'utils/alert';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import SaveDepartmentModal from '../../Pages/PredictiveMaintenance/Companies/Modals/SaveDepartmentModal';
import SaveEmployeeModal from '../../Pages/PredictiveMaintenance/Companies/Modals/SaveEmployeeModal';
import SaveComponentModal from '../../Pages/PredictiveMaintenance/Equipment/Modals/SaveComponentModal';
import SavePartModal from '../../Pages/PredictiveMaintenance/Equipment/Modals/SavePartModal';
import ChangeDepartmentModal from '../../Pages/PredictiveMaintenance/Equipment/Modals/ChangeDepartmentModal';
import DeleteItemModal from 'Components/modals/delete-item-modal/delete-item-modal.component';
// import DuplicateComponentModal from '../../Pages/PredictiveMaintenance/Equipment/Modals/DuplicateComponentModal';
import CloneToEquipmentModal from '../../Pages/PredictiveMaintenance/Catalog/Modals/CloneToEquipmentModal';
import SaveScheduledReportsModal
    from '../../Pages/PredictiveMaintenance/ScheduledReports/Modals/SaveScheduledReportsModal';
import SaveCompanyModal from '../../Pages/PredictiveMaintenance/Companies/Modals/SaveCompanyModal';
import {
    setComponents,
    setCurrentDepartment,
    setDepartments,
    setEditingCompany,
    setEmployees,
    setParts,
    setReports,
    setUnassignedComponents,
    setRunComponentsUpdate,
    setStatusOptions,
    setStates,
    setTypeOptions,
} from 'Store/modules/mms/actions';
import ThemeSelector from '../ThemeSelector/ThemeSelector.component';
import LanguageSelector from '../LanguageSelector/LanguageSelector.component';
import ExportReportModal from 'Pages/PredictiveMaintenance/ScheduledReports/Modals/ExportReportModal';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/MoreVert';
import useTranslation from 'Hooks/useTranslation';
import { apiMmsBaseUrl, appName } from '../../config';
import { useQuery, useQueryClient } from 'react-query';
import axios from 'axios';
import { useAppSettings } from '../../Contexts/AppSettingsProvider';
import { clearStoredDepartment, getStoredDepartment, setStoredDepartment } from '../../storage/department-storage';
import EventEmitter from '../../utils/EventEmitter';
import { useComponents } from '../../Hooks/mms/useComponents';
import DmsService from 'Services/DMS/dms-api.service';
import MyIcons from 'assets/img/Icons/MyIcons';
import Fade from '@material-ui/core/Fade';
import CircularProgress from '@material-ui/core/CircularProgress'






const PredMainLayout = (
    {
        children,
        departments,
        currentDepartment,
        setCurrentDepartment,
        currentCompany,
        setDepartments,
        currentPage,
        components,
        setParts,
        parts,
        componentsCatalog,
        setComponents,
        setUnassignedComponents,
        partsCatalog,
        setRunComponentsUpdate,
        setTypeOptions,
        setStatusOptions,
        setStates,
        setEmployees,
        reports,
        setReports,
        filterInfo,
        activities,
        pageTitle,
        rspSelectedComponentsIds,
        selectedUnassignedComponentsIds,
        editingCompany,
        setEditingCompany,
    }) => {

    const { currentUser, userLoaded } = useAuth();
    const isSuperUser = useMemo(() => (currentUser.isSuperuser()), [currentUser]);
    const [isOpenSaveDepartmentModal, setIsOpenSaveDepartmentModal] = useState(false);
    const [isOpenSaveCompanyModal, setIsOpenSaveCompanyModal] = useState(false);
    const [isOpenSaveEmployeeModal, setIsOpenSaveEmployeeModal] = useState(false);
    const [isOpenSaveComponentModal, setIsOpenSaveComponentModal] = useState(false);
    const [isOpenCloneToEquipmentModal, setIsOpenCloneToEquipmentModal] = useState(false);
    const [isOpenChangeDepartmentModal, setIsOpenChangeDepartmentModal] = useState(false);
    const [isOpenDeleteComponentsModal, setIsOpenDeleteComponentsModal] = useState(false);
    // const [isOpenDuplicateComponentModal, setIsOpenDuplicateComponentModal] = useState(false);
    const [isOpenSaveScheduleModal, setIsOpenSaveScheduleModal] = useState(false);
    const [isOpenDeletePartsModal, setIsOpenDeletePartsModal] = useState(false);
    const [isOpenDeleteReportsModal, setIsOpenDeleteReportsModal] = useState(false);
    const [isOpenDeleteUCModal, setIsOpenDeleteUCModal] = useState(false);
    const [isOpenExportReportModal, setIsOpenExportReportModal] = useState(false);
    const [isOpenSavePartModal, setIsOpenSavePartModal] = useState(false);
    const [disableExportButton, setDisableExportButton] = useState(false);
    const [isCatalog, setIsCatalog] = useState(false);
    const [cloneInstance, setCloneInstance] = useState('components');
    const { dashboardId } = useParams();
    const location = useLocation();
    const translation = useTranslation();
    const queryClient = useQueryClient();
    const [anchorSubNavEl, setAnchorSubNavEl] = React.useState(null);
    const history = useHistory();
    const {
        showActivitiesPartsExportPdf,
        appLanguage,
    } = useAppSettings();
    const [, setAllowRemoveNotifications] = useState(false);
    const [isLoading, setIsLoading] = useState(false)

    const handleSubNavClick = (event) => setAnchorSubNavEl(event.currentTarget);
    const handleSubNavClose = () => setAnchorSubNavEl(null);
    // const { path, url } = useRouteMatch();
    const inputFile = useRef(null);

    useEffect(() => {
        const onEvent = (selected) => setAllowRemoveNotifications(selected);

        const listener = EventEmitter.addListener('NotificationsSelected', onEvent);
        return () => {
            listener.remove();
        };
    }, []);

    const departmentsRequest = useQuery(
        ['departments', currentCompany],
        async () => {
            const config = !currentUser.isSuperuser() ? {} : {
                headers: { 'CompanyId': currentCompany?.id },
            };
            return await axios.get(`${apiMmsBaseUrl}/departments`, config);
        },
        { keepPreviousData: true, enabled: userLoaded },
    );

    useEffect(() => {
        if (departmentsRequest.isSuccess) {
            const { data, status } = departmentsRequest.data;
            setDepartments(status === 200 ? data : []);
        }
    }, [departmentsRequest.isSuccess, departmentsRequest.data, setDepartments]);


    const employeesRequest = useQuery(
        ['employees', currentCompany],
        async () => {
            const config = !currentUser.isSuperuser() ? {} : {
                headers: { 'CompanyId': currentCompany?.id },
            };
            return await axios.get(`${apiMmsBaseUrl}/employees`, config);
        },
        { keepPreviousData: true, enabled: userLoaded },
    );

    useEffect(() => {
        if (employeesRequest.isSuccess) {
            const { data, status } = employeesRequest.data;
            setEmployees(status === 200 ? data : []);
        }
    }, [employeesRequest.isSuccess, employeesRequest.data, setEmployees]);


    const componentsRequest = useComponents(currentDepartment?.id);

    useEffect(() => {
        if (componentsRequest.isSuccess && componentsRequest.data) {
            setComponents(componentsRequest.data.data);
        }
    }, [componentsRequest.isSuccess, componentsRequest.data, setComponents]);

    useEffect(() => {
        async function fetchUnassignedComponents() {
          if (currentCompany?.id) {
            const unassignedComponentsRequest = await Api.get(
              `${apiMmsBaseUrl}/components/unassigned`,
              {
                headers: isSuperUser ? { 'CompanyId': currentCompany.id } : {},
              }
            );
      
            if (unassignedComponentsRequest.data) {
              setUnassignedComponents(unassignedComponentsRequest.data.data);
            }
          }
        }
      
        fetchUnassignedComponents();
      }, [currentCompany?.id, isSuperUser, setUnassignedComponents]);
      


    useEffect(() => {
        const fetchParts = async () => {
            try {
                const response = await MmsService.getPartsList();
                setParts(response?.data);
            } catch (error) {
                if (error && error.response && error.response.data && error.response.data.message)
                    AlertToastr.showErrorAlert(error.response.data.message);
            }
        };
        // const fetchComponents = async () => {
        //     try {
        //         const response = await MmsService.getComponents(currentDepartment.id);
        //         setComponents(response.data);
        //     } catch (error) {
        //         if (error && error.response && error.response.data && error.response.data.message)
        //             AlertToastr.showErrorAlert(error.response.data.message);
        //     }
        // };
        if (!currentDepartment)
            return;
        fetchParts();
        // fetchComponents();
    }, [currentDepartment, setComponents, setParts]);

    useEffect(() => {
        const fetchTypeOptions = async (lang) => {
            let response = await MmsService.getTypesOptions(lang);
            setTypeOptions(response?.data);
        };
        const fetchStatusOptions = async (lang) => {
            let response = await MmsService.getStatusOptions(lang);
            setStatusOptions(response.data);
        };
        const fetchStates = async (lang) => {
            let response = await MmsService.getStates(lang);
            setStates(response.data);
        };
        fetchTypeOptions(appLanguage);
        fetchStatusOptions(appLanguage);
        fetchStates(appLanguage);
    }, [setStatusOptions, setStates, setTypeOptions, appLanguage]);

    useEffect(() => {
        if (!departments) return;
        if (departments.length === 0) {
            history.push(`/companies`);
            setCurrentDepartment(null);
            clearStoredDepartment();
            return;
        }

        const storedDepartmentId = getStoredDepartment();
        const storedDepartmentModel = departments.find(d => d.id === storedDepartmentId);

        let defaultDepartment;

        if (storedDepartmentModel) {
            defaultDepartment = storedDepartmentModel;
        } else {
            defaultDepartment = departments[0];
            setStoredDepartment(defaultDepartment.id);
        }

        setCurrentDepartment(defaultDepartment);

    }, [
        departments, setCurrentDepartment, history,
    ]);

    const openSubNav = Boolean(anchorSubNavEl);


    const selectedComponentsIds = useMemo(() => rspSelectedComponentsIds, [rspSelectedComponentsIds]);
    const selectedUCIds = useMemo(() => selectedUnassignedComponentsIds, [selectedUnassignedComponentsIds]);

    const selectedPartsIds = useMemo(() => {
        if (!parts) return [];
        return parts.filter(c => c.selected).map(c => c.id);
    }, [parts]);

    const selectedComponentsCatalogIds = useMemo(() => {
        if (!componentsCatalog) return [];
        return componentsCatalog.filter(c => c.selected).map(c => c.id);
    }, [componentsCatalog]);

    const selectedPartsCatalogIds = useMemo(() => {
        if (!partsCatalog) return [];
        return partsCatalog.filter(c => c.selected).map(c => c.id);
    }, [partsCatalog]);

    const selectedReportsIds = useMemo(() => {
        if (!reports) return [];
        return reports.filter(c => c.selected).map(c => c.id);
    }, [reports]);

    const onDeleteComponents = () => {
        setIsLoading(true); 
        const selectedComponents = isCatalog ? selectedComponentsCatalogIds : selectedComponentsIds;
        MmsService.deleteComponents(selectedComponents)
            .then(res => {
                if (res.data.isSuccessful) {
                    AlertToastr.showAlert(res.data.message)
                    queryClient.invalidateQueries(['components']);
                    // AlertToastr.showAlert(selectedComponents.length === 1 ? translation.notificationsComponentDeleted : translation.notificationsComponentsDeleted);
                    setRunComponentsUpdate(Math.random());
                }
            })
            .catch((error) => {
                if (error && error.response && error.response.data && error.response.data.message)
                    AlertToastr.showErrorAlert(error.response.data.message);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const onDeleteUnassignedComponents = () => {
        const selectedComponents = isCatalog ? selectedComponentsCatalogIds : selectedUCIds;
        MmsService.deleteUnassignedComponents(selectedComponents).then(res => {
            if (res.data.isSuccessful) {
                AlertToastr.showAlert(res.data.message)
                queryClient.invalidateQueries(['unassigned']);
                // AlertToastr.showAlert(selectedComponents.length === 1 ? translation.notificationsComponentDeleted : translation.notificationsComponentsDeleted);
                setRunComponentsUpdate(Math.random());
            }
        })
            .catch((error) => {
                if (error && error.response && error.response.data && error.response.data.message)
                    AlertToastr.showErrorAlert(error.response.data.message);
            });
    };

    const exportRsp = () => {
        setDisableExportButton(true);
        MmsService.exportRsp(currentDepartment.id, rspSelectedComponentsIds).then(res => {
            setDisableExportButton(false);
            const file = new Blob(
                [res.data],
                { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
        })
            .catch((error) => {
                setDisableExportButton(false);
                if (error && error.response && error.response.data && error.response.data.message)
                    AlertToastr.showErrorAlert(error.response.data.message);
            });
    };

    const onDeleteParts = () => {
        const partsIds = isCatalog ? selectedPartsCatalogIds : selectedPartsIds;
        MmsService.deleteParts(partsIds).then(res => {
            if (res.data.isSuccessful) {
                AlertToastr.showAlert(partsIds.length === 1 ? translation.notificationsPartDeleted : translation.notificationsPartsDeleted);
                const newParts = parts.filter(d => !selectedPartsIds.includes(d.id));
                setParts(newParts);
            }
        })
            .catch((error) => {
                if (error && error.response && error.response.data && error.response.data.message)
                    AlertToastr.showErrorAlert(error.response.data.message);
            });
    };

    const onDeleteReports = () => {
        MmsService.deleteReports(selectedReportsIds).then(res => {
            if (res.data.isSuccessful) {
                AlertToastr.showAlert(selectedReportsIds.length === 1 ? translation.notificationsReportDeleted : translation.notificationsReportsDeleted);
                const newReports = reports.filter(d => !selectedReportsIds.includes(d.id));
                setReports(newReports);
            }
        })
            .catch((error) => {
                if (error && error.response && error.response.data && error.response.data.message)
                    AlertToastr.showErrorAlert(error.response.data.message);
            });
    };

    const exportReport = () => {
        const filters = {...filterInfo}

        const activityIds = activities.filter(a => a.selected).map(a => a.id);
        filters.activityIds = activityIds.length ? activityIds : null

        MmsService.exportReport(currentDepartment.id, filters).then(res => {
            const file = new Blob(
                [res.data],
                { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
        })
            .catch((error) => {
                // console.log(error)
                if (error && error.response && error.response.data && error.response.data.message)
                    AlertToastr.showErrorAlert(error.response.data.message);
            });
    };

    const exportActivitiesPartReport = () => {
        const exportActivitiesIds = activities.filter(item => item.selected).map(item => item.id);

        MmsService.exportActivityParts(currentDepartment.id, exportActivitiesIds)
            .then(res => {
                const file = new Blob(
                    [res.data],
                    { type: 'application/pdf' });
                const fileURL = URL.createObjectURL(file);
                window.open(fileURL);
            })
            .catch((error) => {
                if (error && error.response && error.response.data && error.response.data.message)
                    AlertToastr.showErrorAlert(error.response.data.message);
            });
    };

    const [anchorElAsidePopover, setAnchorElAsidePopover] = React.useState(null);

    const handleClickAsidePopover = (event) => {
        setAnchorElAsidePopover(event.currentTarget);
    };

    const handleCloseAsidePopover = () => {
        setAnchorElAsidePopover(null);
    };

    const onFileSelected = (file) => {
        DmsService.uploadCompanyImage(editingCompany.id, file).then(async (response) => {
            if (response.data.isSuccessful) {
                let response = await DmsService.getCompanyImage(editingCompany.id);
                setEditingCompany(response.data);
            }
        }).catch(error => {
            if (error && error.response && error.response.data && error.response.data.message)
                AlertToastr.showErrorAlert(error.response.data.message);
        });
    };

    useEffect(() => {
        const fetchCompanyImage = async (id) => {
            let response = await DmsService.getCompanyImage(id);
            setEditingCompany(response.data);
        };
        if (currentCompany)
            fetchCompanyImage(currentCompany.id).then();
        if (currentUser && currentUser.companyId)
            fetchCompanyImage(currentUser.companyId).then();
    }, [currentCompany, currentUser, setEditingCompany]);

    const renderContextMenu = () => {
        if (location.pathname === '/dashboard') return null;
        return (
            <div className="asideNavHolder secondary">
                {location.pathname === '/companies' && <ul className="asideNav">
                    <li className="asideNavItemHolder">
                        <Button
                            fullWidth
                            startIcon={<MyIcons icon='createIcon'/>}
                            className="asideNavItem"
                            onClick={(e) => setIsOpenSaveCompanyModal(true)}
                            id='myCompanyEditBtn'
                        >
                            {translation.sideMenuEditCompanyLabel}
                        </Button>
                    </li>
                    <li className="asideNavItemHolder">
                        <Button
                            fullWidth
                            startIcon={<MyIcons icon='photoCamera'/>}
                            className="asideNavItem"
                            onClick={e => inputFile.current.click()}
                            id='myCompanyChangeLogoBtn'
                        >
                            {translation.sideMenuEditLogoLabel}
                        </Button>
                        <input type="file" id="file"
                                   ref={inputFile} style={{ display: 'none' }}
                                   onChange={(e) => {
                                       if (!e.target.files[0])
                                           return;
                                       onFileSelected(e.target.files[0]);
                                   }} />
                    </li>

                </ul>}
                {location.pathname === '/equipment' && currentPage === 'equipment' && <ul className="asideNav">
                    <li className="asideNavItemHolder">
                        <Button
                            fullWidth
                            startIcon={<MyIcons icon='addCircleOutlineIcon'/>}
                            className="asideNavItem"
                            id="assetsAddBtn"
                            onClick={(e) => EventEmitter.emit('CreateNewComponent')}
                        >
                            {translation.sideMenuAddNewComponentLabel}
                        </Button>
                    </li>
                    {/*<li className="asideNavItemHolder">*/}
                    {/*    <Button*/}
                    {/*        disabled={!selectedComponentsIds.length}*/}
                    {/*        fullWidth*/}
                    {/*        startIcon={<MyIcons icon='filterNoneIcon'/>}*/}
                    {/*        className="asideNavItem"*/}
                    {/*        onClick={(e) => {*/}
                    {/*            setIsCatalog(false);*/}
                    {/*            setIsOpenDuplicateComponentModal(true);*/}
                    {/*        }}*/}
                    {/*    >*/}
                    {/*        {translation.sideMenuDuplicateLabel}*/}
                    {/*    </Button>*/}
                    {/*</li>*/}
                    <li className="asideNavItemHolder">
                        <Button
                            disabled={!selectedComponentsIds.length}
                            fullWidth
                            startIcon={<MyIcons icon='deleteOutlineIcon'/>}
                            className="asideNavItem"
                            id="assetsRemoveBtn"
                            onClick={(e) => {
                                setIsCatalog(false);
                                setIsOpenDeleteComponentsModal(true);
                            }}
                        >
                            {translation.sideMenuRemoveLabel}
                        </Button>
                    </li>
                    <li className="asideNavItemHolder">
                        <Button
                            disabled={!selectedComponentsIds.length}
                            fullWidth
                            startIcon={<MyIcons icon='lowPriorityIcon'/>}
                            className="asideNavItem"
                            id="assetsChangeDepBtn"
                            onClick={(e) => setIsOpenChangeDepartmentModal(true)}
                        >
                            {translation.sideMenuChangeDepartmentLabel}
                        </Button>
                    </li>
                </ul>}
                {location.pathname === '/equipment' && <ul className="asideNav">
                    <li className="asideNavItemHolder">
                        <Button
                            disabled={disableExportButton || !rspSelectedComponentsIds.length}
                            fullWidth
                            startIcon={<MyIcons icon='getAppIcon'/>}
                            className="asideNavItem"
                            onClick={(e) => exportRsp()}
                            id="assetsExportPDFBtn"
                        >
                            {/*{translation.sideMenuPrintLabel}*/}
                            {translation.sideMenuExportSparePartsLabel}
                        </Button>
                    </li>
                </ul>}
                {location.pathname === '/parts' && <ul className="asideNav">
                    <li className="asideNavItemHolder">
                        <Button
                            fullWidth
                            startIcon={<MyIcons icon='addCircleOutlineIcon'/>}
                            className="asideNavItem"
                            onClick={(e) => {
                                setIsCatalog(false);
                                setIsOpenSavePartModal(true);
                            }}
                            id="partsAddBtn"
                        >
                            {translation.sideMenuAddNewPartLabel}
                        </Button>
                    </li>
                    <li className="asideNavItemHolder">
                        <Button
                            disabled={!selectedPartsIds.length}
                            fullWidth
                            startIcon={<MyIcons icon='deleteOutlineIcon'/>}
                            className="asideNavItem"
                            onClick={(e) => setIsOpenDeletePartsModal(true)}
                            id="partsRemoveBtn"
                        >
                            {translation.sideMenuRemoveLabel}
                        </Button>
                    </li>
                </ul>}
                {location.pathname === '/catalog' && currentPage === 'components' && <ul className="asideNav">
                    {isSuperUser &&
                        <li className="asideNavItemHolder">
                            <Button
                                fullWidth
                                startIcon={<MyIcons icon='addCircleOutlineIcon'/>}
                                className="asideNavItem"
                                onClick={(e) => {
                                    setIsCatalog(true);
                                    setIsOpenSaveComponentModal(true);
                                }}
                            >
                                {translation.sideMenuAddNewComponentLabel}
                            </Button>
                        </li>}
                    {isSuperUser &&
                        <>
                            {/* <li className="asideNavItemHolder">
                                <Button
                                    disabled={!selectedComponentsCatalogIds.length}
                                    fullWidth
                                    startIcon={<MyIcons icon='filterNoneIcon'/>}
                                    className="asideNavItem"
                                    onClick={(e) => {
                                        setIsCatalog(true);
                                        setIsOpenDuplicateComponentModal(true);
                                    }}
                                >
                                    {translation.sideMenuDuplicateLabel}
                                </Button>
                            </li> */}
                            <li className="asideNavItemHolder">
                                <Button
                                    disabled={!selectedComponentsCatalogIds.length}
                                    fullWidth
                                    startIcon={<MyIcons icon='deleteOutlineIcon'/>}
                                    className="asideNavItem"
                                    onClick={(e) => {
                                        setIsCatalog(true);
                                        setIsOpenDeleteComponentsModal(true);
                                    }}
                                >
                                    {translation.sideMenuRemoveLabel}
                                </Button>
                            </li>
                        </>}
                    {!isSuperUser && <li className="asideNavItemHolder">
                        <Button
                            disabled={!selectedComponentsCatalogIds.length}
                            fullWidth
                            startIcon={<MyIcons icon='lowPriorityIcon'/>}
                            className="asideNavItem"
                            onClick={(e) => {
                                setCloneInstance('components');
                                setIsOpenCloneToEquipmentModal(true);
                            }}
                        >
                            {translation.sideMenuTakeToMyEquipmentLabel}
                        </Button>
                    </li>}
                </ul>}
                {location.pathname === '/catalog' && currentPage === 'parts' && <ul className="asideNav">
                    {isSuperUser &&
                        <li className="asideNavItemHolder">
                            <Button
                                fullWidth
                                startIcon={<MyIcons icon='addCircleOutlineIcon'/>}
                                className="asideNavItem"
                                onClick={(e) => {
                                    setIsCatalog(true);
                                    setIsOpenSavePartModal(true);
                                }}
                            >
                                {translation.sideMenuAddNewPartLabel}
                            </Button>
                        </li>}
                    {isSuperUser &&
                        <li className="asideNavItemHolder">
                            <Button
                                disabled={!selectedPartsCatalogIds.length}
                                fullWidth
                                startIcon={<MyIcons icon='deleteOutlineIcon'/>}
                                className="asideNavItem"
                                onClick={(e) => setIsOpenDeletePartsModal(true)}
                            >
                                {translation.sideMenuRemoveLabel}
                            </Button>
                        </li>}
                    {!isSuperUser && <li className="asideNavItemHolder">
                        <Button
                            disabled={!selectedPartsCatalogIds.length}
                            fullWidth
                            startIcon={<MyIcons icon='lowPriorityIcon'/>}
                            className="asideNavItem"
                            onClick={(e) => {
                                setCloneInstance('parts');
                                setIsOpenCloneToEquipmentModal(true);
                            }}
                        >
                            {translation.sideMenuTakeToMyEquipmentLabel}
                        </Button>
                    </li>}
                </ul>}
                {location.pathname === '/activities' &&
                    <ul className="asideNav">
                        <li className="asideNavItemHolder">
                            <Button
                                fullWidth
                                startIcon={<MyIcons icon='equalizerIcon'/>}
                                className="asideNavItem"
                                id="activitiesAddScheduledReportBtn"
                                onClick={(e) => {
                                    setIsOpenSaveScheduleModal(true);
                                }}
                            >
                                {translation.sideMenuScheduledReportsLabel}
                            </Button>
                        </li>
                        <li className="asideNavItemHolder">
                            <Button
                                disabled={!activities || activities.length === 0}
                                fullWidth
                                startIcon={<MyIcons icon='getAppIcon'/>}
                                className="asideNavItem"
                                id="activitiesExportPDFBtn"
                                onClick={(e) => {
                                    exportReport();
                                    // setIsOpenExportReportModal(true)
                                }}
                            >
                                {translation.sideMenuExportReportsLabel}
                            </Button>
                        </li>
                        <li className="asideNavItemHolder">
                            <Button
                                disabled={!showActivitiesPartsExportPdf}
                                fullWidth
                                startIcon={<MyIcons icon='getAppIcon'/>}
                                className="asideNavItem"
                                id="activitiesPartsExportPDFBtn"
                                onClick={exportActivitiesPartReport}
                            >
                                {translation.sideMenuExportActivitiesPartsToPdfLabel}
                            </Button>
                        </li>
                    </ul>
                }
                {location.pathname === '/reports' && <ul className="asideNav">
                    <li className="asideNavItemHolder">
                        <Button
                            fullWidth
                            startIcon={<MyIcons icon='equalizerIcon'/>}
                            className="asideNavItem"
                            onClick={(e) => setIsOpenSaveScheduleModal(true)}
                            id="reportsAddScheduledReportBtn"
                        >
                            {translation.sideMenuScheduledReportsLabel}
                        </Button>
                    </li>
                    <li className="asideNavItemHolder">
                        <Button
                            disabled={!selectedReportsIds.length}
                            fullWidth
                            startIcon={<MyIcons icon='deleteOutlineIcon'/>}
                            className="asideNavItem"
                            id="reportsRemoveBtn"
                            onClick={(e) => setIsOpenDeleteReportsModal(true)}
                        >
                            {translation.sideMenuRemoveLabel}
                        </Button>
                    </li>
                </ul>}
                {/* {location.pathname === '/notifications' && <ul className="asideNav">
                    <li className="asideNavItemHolder">
                        <Button
                            fullWidth
                            startIcon={<MyIcons icon='equalizerIcon'/>}
                            className="asideNavItem"
                            onClick={() => EventEmitter.emit('CreateNotification')}
                            id="notificationsAddNotificationsBtn"
                        >
                            {translation.sideMenuCreateNotificationLabel}
                        </Button>
                    </li>
                    <li className="asideNavItemHolder">
                        <Button
                            disabled={!allowRemoveNotifications}
                            fullWidth
                            startIcon={<MyIcons icon='deleteOutlineIcon'/>}
                            className="asideNavItem"
                            id="notificationsRemoveNotificationsBtn"
                            onClick={() => EventEmitter.emit('RemoveBatchNotifications')}
                        >
                            {translation.sideMenuRemoveLabel}
                        </Button>
                    </li>
                </ul>} */}
                {location.pathname === '/departments' && <ul className="asideNav">
                <li className="asideNavItemHolder">
                        <Button
                            fullWidth
                            id='departmentsAddBtn'
                            startIcon={<MyIcons icon='addCircleOutlineIcon'/>}
                            className="asideNavItem"
                            onClick={(e) => setIsOpenSaveDepartmentModal(true)}
                        >
                            {translation.sideMenuAddNewDepartmentLabel}
                        </Button>
                        <input type="file" id="file"
                                   ref={inputFile} style={{ display: 'none' }}
                                   onChange={(e) => {
                                       if (!e.target.files[0])
                                           return;
                                       onFileSelected(e.target.files[0]);
                                   }} />
                    </li>
                </ul>}
                {location.pathname === '/employees' && <ul className="asideNav">
                    <li className="asideNavItemHolder">
                        <Button
                            disabled={!departments}
                            fullWidth
                            startIcon={<MyIcons icon='personAddIcon'/>}
                            className="asideNavItem"
                            onClick={(e) => setIsOpenSaveEmployeeModal(true)}
                            id="employeesAddBtn"
                        >
                            {translation.sideMenuAddNewEmployeeLabel}
                        </Button>
                    </li>
                </ul>}
                {location.pathname === '/unassigned-components' && <ul className="asideNav">
                    <li className="asideNavItemHolder">
                        <Button
                            fullWidth
                            startIcon={<MyIcons icon='addCircleOutlineIcon'/>}
                            className="asideNavItem"
                            onClick={(e) => EventEmitter.emit('CreateUnassignedComponent')}
                            id="ucAddComponentBtn"
                        >
                            {translation.saveComponentModalAddChildComponentLabel}
                        </Button>
                    </li>
                    <li className="asideNavItemHolder">
                        <Button
                            disabled={!selectedUCIds?.length}
                            fullWidth
                            startIcon={<MyIcons icon='deleteOutlineIcon'/>}
                            className="asideNavItem"
                            id="ucRemoveComponentBtn"
                            onClick={(e) => setIsOpenDeleteUCModal(true)}
                        >
                            {translation.sideMenuRemoveLabel}
                        </Button>
                    </li>
                </ul>}
            </div>
        );
    };


    const openAsidePopover = Boolean(anchorElAsidePopover);
    const contextMenu = renderContextMenu();

    return (
        <div className="predMainLayout">
            <CssBaseline />
            {!dashboardId && <Header />}
            {isLoading ? (
          <div className="loaderHolder">
            <Fade in={isLoading} unmountOnExit style={{position: 'absolute', top: '20%', left: '50%', zIndex: '100'}}>  
              <CircularProgress />
            </Fade>
          </div>
        ) : null}
            <main className="main" style={!!contextMenu ? undefined : {paddingRight: 0}}>
                <div className="pmSubHeader">
                    <div className="title">{pageTitle}</div>
                    {contextMenu && (
                        <div className="subMenuDrawerHolder">
                            <IconButton
                                disabled={!isSuperUser && location.pathname === '/catalog' &&
                                    ((currentPage === 'parts' && selectedPartsCatalogIds.length === 0) ||
                                        (currentPage === 'components' && selectedComponentsCatalogIds.length === 0))}
                                onClick={handleClickAsidePopover}>
                                <MenuIcon />
                            </IconButton>
                            <Popover
                                open={openAsidePopover}
                                anchorEl={anchorElAsidePopover}
                                onClose={handleCloseAsidePopover}
                                disableScrollLock={true}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                            >
                                {contextMenu}
                            </Popover>
                        </div>
                    )}
                </div>
                {children}
            </main>
            <aside className="mainAside">
                <div className="subNavHolder">
                    <Button
                        fullWidth
                        startIcon={<MyIcons icon='apartmentIcon'/>}
                        className={`asideNavItem ${['/companies'].includes(location.pathname) ? 'active' : ''}`}
                        onClick={(e) => history.push(`/companies`)}
                        id='asideMmsMenuMyCompanyBtn'
                    >
                        {translation.siderMyCompanyLabel}
                    </Button>


                    <div className='mainAsideSubItems'>
                        <Button
                            fullWidth
                            startIcon={<MyIcons icon='domainIcon'/>}
                            className={`asideNavItem ${['/departments'].includes(location.pathname) ? 'active' : ''}`}
                            onClick={(e) => history.push(`/departments`)}
                            id='asideMmsMenuDepartmentsBtn'
                            >{translation.myCompanyDepartmentsLabel}</Button>

                        <Button
                            fullWidth
                            startIcon={<MyIcons icon='peopleAltIcon'/>}
                            className={`asideNavItem ${['/employees'].includes(location.pathname) ? 'active' : ''}`}
                            onClick={(e) => history.push(`/employees`)}
                            id='asideMmsMenuEmployeesBtn'
                            >{translation.myCompanyEmployeesLabel}</Button>
                        <Button
                            fullWidth
                            startIcon={<MyIcons icon='settingsIcon'/>}
                            className={`asideNavItem ${['/parts'].includes(location.pathname) ? 'active' : ''}`}
                            onClick={(e) => history.push(`/parts`)}
                            id="asideMmsMenuPartsBtn"
                            >{translation.equipmentPagePartsLabel}</Button>
                        <Button
                            disabled={!currentDepartment}
                            fullWidth
                            startIcon={<MyIcons icon='componentsIcon'/>}
                            className={`asideNavItem ${location.pathname === '/unassigned-components' ? 'active' : ''}`}
                            onClick={(e) => history.push(`/unassigned-components`)}
                            id="asideMmsMenuUCBtn"
                        >
                            {translation.siderUnassignedComponentsLabel}
                        </Button>
                    </div>


                {/* <Switch>
                    <PrivateRoute path={`${path}`} component={DepartmentsComponent} exact/>
                    <PrivateRoute path={`${path}/employees`}  component={EmployeesComponent}/>
                </Switch> */}


                </div>
                <div className="asideNavHolder">
                    <ul className="asideNav">
                        {/*<li className="asideNavItemHolder">*/}
                        {/*    <Button*/}
                        {/*        disabled={!currentDepartment}*/}
                        {/*        fullWidth*/}
                        {/*        startIcon={<BarChartSharp />}*/}
                        {/*        className={`asideNavItem ${['/dashboard'].includes(location.pathname) ? 'active' : ''}`}*/}
                        {/*        onClick={(e) => history.push(`/dashboard`)}*/}
                        {/*    >*/}
                        {/*        {translation.siderDashboardLabel}*/}
                        {/*    </Button>*/}
                        {/*</li>*/}
                        <li className="asideNavItemHolder">
                            <Button
                            onClick={handleSubNavClick}
                            disabled={!departments || departments.length === 0}
                            className="asideNavItem"
                            fullWidth
                            startIcon={<MyIcons icon='accountTreeIcon'/>}
                            endIcon={<ArrowDropDownIcon />}
                            id="asideMmsMenuChangeDepartmentBtn"
                            >
                            <span
                                className="hasMaxWidth">{currentDepartment ? currentDepartment.name : 'Select Department'}</span>
                            </Button>
                            <Popover
                                open={openSubNav}
                                anchorEl={anchorSubNavEl}
                                onClose={handleSubNavClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                            >
                                {
                                    departments && departments.map(department =>
                                        <MenuItem
                                            key={department.id}
                                            className="filterMenuItem"
                                            onClick={() => {
                                                setCurrentDepartment(department);
                                                setStoredDepartment(department.id);
                                                handleSubNavClose();
                                            }}
                                            id="asideMmsMenuChooseDepartmentBtn"
                                        >{department.name}</MenuItem>,
                                    )
                                }
                            </Popover>

                        </li>

                        <div className='mainAsideSubItems'>
                            <li className="asideNavItemHolder">
                                <Button
                                    disabled={!currentDepartment}
                                    fullWidth
                                    startIcon={<MyIcons icon='buildIcon' style={{width: '17px', height: '17px'}}/>}
                                    className={`asideNavItem ${['/equipment', '/equipment/parts'].includes(location.pathname) ? 'active' : ''}`}
                                    onClick={(e) => history.push(`/equipment`)}
                                    id="asideMmsMenuAssetsBtn"
                                >
                                    {translation.siderEquipmentLabel}
                                </Button>
                            </li>
                            {/* {isSuperUser && <li className="asideNavItemHolder">
                                <Button
                                    disabled={!currentDepartment}
                                    fullWidth
                                    startIcon={<MyIcons icon='developerBoardIcon'/>}
                                    className={`asideNavItem ${location.pathname === '/catalog' ? 'active' : ''}`}
                                    onClick={(e) => history.push(`/catalog`)}
                                    id="asideMmsMenuTemplatesBtn"
                                >
                                    {translation.siderCatalogLabel}
                                </Button>
                            </li>} */}
                            
                            <li className="asideNavItemHolder">
                                <Button
                                    disabled={!currentDepartment}
                                    fullWidth
                                    startIcon={<MyIcons icon='playlistAddCheckIcon'/>}
                                    className={`asideNavItem ${location.pathname === '/activities' ? 'active' : ''}`}
                                    onClick={(e) => history.push(`/activities`)}
                                    id="asideMmsMenuActivitiesBtn"
                                >
                                    {translation.siderActivitiesLabel}
                                </Button>
                            </li>
                            <li className="asideNavItemHolder">
                                <Button
                                    disabled={!currentDepartment}
                                    fullWidth
                                    startIcon={<MyIcons icon='assessmentIcon'/>}
                                    className={`asideNavItem ${location.pathname === '/reports' ? 'active' : ''}`}
                                    onClick={(e) => history.push(`/reports`)}
                                    id="asideMmsMenuReportsBtn"
                                >
                                    {translation.scheduledReportsTabLabel}
                                </Button>
                            </li>
                            {/* <li className="asideNavItemHolder">
                                <Button
                                    disabled={!currentDepartment}
                                    fullWidth
                                    startIcon={<MyIcons icon='notificationImportant'/>}
                                    className={`asideNavItem ${location.pathname === '/notifications' ? 'active' : ''}`}
                                    onClick={(e) => history.push(`/notifications`)}
                                    id="asideMmsMenuNotificationsBtn"
                                >
                                    {translation.notificationsPageLabel}
                                </Button>
                            </li> */}
                        </div>
                    </ul>
                </div>
            </aside>
            {contextMenu && (
                <aside className="secondaryAside">
                    {contextMenu}
                </aside>
            )}
            <footer className="mainFooter">
                <div className="mainContainer">
                    <div className="footerWrapper">
                        <ThemeSelector />
                        <LanguageSelector />
                        <div className="copyright">
                            {appName}
                        </div>
                    </div>
                </div>
            </footer>
            <SaveCompanyModal
                open={isOpenSaveCompanyModal}
                handleClose={(e) => setIsOpenSaveCompanyModal(false)} />
            <SaveDepartmentModal
                open={isOpenSaveDepartmentModal}
                handleClose={(e) => setIsOpenSaveDepartmentModal(false)}
                onSuccessSave= {() => queryClient.invalidateQueries(['departments'])}
            />
            <SaveEmployeeModal
                open={isOpenSaveEmployeeModal}
                handleClose={(e) => setIsOpenSaveEmployeeModal(false)} />
            <SaveComponentModal
                open={isOpenSaveComponentModal}
                handleClose={(e) => setIsOpenSaveComponentModal(false)}
                isCatalog={isCatalog}
                onSuccessSave={item => {}}
            />
            <SavePartModal
                open={isOpenSavePartModal}
                handleClose={(e) => setIsOpenSavePartModal(false)}
                isCatalog={isCatalog} />
            <DeleteItemModal
                isOpen={isOpenDeleteComponentsModal}
                isOpenManager={setIsOpenDeleteComponentsModal}
                message={translation.removeComponentsModalMessageLabel}
                onDeleteModalClose={(e) => onDeleteComponents()} />
            <DeleteItemModal
                isOpen={isOpenDeletePartsModal}
                isOpenManager={setIsOpenDeletePartsModal}
                message={translation.removePartsModalMessageLabel}
                onDeleteModalClose={(e) => onDeleteParts()} />
            <DeleteItemModal
                isOpen={isOpenDeleteReportsModal}
                isOpenManager={setIsOpenDeleteReportsModal}
                message={translation.removeReportsModalMessageLabel}
                onDeleteModalClose={(e) => onDeleteReports()} />
            <DeleteItemModal
                isOpen={isOpenDeleteUCModal}
                isOpenManager={setIsOpenDeleteUCModal}
                message={translation.removeUCModalMessageLabel}
                onDeleteModalClose={(e) => onDeleteUnassignedComponents()} />    
            <ChangeDepartmentModal
                open={isOpenChangeDepartmentModal}
                handleClose={(e) => setIsOpenChangeDepartmentModal(false)}
                department={currentDepartment}
                componentIds={selectedComponentsIds} />
            {/* <DuplicateComponentModal
                isOpen={isOpenDuplicateComponentModal}
                isOpenManager={setIsOpenDuplicateComponentModal}
                componentIds={isCatalog ? selectedComponentsCatalogIds : selectedComponentsIds}
                departmentId={currentDepartment?.id}
                isCatalog={isCatalog}
            /> */}
            <CloneToEquipmentModal
                isOpen={isOpenCloneToEquipmentModal}
                isOpenManager={setIsOpenCloneToEquipmentModal}
                ids={cloneInstance === 'components' ? selectedComponentsCatalogIds : selectedPartsCatalogIds}
                departmentId={currentDepartment?.id}
                instance={cloneInstance} />
            <SaveScheduledReportsModal
                isOpen={isOpenSaveScheduleModal}
                isOpenManager={setIsOpenSaveScheduleModal} />
            <ExportReportModal
                open={isOpenExportReportModal}
                handleClose={() => setIsOpenExportReportModal(false)}
                onExport={exportReport}
            />
        </div>
    );
};

const mapDispatchToProps = {
    setCurrentDepartment,
    setDepartments,
    setComponents,
    setParts,
    setRunComponentsUpdate,
    setUnassignedComponents,
    setStatusOptions,
    setStates,
    setTypeOptions,
    setEmployees,
    setReports,
    setEditingCompany
};

const mapStateToProps = ({ mms, dashboard }) => ({
    departments: mms.departments,
    editingCompany: mms.editingCompany,
    currentDepartment: mms.currentDepartment,
    currentCompany: dashboard.currentCompany,
    currentPage: mms.currentPage,
    components: mms.components,
    parts: mms.parts,
    componentsCatalog: mms.componentsCatalog,
    partsCatalog: mms.partsCatalog,
    reports: mms.reports,
    filterInfo: mms.filterInfo,
    activities: mms.activities,
    pageTitle: mms.pageTitle,
    rspSelectedComponentsIds: mms.rspSelectedComponentsIds,
    selectedUnassignedComponentsIds: mms.selectedUnassignedComponentsIds,
});

export default connect(mapStateToProps, mapDispatchToProps)(PredMainLayout);
