import React, { useCallback, useEffect, useMemo, useState } from 'react'
import useTranslation from '../../Hooks/useTranslation'
import { useAuth } from '../../Contexts/AuthProvider'
import { useSelector } from 'react-redux'
import { useQuery, useQueryClient } from 'react-query'
import axios from 'axios'
import { apiAuthBaseUrl } from '../../config'

const UsersSettingsContext = React.createContext(undefined)

const UsersSettingsProvider = ({children}) => {

    const translation = useTranslation()
    const queryClient = useQueryClient()
    const { currentUser, userLoaded } = useAuth()
    const currentCompany = useSelector(state => state.dashboard.currentCompany)
    const [companyDetails, setCompanyDetails] = useState(null)

    const [usersTab, setUsersTab] = useState(`${translation.settingsTablesUsersLabel} (?)`)
    const [oneJobPasswordTab, setOneJobPasswordTab] = useState(`${translation.settingsTablesOneJobPasswordsLabel} (?)`)
    const [dbManagementTab, setDbManagementTab] = useState(`${translation.settingsDbManagementLabel}`)
    const [templatesManagementTab] = useState(`${translation.settingsTemplatesManagementLabel}`)

    const [users, setUsers] = useState([])
    const [oneJobPasswords, setOneJobPasswords] = useState([])

    const updateUsersTab = useCallback(() => setUsersTab(`${translation.settingsTablesUsersLabel} (${users.length})`), [translation, users])
    const updateOneJobPasswordTab = useCallback(() => setOneJobPasswordTab(`${translation.settingsTablesOneJobPasswordsLabel} (${oneJobPasswords.length})`), [translation, oneJobPasswords])
    const updateDbManagementTab = useCallback((count) => setDbManagementTab(`${translation.settingsDbManagementLabel}`), [translation])

    //fetch users for non-root current user
    const usersRequest = useQuery(
        ['settingsUsers', currentCompany],
        () => {
            const config = ! currentUser.isSuperuser() ? {} : {
                headers: {
                    'CompanyId': currentCompany?.id
                }
            }
            return axios.get(`${apiAuthBaseUrl}/users`, config)
        },
        {keepPreviousData: true, enabled: userLoaded}
    )

    useQuery(
        'company',
        () =>  {
            const companyId = currentUser.companyId
            return axios.get(`${apiAuthBaseUrl}/companies/${companyId}`)
        },
        {
            enabled: userLoaded && !currentUser.isSuperuser(),
            onSuccess: data => setCompanyDetails(data.data)
        }
    )

    const oneJobPasswordsRequest = useQuery(
        ['settingsOneJobPasswords', currentCompany],
        () => {
            const config = ! currentUser.isSuperuser() ? {} : {
                headers: {
                    'CompanyId': currentCompany?.id
                }
            }
            return axios.get(`${apiAuthBaseUrl}/JobPasswords`, config)
            // return axios.get(`${apiAuthBaseUrl}/JobPasswords/by-companyid/${currentCompany.id}`, config)
        },
        {keepPreviousData: true, enabled: userLoaded}
    )


    useEffect(() => {
        if (currentUser && currentUser.isSuperuser()) {
            setCompanyDetails(currentCompany)
        }
    }, [currentCompany, currentUser])

    useEffect(() => {
        if (usersRequest.isSuccess) {
            setUsers(usersRequest.data.data)
        }
    }, [
        usersRequest.isSuccess,
        usersRequest.data,
    ])

    useEffect(() => {
        if (oneJobPasswordsRequest.isSuccess) {
            setOneJobPasswords(oneJobPasswordsRequest.data.data)
        }
    }, [
        oneJobPasswordsRequest.isSuccess,
        oneJobPasswordsRequest.data,
    ])


    useEffect(() => {
        updateUsersTab()
        updateOneJobPasswordTab()
        updateDbManagementTab('?')
    }, [
        translation,
        updateUsersTab,
        updateOneJobPasswordTab,
        updateDbManagementTab
    ])

    const tabs = useMemo(() => {
        const items =  [
            {label: usersTab, path: '', exact: true},
            {label: oneJobPasswordTab, path: 'one-job-password'},
            {label: dbManagementTab, path: 'db-management'},
        ]
        if (currentUser.canManageTemplates()) {
            items.push({label: templatesManagementTab, path: 'templates-management'})
        }
        return items;
    }, [
        currentUser,
        usersTab,
        oneJobPasswordTab,
        dbManagementTab,
        templatesManagementTab
    ])


    const updateUsers = useCallback(() => {
        queryClient.invalidateQueries('settingsUsers').then()
    }, [queryClient])


    return (
        <UsersSettingsContext.Provider value={{
            tabs,
            updateUsersTab,
            updateOneJobPasswordTab,
            currentUser,
            currentCompany,
            companyDetails,
            setCompanyDetails,
            users,
            oneJobPasswords,
            updateUsers
        }}>
            {children}
        </UsersSettingsContext.Provider>
    )
}

const useUsersSettings = () => {
    const context = React.useContext(UsersSettingsContext)
    if (context === undefined) {
        throw new Error('useUsersSettings must be used within a UsersSettingsProvider')
    }
    return context
}

export {
    UsersSettingsProvider,
    useUsersSettings
}
