import React, { useState, useEffect } from "react";
import MmsService from "../../../../Services/MMS/mms-api.service";
import { TextField } from "@material-ui/core";
import WorkhoursUpdateModal from "./WorkhoursUpdateModal";
import AlertToastr from 'utils/alert';
import useTranslation from 'Hooks/useTranslation';
import { useQueryClient } from "react-query";

const EquipmentInlineWorkhoursForm = ({ model, onSuccessUpdate, setExpandedIds, isUnassignedComponent, openedComponentsIds }) => {
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [updatedValue, setUpdatedValue] = useState("");
  const [forceUpdate, setForceUpdate] = useState(false);
  const [isValueChanged, setIsValueChanged] = useState(false);

  const translation = useTranslation();
  const queryClient = useQueryClient();

  const isDeleting = model.isDeleting;

  useEffect(() => {
    if (model?.workhoursTotal === undefined) return;
    setValue(model?.workhoursTotal || 0);
  }, [model]);

  const handleSave = async () => {
    if (!updatedValue) {
      setValue(model.workhoursTotal || 0);
    }
  
    if (model.workhoursTotal !== updatedValue) {
      setLoading(true);
  
      try {
        const response = await MmsService.updateComponentWorkHours(
          model.id,
          updatedValue,
          forceUpdate
        );
  
        if (response?.data?.isSuccessful) {
          if(response.data.expandedIds.length > 0) {
            setExpandedIds(response.data.expandedIds); 
          } else {
            setExpandedIds(openedComponentsIds)  
          }
          setValue(updatedValue);
          setForceUpdate(true);
          queryClient.invalidateQueries("unassigned");
          queryClient.invalidateQueries("components");
          AlertToastr.showAlert(translation.notificationsWorkhoursUpdated)
          if (onSuccessUpdate) {
            onSuccessUpdate();
          }
          setModalOpen(false);
          setForceUpdate(false); 
        }
      } catch (error) {
        setModalOpen(true);
        setForceUpdate(true);
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          AlertToastr.showErrorAlert(error.response.data.message);
        }
      }
  
      setLoading(false);
    } else {
      setModalOpen(false);
      setValue(updatedValue);
      setForceUpdate(false);
    }
  };

  const handleCancel = () => {
    setUpdatedValue("");
    setModalOpen(false);
    setForceUpdate(false);
  };

  const handleModalOpen = () => {
    if (isDeleting) {
      return;
    }
    setUpdatedValue(value);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setUpdatedValue("");
    setModalOpen(false);
    if (value !== model.workhoursTotal) {
      setValue(model.workhoursTotal);
    }
    setForceUpdate(false);
  };

  const handleInputChange = (e) => {
    if (isDeleting) {
      return;
    }
    const input = e.target.value.replace(/\D/g, "");
    setUpdatedValue(input);
    setIsValueChanged(input !== model.workhoursTotal);
  };

  const iconClassName =
     model.workhoursUpdateType === "parallel"
      ? "sync"
      : model.workhoursUpdateType === "manual"
      ? "manual" : model.workhoursUpdateType === "dedicated" ? "dedicated"
      : "";

  return (
    <div style={{ position: "relative", display: "inline-block", cursor: "default" }}>
      <TextField
        className={`workHoursField ${iconClassName}`}
        id='assetsTableInlineWorkhours'
        readOnly={model.workhoursUpdateType !== "manual"} 
        disabled={isUnassignedComponent}
        inputProps={{ min: 0, max: 10000000000000 }}
        value={model.workhoursUpdateType === 'parent' ? `${model?.relativeWorkhours === undefined ? 0 : model?.workhoursTotal } `
          : value}
        variant="outlined"
        size="small"
        onMouseDown={
          model.workhoursUpdateType !== "manual" || isUnassignedComponent
            ? undefined
            : handleModalOpen
        }
        style={{
          pointerEvents: model.workhoursUpdateType !== "manual" ? "none" : "auto"
        }}
      />
      {loading && (
        <div
          style={{
            position: "absolute",
            right: "3px",
            top: 0,
            bottom: 0,
            opacity: "0.3",
          }}
        >
          ...
        </div>
      )}
      <WorkhoursUpdateModal
        model={model}
        open={modalOpen}
        onClose={handleModalClose}
        value={updatedValue}
        onChange={handleInputChange}
        onSave={handleSave}
        onCancel={handleCancel}
        // forceUpdate={forceUpdate}
        disabled={!isValueChanged}
      />
    </div>
  );
};

export default EquipmentInlineWorkhoursForm;
