
import React, { useState, useEffect } from 'react'
import { Datepicker, START_DATE, END_DATE } from "@datepicker-react/styled";
import { ThemeProvider } from "styled-components";
import { Button, TextField } from '@material-ui/core'
import Popover from "@material-ui/core/Popover";
import AlertToastr from 'utils/alert'
import useTranslation from 'Hooks/useTranslation'
import moment from 'moment'
import useSyncTabs from 'Hooks/useSyncTabs';
import { useAppSettings } from 'Contexts/AppSettingsProvider'
import { useBoxes } from 'Contexts/BoxContext';

const CalendarIcon = () => <div className='calendarIcon' />
const defaultTime = "00:00"

const DateRangePicker = ({ dateFrom, dateTo, setDateFrom, setDateTo, customRange, setCustomDateRangeType, onChange, onReset, handleClosePopover, handleReset}) => {
    const translation = useTranslation();
    const { startDate, endDate, updateDateRange } = useBoxes();
    const { appTheme: theme } = useAppSettings()
    const [anchorEl, onAnchorElChange] = useState(null)
    const [timeFrom, setTimeFrom] = useState(defaultTime)
    const [timeTo, setTimeTo] = useState(defaultTime)
    const [dateRange, setDateRange] = useState({
        startDate: null,
        endDate: null,
        focusedInput: START_DATE,
    })

    const { mutateAsync: syncTabs } = useSyncTabs('units');

    useEffect(() => {
        syncTabs({
            dateFrom: dateRange.startDate,
            dateTo: dateRange.endDate,
        }).then();

    }, [syncTabs, dateRange.startDate, dateRange.endDate]);

    const customDateRangeValue = `${moment(dateRange.startDate).format('DD-MMM-YY')} to ${moment(dateRange.endDate).format('DD-MMM-YY')}`;
    

    useEffect(() => {
        if (!dateFrom && !dateTo) {
            setTimeFrom(defaultTime)
            setTimeTo(defaultTime)
        } else {
            setTimeTo(moment(new Date(dateTo)).format("HH:mm"))
            setTimeFrom(moment(new Date(dateFrom)).format("HH:mm"))
            setTimeout(() => {
                handleDatesChange({ startDate: new Date(dateFrom), endDate: new Date(dateTo), focusedInput: END_DATE })
            }, 100)
        }
    }, [dateFrom, dateTo])

    const closeMenu = () => {
        if ((dateRange.startDate && !dateRange.endDate) || (dateRange.startDate && dateRange.endDate && dateRange.startDate === dateRange.endDate)) {
            var dt = new Date(dateRange.startDate);
            dt.setDate(dateRange.startDate.getDate() + 1)
            dt.setMinutes(dateRange.startDate.getMinutes() - 1)
            dateTo = dt.toISOString();
        } 
        else if (dateRange.startDate && dateRange.endDate) {
            dateRange.endDate.setDate(dateRange.endDate.getDate() + 1)
            dateRange.endDate.setMinutes(dateRange.endDate.getMinutes() - 1)
            dateTo = dateRange.endDate;
        }
        else if (!dateRange.startDate && !dateRange.endDate)
        {
            handleReset()
            onAnchorElChange(null);
            handleClosePopover();
            onChange({
                jobId: null,
                dateType: "last1hour",
                dateFrom: null,
                dateTo: null,
            });
            return;
        }
        dateFrom = dateRange.startDate;
        
        updateDateRange(dateFrom, dateTo);
        setDateFrom(dateFrom);
        setDateTo(dateTo);
        onChange({
            jobId: null,
            dateType: "customRange",
            name: customDateRangeValue,
            dateFrom: dateRange.startDate,
            dateTo: dateRange.endDate,
        });
        onAnchorElChange(null);
        setCustomDateRangeType(customDateRangeValue);
        handleClosePopover();
    };

    const handleDatesChange = (data) => {
        if (!data.focusedInput) {
            setDateRange({ ...data, focusedInput: START_DATE })
        } else {
            setDateRange(data)
        }
    }

    const formatDate = (date, time) => {
        return new Date(new Date(date).setHours(time.slice(0, 2), time.slice(3, 5))).toISOString()
    }

    const reset = () => {
        setTimeFrom(defaultTime)
        setTimeTo(defaultTime)
        setTimeout(() => {
            handleDatesChange({ startDate: null, endDate: null, focusedInput: START_DATE })
        }, 100)
        setDateFrom(null)
        setDateTo(null)
        // onAnchorElChange(null)
        if (onReset) {
            onReset();
        }
    }

    return (
        <div className="tableDateFilterHolder">
              <Button
                onClick={(e) => onAnchorElChange(e.target)}
                startIcon={<CalendarIcon />}
                className={customRange ? "customDateRangeBtn" : ""}
              >
                {(!customRange && dateRange.endDate !== null) && `${moment(dateRange.startDate).format('DD-MMM-YY')}, ${timeFrom} to ${moment(dateRange.endDate).format('DD-MMM-YY')}, ${timeTo}`}
                {(!customRange && dateRange.endDate === null) && 'Select Date Range'}
                {(customRange) && 'Select Custom Range'}
              </Button>
            

            <Popover anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                onClose={closeMenu}
            >
                <div className="popoverCalendarHolder">
                    <div className="calendarHolder">
                        <ThemeProvider
                            theme={{
                                reactDatepicker: {
                                    daySize: [36, 40],
                                    fontFamily: '"Open Sans", sans-serif',
                                    colors: {
                                        accessibility: 'transparent',
                                        selectedDay: theme === 'Light' ? 'rgba(68, 68, 68, 0.05)!important' : 'rgba(255, 255, 255, 0.05)!important',
                                        selectedDayHover: theme === 'Light' ? 'rgba(68, 68, 68, 0.05)!important' : 'rgba(255, 255, 255, 0.05)!important',
                                        primaryColor: theme === 'Light' ? 'rgba(68, 68, 68, 0.12)!important' : 'rgba(255, 255, 255, 0.12)!important',
                                    },
                                },
                            }}
                        >
                            <Datepicker
                                onDatesChange={handleDatesChange}
                                startDate={dateRange.startDate} // Date or null
                                endDate={dateRange.endDate} // Date or null
                                focusedInput={dateRange.focusedInput} // START_DATE, END_DATE or null
                                showResetDates={false}
                                showSelectedDates={false}
                                showClose={false}
                            />
                        </ThemeProvider>
                    </div>
                    {customRange ? null : 
                    <div className="timeHolder">
                        <div className="inputHolder">
                            <TextField
                                id="startTime"
                                label="Start Time"
                                type="time"
                                variant="outlined"
                                fullWidth
                                value={timeFrom}
                                onChange={e => setTimeFrom(e.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    step: 300, // 5 min
                                }}
                            />
                        </div>
                        <div className="inputHolder">
                            <TextField
                                id="endtime"
                                label="Stop Time"
                                type="time"
                                variant="outlined"
                                fullWidth
                                value={timeTo}
                                onChange={e => setTimeTo(e.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    step: 300, // 5 min
                                }}
                            />
                        </div>
                    </div>
                    }
                    <div className="btnHolder justified fixedWidthBtn mt32">
                        <Button variant="outlined"
                            onClick={reset}>
                            {translation.resetButtonLabel}
                        </Button>
                        <Button variant="contained"
                            color="primary"
                            onClick={closeMenu}>
                            {translation.applyButtonLabel}
                        </Button>
                    </div>
                </div>
            </Popover>
        </div>)
}
export default DateRangePicker