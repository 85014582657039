import React from 'react'
import { Switch, useRouteMatch } from 'react-router-dom'
import { PrivateRoute } from '../../Components/Routes'
import Users from './Blocks/Users'
import OneJobPasswords from './Blocks/OneJobPasswords'
import DatabaseManagement from './Blocks/DatabaseManagement'
import UserSettingsTabs from './UserSettingsTabs'
import { UsersSettingsProvider } from './UsersSettingsContext'
import CompanySelector from '../../Components/CompanySelector';
import { useAuth } from '../../Contexts/AuthProvider';
import TemplatesManagement from './Blocks/TemplatesManagement'

const UsersSettingsPage = () => {

    const {path} = useRouteMatch()
    const {currentUser} = useAuth();

    return (
        <main>
            <UsersSettingsProvider>
                <div className="mainContainer">
                    <div className="pageBtnSection">
                        <UserSettingsTabs path={path}/>
                        {currentUser.isMaster() && <CompanySelector />}
                    </div>
                    <div className="pageBlock">
                        <Switch>
                            <PrivateRoute path={path} component={Users} exact/>
                            <PrivateRoute path={`${path}/one-job-password`} component={OneJobPasswords} exact/>
                            <PrivateRoute path={`${path}/db-management`} component={DatabaseManagement} exact/>
                            <PrivateRoute path={`${path}/templates-management`} component={TemplatesManagement} exact/>
                        </Switch>

                    </div>
                </div>
            </UsersSettingsProvider>
        </main>
    )
}

export default UsersSettingsPage
