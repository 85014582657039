/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState, useCallback } from 'react';
import useUnitJobs from '../../Hooks/useUnitJobs';
import useUnitDateTypes from '../../Hooks/useUnitDateTypes';
import useTranslation from '../../Hooks/useTranslation';
import { MenuItem, Popover, TextField } from '@material-ui/core';
import { deleteEmptyStringProperties } from '../../helpers';
import DateRangePicker from 'Components/Box/DateRangePickerModal';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { useAuth } from 'Contexts/AuthProvider';

const defaultDateRangeValue = 'thisWeek';

const UnitJobDateRangeGlobalFilters = props => {

    const {
        unitUid,
        onChange,
        initData,
        inline = false,
        tabType
    } = props;

    const translation = useTranslation();
    const { data: unitJobs, isLoading: isLoadingUnitJobs } = useUnitJobs(tabType, unitUid);
    const { data: dateTypes, isLoading: isLoadingUnitDateTypes } = useUnitDateTypes();
    const [selectedDateFrom, setSelectedDateFrom] = useState(null);
    const [selectedDateTo, setSelectedDateTo] = useState(null);

    const [customDateRangeType, setCustomDateRangeType] = useState(null);

    const { currentCompany } = useAuth() 
    
    const [currentPage] = useState(window.location.pathname);



    const dateTypesOptions = useMemo(() => {
        return dateTypes.map(item => ({ ...item, name: item.name || translation.selectDateRangeLabel }));
    }, [dateTypes, translation]);

    const [updatedDateTypesOptions, setUpdatedDateTypesOptions] = useState(null);
    const [initialDateTypesOptions, setInitialDateTypesOptions] = useState(null);

    const [filterType, setFilterType] = useState('date');
    const [jobValue, setJobValue] = useState('none');
    const [dateRangeValue, setDateRangeValue] = useState('');
    const [resetClicked, setResetClicked] = useState(false);
    const [popoverAnchor, setPopoverAnchor] = useState(null);

    const saveToLocalStorage = (key, data) => {
      localStorage.setItem(`${currentPage}_${key}`, JSON.stringify(data));
    };
    
    const loadFromLocalStorage = (key) => {
      const storedData = localStorage.getItem(`${currentPage}_${key}`);
      return storedData ? JSON.parse(storedData) : null;
    };

    useEffect(() => {
      saveToLocalStorage("updatedDateTypesOptions", updatedDateTypesOptions);
    
    }, [updatedDateTypesOptions, currentPage]);

    useEffect(() => {
      const storedUpdatedDateTypesOptions = loadFromLocalStorage(
        "updatedDateTypesOptions"
      )
      if (storedUpdatedDateTypesOptions) {
        setUpdatedDateTypesOptions(dateTypesOptions);
      }
   
    }, [currentPage]);

    useEffect(() => {
      if (customDateRangeType !== null) {
        const updatedOptions = initialDateTypesOptions.map((item) => {
          if (item.dateType === "customRange") {
            return { ...item, name: customDateRangeType };
          }
          return item;
        });
        setUpdatedDateTypesOptions(updatedOptions);
        saveToLocalStorage("customDateRangeType", customDateRangeType);
      } else {
        const storedCustomDateRangeType = loadFromLocalStorage(
          "customDateRangeType"
        )
        if (storedCustomDateRangeType) {
          setCustomDateRangeType(storedCustomDateRangeType);
        }
        const updatedOptions = initialDateTypesOptions?.filter(
          (item) => item.dateType !== "customRange"
        )
        setUpdatedDateTypesOptions(updatedOptions);
      }
    }, [initialDateTypesOptions, customDateRangeType]);

    useEffect(() => {
      setInitialDateTypesOptions(dateTypesOptions);
    }, [dateTypesOptions]);

    const handleReset = useCallback(() => {
        const updatedOptions = initialDateTypesOptions?.filter(item => item.dateType !== "customRange");
        setUpdatedDateTypesOptions(updatedOptions);
        setDateRangeValue("last1hour");
    }, [initialDateTypesOptions, setUpdatedDateTypesOptions, setDateRangeValue]);

    useEffect(() => {
        if (resetClicked) {
            handleReset();
            setResetClicked(false);
        }
    }, [handleReset, resetClicked, setResetClicked, setDateRangeValue]);

    useEffect(() => {
        if (updatedDateTypesOptions?.length && !initData?.dateType) {
            setDateRangeValue(defaultDateRangeValue);
        }
    }, [updatedDateTypesOptions, initData]);

    useEffect(() => {
        if (!initData) return;

        if (initData.jobId) {
            setFilterType('job');
            setJobValue(initData.jobId);
        }
        if (initData.dateType && initData.dateType !== 'none') {
            setFilterType('date');
            if (dateTypes.length) {
                setDateRangeValue(initData.dateType);
            }
        }
    }, [initData, dateTypes]);


    useEffect(() => {
        if (inline) return;
        onChange(deleteEmptyStringProperties({
            dateType: dateRangeValue,
            jobId: jobValue !== 'none' ? jobValue : '',
            dateFrom: null,
            dateTo: null,
        }));
    }, [jobValue, dateRangeValue, onChange, inline]);

    // const handleChangeFilterType = (event) => {
    //     setDateRangeValue('none');
    //     setJobValue('none');
    //     setFilterType(event.target.value);
    //     if (inline) {
    //         if (dateRangeValue === 'none' && jobValue === 'none') return;
    //
    //         onChange(deleteEmptyStringProperties({
    //             dateType: 'none',
    //             jobId: '',
    //         }));
    //     }
    // };

    const handleChangeJob = (event) => {
        const value = event.target.value;

        setDateRangeValue('none');
        setJobValue(value);

        if (inline) {
            onChange(deleteEmptyStringProperties({
                dateType: 'none',
                jobId: value,
                dateFrom: null,
                dateTo: null,
            }));
        }
    };

    const handleChangeDateRange = (event) => {
        const value = event.target.value;
        setJobValue('none');
        setDateRangeValue(value);
        const updatedOptions = initialDateTypesOptions?.filter(item => item.dateType !== "customRange");
        setUpdatedDateTypesOptions(updatedOptions);

        if (inline && value !== 'customRange') {
            onChange({
                dateType: value,
                jobId: null,
                dateFrom: null,
                dateTo: null,
            });
        }
    };

    const renderJobSelect = () => {

        if (filterType !== 'job') return null;

        return (
            <TextField
                disabled={isLoadingUnitJobs}
                variant="outlined"
                margin="none"
                fullWidth
                select
                value={jobValue}
                onChange={handleChangeJob}
            >
                <MenuItem value="none" className="filterMenuItem">{translation.selectJobLabel}</MenuItem>
                {
                    unitJobs.map(item => (
                        <MenuItem
                            className="filterMenuItem"
                            key={item.id}
                            value={item.id}
                        >
                            {item.name}
                        </MenuItem>
                    ))
                }
            </TextField>
        );
    };

    const handleOpenPopover = (event) => {
      setPopoverAnchor(event.currentTarget);
    };
  
    const handleClosePopover = () => {
      setPopoverAnchor(null);
    };

    const renderDateRangeSelect = () => {
        if (filterType !== 'date') return null;
    
        const selectedDateType = updatedDateTypesOptions?.find(item => item.dateType === dateRangeValue);
      
        const displayDate = selectedDateType?.name.replace(' to Invalid date', '');
    
        return (
            <>
                <TextField
                    disabled={isLoadingUnitDateTypes}
                    variant="outlined"
                    margin="none"
                    fullWidth
                    value={displayDate || ''}
                    onClick={handleOpenPopover}
                    className='dateRangePicker'
                    InputProps={{
                        endAdornment: <ArrowDropDownIcon className='dateRangePickerIcon'/>,
                    }}
                />
                <Popover
                    open={Boolean(popoverAnchor)}
                    anchorEl={popoverAnchor}
                    onClose={handleClosePopover}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    {updatedDateTypesOptions
                ?.filter(item => item.dateType !== 'customRange')
                .map(item => (
                    <MenuItem
                        key={item.dateType}
                        value={item.dateType}
                        className="filterMenuItem"
                        onClick={() => {
                            handleChangeDateRange({ target: { value: item.dateType } });
                            handleClosePopover();
                        }}
                    >
                        {item.name}
                    </MenuItem>
                ))}
                    <DateRangePicker
                        customRange
                        dateFrom={selectedDateFrom}
                        dateTo={selectedDateTo}
                        setDateFrom={setSelectedDateFrom}
                        setDateTo={setSelectedDateTo}
                        setCustomDateRangeType={setCustomDateRangeType}
                        onChange={onChange}
                        onReset={() => setResetClicked(true)}
                        handleClosePopover={handleClosePopover}
                        handleReset={handleReset}
                    />
                </Popover>
            </>
        );
    };

    const toggleFilterType = (e) => {
        setFilterType(prevFilter => prevFilter === 'job' ? 'date' : 'job');
    };

    return (
        <div className='partEndBlock'>
            <div className={filterType === 'date' ? 'optionsSwitch' : 'optionsSwitch in'}>
                <div className="option" onClick={toggleFilterType}>{translation.dateRangeLabel}</div>
                <div className="option" onClick={toggleFilterType}>{translation.jobLabel}</div>
            </div>
            <div className='unitsDateRange'>
                {renderJobSelect()}
                {renderDateRangeSelect()}
            </div>
        </div>
    );
};

export default UnitJobDateRangeGlobalFilters;
