import React, { Fragment, useEffect, useMemo, useState } from 'react'
import UnitGroupHeader from './Components/UnitGroupHeader'
import ManageUnitChannelsModal from '../../Components/modals/UnitsModals/ManageUnitChannelsModal'
import uuid from 'uuid/v1'
import UnitBox from '../../Components/Box/Units/UnitBox'
import { chartHelper, dragNDropHelper } from '../../helpers'
import { useBoxes } from '../../Contexts/BoxContext'
import { useAuth } from '../../Contexts/AuthProvider'
import LoadUnitTemplateModal from '../../Components/modals/UnitsModals/LoadUnitTemplateModal'
import SaveUnitTemplateModal from '../../Components/modals/UnitsModals/SaveUnitTemplateModal'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import useTranslation from '../../Hooks/useTranslation'
import ChangeUnitModal from '../../Components/modals/UnitsModals/ChangeUnitModal'
import useTemplateChange from '../../Hooks/useTemplateChange'
import ErrorTemplateModal from '../../Components/modals/ErrorTemplateModal'
import { useConfirm } from '../../Contexts/ConfirmContext'
import useTemplateChangeConfirm from '../../Hooks/useTemplateChangeConfirm'
import ExportCsvUnitModal from '../../Components/modals/UnitsModals/ExportCsvUnitModal'
import DmsService from 'Services/DMS/dms-api.service'
import AlertToastr from '../../utils/alert'
import { apiBaseUrl } from "config";
import axios from "axios";

const UnitGroup = props => {
  const {
    onTemplateLoad,
    syncTabs,
    onAddBox,
    onEditBox,
    onRemoveBox,
    onNewWindowOpen,
    onUpdateSettings,
  } = props
  const translation = useTranslation()
  const { win } = useAuth()
  const {
    unitBoxes,
    currentUnitTitle,
    currentUnitTemplate,
    setCurrentUnitTemplate,
    addUnitBoxes,
    isMainGroup,
    resetCurrentUnit,
    currentUnitModel,
    currentUnit,
    setCurrentUnit,
    unitsRequest,
    unitBoxesFilters,
    dataExportUnitsRequest,
    setModalStep
  } = useBoxes()

  const [showManageChannelsModal, setShowManageChannelsModal] = useState(false)
  const [showChangeUnitModal, setShowChangeUnitModal] = useState(false)
  const [dontShowChangeUnitModal, setDontShowChangeUnitModal] = useState(false)
  const [showExportCsvModal, setShowExportCsvModal] = useState(false)
  const [templateErrors, setTemplateErrors] = useState()
  const [showTemplateErrorsModal, setShowTemplateErrorsModal] = useState(false)
  const [showSaveTemplateModal, setShowSaveTemplateModal] = useState(false)
  const [showLoadTemplateModal, setShowLoadTemplateModal] = useState(false)
  const [newlyCreatedBoxType, setNewlyCreatedBoxType] = useState(null)
  const [currentEditedBox, setCurrentEditedBox] = useState(null)
  const [isDragging, setIsDragging] = useState(false)
  const [showMapBtn, setShowMapBtn] = useState(false)

  const groupTitle = useMemo(() => isMainGroup && currentUnitTitle, [isMainGroup, currentUnitTitle])

  const manageChannelModalTitle = useMemo(() => {
    if (currentEditedBox)
      return translation.dashboardManageChannelsLabel

    const titleMap = {
      'chartView': translation.dashboardAddNewChartLabel,
      'digitalView': translation.dashboardAddNewDigitalDisplayLabel,
      'dataTable': translation.dashboardAddNewDataTableLabel,
    }

    return titleMap[newlyCreatedBoxType] || ''
  }, [currentEditedBox, newlyCreatedBoxType, translation])

  const { confirm } = useConfirm()

  const changeTemplateMutation = useTemplateChange()
  const changeTemplateConfirmMutation = useTemplateChangeConfirm()

  useEffect(() => {
    unitBoxes && !unitBoxes.length
      ? document.body.classList.add('dashboard')
      : document.body.classList.remove('dashboard')
    return () => {
      document.body.classList.remove('dashboard')
    }
  }, [unitBoxes])

  const addBoxShowModal = boxType => {
    setNewlyCreatedBoxType(boxType)
    setShowManageChannelsModal(true)
    if(boxType === 'map') {
      setShowManageChannelsModal(false)
    }
    if(boxType?.type === 'eventLogger') {
      setShowManageChannelsModal(false)
    }
  }

  const updateFilters = (updatedBox) => {
    onEditBox(updatedBox)
  }

  const saveBox = (boxData, openInNewDashboard) => {

    setShowManageChannelsModal(false)

    //TODO: modify template

    let savedData

    if (currentEditedBox) {
      savedData = {
        ...boxData,
        name: currentEditedBox.name,
        type: currentEditedBox.type,
        group: currentEditedBox.group,
        itemPosition: currentEditedBox.itemPosition,
        id: currentEditedBox.id,
      }

      if (savedData.type === 'chartView') {
        savedData.chartType = currentEditedBox.chartType
        savedData.yAxisSettings = currentEditedBox.yAxisSettings
        savedData.xAxisSettings = currentEditedBox.xAxisSettings
        savedData.timeType = currentEditedBox.timeType
        savedData.timeFormat = currentEditedBox.timeFormat
      }

      if (!openInNewDashboard) {
        savedData.group = win.id
      }

      if (savedData.yAxisSettings) {
        chartHelper.editChartSettings(savedData)
      } else {
        chartHelper.appendChartSettings(savedData)
      }

      onEditBox(savedData, openInNewDashboard)
      setCurrentEditedBox(null)

      // TODO: drag-n-drop update
      // if (openInNewDashboard) {
      //     const box = boxes.find(x => x.id === savedData.id);
      //     const boxIndex = boxes.indexOf(box);
      //     setNearbyBoxPosition(box.itemPosition.position, boxIndex);
      // }
    } else {
      savedData = {
        ...boxData,
        type: newlyCreatedBoxType,
        id: uuid(),
        timeType: 'local',
        timeFormat: 'time',
        itemPosition: {
          rowIndex: unitBoxes.length,
          position: 'Stretch',
        },
      }

      if (newlyCreatedBoxType === 'chartView') {
        savedData.name = translation.dashboardChartViewLabel
        savedData.chartType = 'timeBased'
      }

      if (!openInNewDashboard)
        savedData.group = win.id

      chartHelper.appendChartSettings(savedData)
      onAddBox(savedData, openInNewDashboard)
      getMapCoordinates(currentUnitModel?.uId)
    }
  }

  const setEditingBox = boxId => {
    //TODO: onManageChannels()?
    const editedBox = unitBoxes.find(box => box.id === boxId)
    if (editedBox) {
      setCurrentEditedBox(editedBox)
      setShowManageChannelsModal(true)
    }
  }

  const handleManageUnitChannelModalCancel = () => {
    setShowManageChannelsModal(false)
    setCurrentEditedBox(null)
    resetCurrentUnit()
  }

  const handleExportCsvModalCancel = () => {
    setShowExportCsvModal(false)
    setCurrentEditedBox(null)
    if (!unitBoxes.length) {
      resetCurrentUnit()
    }
    setSelectedCsvChannels([])
    setModalStep('')
  }

  const handleLoadUnitTemplateModalCancel = () => {
    setShowLoadTemplateModal(false)
  }

  const onDragStart = () => setIsDragging(true)

  const onDragEnd = ({ combine, source, destination }) => {
    setIsDragging(false)

    if (combine) {
      const reorderedBoxes = dragNDropHelper.combineAndUpdateBoxes(
        unitBoxes,
        source,
        combine,
      )
      updateBoxesOnDragEnd(reorderedBoxes)
      return
    }

    if (!destination) {
      return
    }

    const reorderedBoxes = dragNDropHelper.updateBoxes(
      unitBoxes,
      source,
      destination,
    )
    updateBoxesOnDragEnd(reorderedBoxes)
  }

  const updateBoxesOnDragEnd = (boxes) => {
    addUnitBoxes(boxes)
    syncTabs(win.id, boxes, [])
    setCurrentEditedBox(null)
  }

  const renderBox = (box, dragHandleProps) => {
    return (
      <UnitBox
        dragHandleProps={dragHandleProps}
        boxData={box}
        onRemove={() => onRemoveBox(box.id)}
        handleEdit={() => setEditingBox(box.id)}
        onNewWindowOpen={() => onNewWindowOpen(box.id)}
        onExportToCsv={() => handleExportToCsv(box.id)}
        onFilterChange={updateFilters}
        onUpdateSettings={onUpdateSettings}
        currentUnitModel={currentUnitModel}
      />
    )
  }

  const renderDraggableBox = (box, index) => {
    return (
      <Draggable
        key={box.id}
        draggableId={box.id}
        index={index}
      >
        {
          provided => (
            <Fragment>
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                className="pageBlockHolder"
              >
                <div
                  className={`pageBlock ${box?.itemPosition?.position || 'Stretch'} ${box[0] === 'm' ? 'mapContainer' : ''}`}
                  key={box.id}
                >
                  {renderBox(box, provided.dragHandleProps)}
                </div>
              </div>
              {provided.placeholder}
            </Fragment>
          )
        }
      </Draggable>
    )
  }

  const showConfirm = async ({ unitId, tabs, errors, code }) => {
    const isConfirmed = await confirm(translation.confirmChangeJobQuestion, errors)

    if (isConfirmed) {

      await changeTemplateConfirmMutation
        .mutateAsync({
          tabId: win?.id,
          code,
        })
      setCurrentUnit(unitId)
      onTemplateLoad(tabs, null)

    }
  }

  const getMapCoordinates = async (unitModel) => {
    const urlMap = `${apiBaseUrl}/data/gps/${unitModel}`;

    const { data } = await axios.get(urlMap);
    if (data.latitude) {
      setShowMapBtn(true);
    }
    return data;
  };

  useEffect(() => {
    if (dontShowChangeUnitModal) {
      setShowChangeUnitModal(false);
    }
  }, [dontShowChangeUnitModal]);
  
  const handleChangeUnit = (unitId, unitModel, newlyCreatedBoxType) => {
    changeTemplateMutation
      .mutateAsync({
        type: 'units',
        tabId: win?.id,
        unitUid: unitModel.uId,
      })
      .then(response => {
        const newTabs = response?.data?.tabs;
        const messages = response?.data?.messages;
        const code = response?.data?.code;
        if (messages && messages.length) {
          const errors = {
            type: 'warning',
            messages,
          };
          showConfirm({ unitId, tabs: newTabs, errors, code }).then(() => {
            setDontShowChangeUnitModal(true);
          });
        } else {
          setDontShowChangeUnitModal(true);
          setCurrentUnit(unitId);
          getMapCoordinates(unitModel.uId);
          if (newlyCreatedBoxType !== "eventLogger") {
            onTemplateLoad(newTabs, null, unitModel);
          }
        }
      })
      .catch(error => {
        const errMsg = error?.response?.data?.message;
        if (errMsg) {
          setTemplateErrors({
            type: 'error',
            messages: [errMsg],
          });
          setShowTemplateErrorsModal(true);
        }
      })
      .finally(() => {
        setShowChangeUnitModal(false);
      });
  };
 
  const [selectedCsvChannels, setSelectedCsvChannels] = useState([]);

  const handleExportToCsv = (boxId) => {
    const editedBox = unitBoxes.find(box => box.id === boxId)
    if (editedBox) {
      setCurrentEditedBox(editedBox)
    }
    setShowExportCsvModal(true)
  }

  const onExportData = (data) => {
    data = { ...data, ...unitBoxesFilters, tabType: 'units', uId: currentUnitModel?.uId }
    data.channels = selectedCsvChannels;
    if (currentEditedBox) {
      data.channels = currentEditedBox.channels.map(ch => ({ code: ch.code, unit: ch.unit }))
    }

    DmsService.exportUnitsData(data).then(res => {
      // const element = document.createElement('a');
      // const file = new Blob(['\ufeff' + res.data]);
      // element.href = URL.createObjectURL(file);
      // element.download = `${data.name}.csv`;
      // element.click();

      dataExportUnitsRequest.refetch().then(() => {
        AlertToastr.showAlert(translation.exportBuildingStartedMessage)
      }).finally(() => {
        if (!unitBoxes.length && isMainGroup) {
          setCurrentUnit(null)
        }
        setShowExportCsvModal(false)
        setModalStep('')
      })
    }).catch(error => {
      if (error?.response?.data?.Message) {
        AlertToastr.showErrorAlert(error?.response?.data?.Message)
      }
    })

    setCurrentEditedBox(null)
    setModalStep('')
  }

  if (!win.id) return null

  return (
    <Fragment>
      <UnitGroupHeader
        title={groupTitle}
        unitsRequest={unitsRequest}
        onAddBox={addBoxShowModal}
        onLoadTemplate={() => setShowLoadTemplateModal(true)}
        onSaveTemplate={() => setShowSaveTemplateModal(true)}
        onExportCsv={() => setShowExportCsvModal(true)}
        onChangeUnit={() => setShowChangeUnitModal(true)}
        handleChangeUnit={handleChangeUnit}
        showMapBtn={showMapBtn}
      />
      <DragDropContext
        onDragEnd={onDragEnd}
        onDragStart={onDragStart}
      >
        <Droppable
          droppableId="droppable"
          type="droppableItem"
          isCombineEnabled
        >
          {
            provided => (
              <div className={isDragging ? 'dragging' : ''} ref={provided.innerRef}>
                {
                  unitBoxes.map((box, index) => {
                    if (!box.itemPosition) return renderDraggableBox(box, index)

                    if (box.itemPosition.position === 'Stretch') {
                      return renderDraggableBox(box, index)
                    }

                    if (box.itemPosition.position === 'Left') {
                      return (
                        <div className="combinedPageBlock" key={box.id}>
                          {renderDraggableBox(box, index)}
                          {
                            (unitBoxes.length > index + 1)
                            &&
                            renderDraggableBox(unitBoxes[index + 1], index + 1)
                          }
                        </div>
                      )
                    }

                    return null
                  })
                }
                {provided.placeholder}
              </div>
            )
          }
        </Droppable>
      </DragDropContext>

      <ChangeUnitModal
        isOpen={showChangeUnitModal}
        onCancel={() => setShowChangeUnitModal(false)}
        onSave={handleChangeUnit}
        currentUnit={currentUnit}
        unitsRequest={unitsRequest}
      />

      <ManageUnitChannelsModal
        newlyCreatedBoxType={newlyCreatedBoxType}
        isOpen={showManageChannelsModal}
        onCancel={handleManageUnitChannelModalCancel}
        onSave={saveBox}
        onSaveEventLogger={handleChangeUnit}
        editedModel={currentEditedBox}
        onNewWindowOpen={onNewWindowOpen}
        enableFilters={false}
        title={manageChannelModalTitle}
        currentUnit={currentUnit}
        currentUnitModel={currentUnitModel}
        setCurrentUnit={setCurrentUnit}
        unitsRequest={unitsRequest}
        unitBoxes={unitBoxes}
      />

      <ErrorTemplateModal
        errors={templateErrors}
        isOpen={showTemplateErrorsModal}
        onOk={() => {
          setShowTemplateErrorsModal(false)
          setTemplateErrors(undefined)
        }}
      />

      <LoadUnitTemplateModal
        isOpen={showLoadTemplateModal}
        onCancel={handleLoadUnitTemplateModalCancel}
        loadedTemplate={currentUnitTemplate}
        onTemplateLoad={(tabs, template) => {
          setShowLoadTemplateModal(false)
          onTemplateLoad(tabs, template)
        }}
        dontShowChangeUnitModal={dontShowChangeUnitModal}
      />

      <SaveUnitTemplateModal
        isOpen={showSaveTemplateModal}
        onCancel={() => setShowSaveTemplateModal(false)}
        loadedTemplate={currentUnitTemplate}
        onSuccessSave={template => setCurrentUnitTemplate(template)}
        currentUnitModel={currentUnitModel}
        type="units"
      />

      <ExportCsvUnitModal
        unitsRequest={unitsRequest}
        currentUnitTitle={currentUnitTitle}
        setCurrentUnit={setCurrentUnit}
        onExportData={onExportData}
        isOpen={showExportCsvModal}
        onCancel={handleExportCsvModalCancel}
        currentUnitModel={currentUnitModel}
        dontShowChangeUnitModal={dontShowChangeUnitModal}
        setSelectedCsvChannels={setSelectedCsvChannels}
        selectedCsvChannels={selectedCsvChannels}
      />

    </Fragment>
  )
}

export default UnitGroup
