import React, { Fragment, useCallback, useEffect, useMemo, useState, useRef } from 'react'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Fade from '@material-ui/core/Fade'
import { Checkbox, MenuItem, TextField } from '@material-ui/core'
import MmsService from 'Services/MMS/mms-api.service'
import 'react-datepicker/dist/react-datepicker.css'
import { connect } from 'react-redux'
import AlertToastr from 'utils/alert'
import Popover from '@material-ui/core/Popover'
import moment from 'moment'
import TuneIcon from '@material-ui/icons/Tune'
import IconButton from '@material-ui/core/IconButton'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import CompleteActivityModal from './Modals/CompleteActivityModal'
import SaveActivityModal from './Modals/SaveActivityModal'
import AssignPersonModal from './Modals/AssignPersonModal'
import { setActivities, setFilterInfo, setPageTitle } from '../../../Store/modules/mms/actions'
import useTranslation from 'Hooks/useTranslation'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import { useAppSettings } from '../../../Contexts/AppSettingsProvider'
import AssignPersonButton from '../../../Components/AssignPersonButton'
import DeleteItemModal from '../../../Components/modals/delete-item-modal/delete-item-modal.component'
import SearchInput from '../../../Components/SearchInput'
import { useEmployeesByDepartmentId } from '../../../Hooks/mms/useEmployees'
import { Autocomplete } from '@material-ui/lab'
import { useQueryClient } from 'react-query';
import Api from 'Services/Api.service'
import { apiMmsBaseUrl } from 'config'

const ArrowLeft = () => <div className="arrowLeftIcon"/>
const ArrowRight = () => <div className="arrowRightIcon"/>

const descConst = 'descending'
const ascConst = 'ascending'
const arrowDownClassName = 'hasArrow'
const arrowUpClassName = 'hasArrow up'

const ActivitiesPage = ({
                          currentDepartment,
                          components,
                          parts,
                          typeOptions,
                          statusOptions,
                          states,
                          employees,
                          setFilterInfo,
                          setActivities,
                          activities,
                          setPageTitle,
                        }) => {

  const [isOpenCompleteActivityModal, setIsOpenCompleteActivityModal] = useState(false)
  const [isOpenSaveActivityModal, setIsOpenSaveActivityModal] = useState(false)
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [isLoadingParts, setIsLoadingParts] = useState(false)
  const [activityAnchorEl, setActivityAnchorEl] = useState(null)
  const [filterAnchorEl, setFilterAnchorEl] = useState(null)
  const [selectedActivity, setSelectedActivity] = useState(null)
  const [isOpenAssignPersonModal, setIsOpenAssignPersonModal] = useState(false)
  const [priorityOptions, setPriorityOptions] = useState([])
  const [filterTimeTypeOptions, setFilterTimeTypeOptions] = useState([])
  const [activitiesParts, setActivitiesParts] = useState([])
  const [sortConfig, setSortConfig] = useState({ key: '', direction: '' })
  const translation = useTranslation()
  const [search, setSearch] = useState('')
  
  const [firstIndex, setFirstIndex] = useState(0)
  const [lastIndex, setLastIndex] = useState(0)
  const [totalRows, setTotalRows] = useState(0)
  const [rowsOnPage, setRowsOnPage] = useState(20)
  const [pageNumber, setPageNumber] = useState(1)
  const [anchorEl, onAnchorElChange] = useState(null)

  const [partsFirstIndex, setPartsFirstIndex] = useState(0)
  const [partsLastIndex, setPartsLastIndex] = useState(0)
  const [partsTotalRows, setPartsTotalRows] = useState(0)
  const [partsRowsOnPage, setPartsRowsOnPage] = useState(20)
  const [partsPageNumber, setPartsPageNumber] = useState(1)
  const [partsAnchorEl, onPartsAnchorElChange] = useState(null)
  const [expandedActivity, setExpandedActivity] = useState(null)
  const [employeesOptions, setEmployeesOptions] = useState({ fromDepartment: [], other: [] })
  const queryClient = useQueryClient();
  const [selectedFilters, setSelectedFilters] = useState({
    status: null,
    dateRange: null,
    component: null,
    priority: null,
    assignee: null,
  });
  const [sortedComponents, setSortedComponents] = useState([]);

  const selectedFilterCount = Object.values(selectedFilters).filter(Boolean).length;

  const searchInputRef = useRef(null);

  useEffect(() => {
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [searchInputRef])

  const { setShowActivitiesPartsExportPdf, appLanguage } = useAppSettings()

  const emptyStatusOption = useMemo(() => {
    return { id: 'empty', name: translation.filterActivitiesModalSelectStatusLabel }
  }, [translation])
  const emptyDateRangeOption = useMemo(() => {
    return { id: 'empty', name: translation.filterActivitiesModalSelectDateRangeLabel }
  }, [translation])
  const emptyComponentOption = useMemo(() => {
    return { id: 'empty', name: translation.filterActivitiesModalSelectComponentLabel }
  }, [translation])
  const emptyPriorityOption = useMemo(() => {
    return { id: 'empty', name: translation.filterActivitiesModalSelectPriorityLabel }
  }, [translation])
  const emptyGuidAssigneeOption = useMemo(() => {
    return {
      id: '00000000-0000-0000-0000-000000000000',
      name: translation.filterActivitiesModalSelectAssigneeLabel,
    }
  }, [translation])
  const emptyAssigneeOption = useMemo(() => {
    return { id: 'empty', name: translation.filterActivitiesModalNoAssigneeLabel }
  }, [translation])
  // const emptyPartOption = useMemo(() => {
  //     return {id: 'empty', name: translation.filterActivitiesModalSelectPartLabel}
  // }, [translation])

  const filteredActivities = useMemo(() => {
    if (!search) return activities;
    return activities.filter(p => {
      const name = p.name?.toLowerCase();
      const component = components.find(x => x.id === p.componentId);
      const componentName = component ? component.fullName?.toLowerCase() : '';
      return (
        name?.includes(search.toLowerCase()) ||
        componentName?.includes(search.toLowerCase())
      );
    });
  }, [activities, components, search]);

  const BlankFilterData = useMemo(() => {
    return {
      statusId: emptyStatusOption.id,
      dateRangeId: emptyDateRangeOption.id,
      componentId: emptyComponentOption.id,
      priorityId: emptyPriorityOption.id,
      assigneeId: emptyGuidAssigneeOption.id,
    }
  }, [emptyStatusOption.id,
    emptyDateRangeOption.id,
    emptyComponentOption.id,
    emptyPriorityOption.id,
    emptyGuidAssigneeOption.id,
  ])

  const [filter, setFilter] = useState(BlankFilterData);
  
  useEffect(() => {
    const storedFilters = JSON.parse(localStorage.getItem(`selectedFilters_${currentDepartment?.id}`)) || BlankFilterData;
    setFilter(storedFilters);
    setSelectedFilters({
      status: storedFilters.statusId !== emptyStatusOption.id ? storedFilters.statusId : null,
      dateRange: storedFilters.dateRangeId !== emptyDateRangeOption.id ? storedFilters.dateRangeId : null,
      component: storedFilters.componentId !== emptyComponentOption.id && components !== null ? components.find(c => c.id === storedFilters.componentId) || null : null,

      priority: storedFilters.priorityId !== emptyPriorityOption.id ? storedFilters.priorityId : null,
      assignee: storedFilters.assigneeId !== emptyGuidAssigneeOption.id ? storedFilters.assigneeId : null,
    });
  }, [currentDepartment, BlankFilterData, emptyStatusOption.id, emptyDateRangeOption.id, emptyComponentOption.id, emptyPriorityOption.id, emptyGuidAssigneeOption.id, components]);

  const employeesByDepartmentIdRequest = useEmployeesByDepartmentId(currentDepartment?.id)

  useEffect(() => {
    if (employeesByDepartmentIdRequest.isSuccess) {
      setEmployeesOptions(employeesByDepartmentIdRequest.data.data)
    }
  }, [
    employeesByDepartmentIdRequest.isSuccess, employeesByDepartmentIdRequest.data
  ])

  useEffect(() => {
    setPageTitle(translation.activitiesPageActivitiesLabel)
  }, [setPageTitle, translation])

  const fetchActivities = useCallback(async () => {
    try {
      const selectedFilters = JSON.parse(localStorage.getItem(`selectedFilters_${currentDepartment.id}`)) || BlankFilterData;
      const response = await MmsService.getFiltredActivities(currentDepartment.id, selectedFilters);
      setIsLoading(false);
      const activityData = response.data.map(activity => {
        return {
          ...activity, tasks: activity.tasks.map(task => {
            return {
              ...task,
              newPartReplacedQuantity: task.partReplacedQuantity ? task.partReplacedQuantity : task.quantity,
              partReplacedQuantity: task.partReplacedQuantity ? task.partReplacedQuantity : task.quantity,
              partReplacedId: task.partReplacedId ? task.partReplacedId : (task.partId ? task.partId : task.alternativePartId),
            }
          }),
        }
      });
      setActivities(activityData);
  
      localStorage.setItem(`selectedFilters_${currentDepartment.id}`, JSON.stringify(selectedFilters));
    } catch (error) {
      setIsLoading(false)
      if (error && error.response && error.response.data && error.response.data.message)
        AlertToastr.showErrorAlert(error.response.data.message)
    }
  }, [currentDepartment, setActivities, BlankFilterData]);
  


  useEffect(() => {
    if (!currentDepartment) return
    setActivitiesParts([])
    fetchActivities().then()
  }, [currentDepartment, fetchActivities])

  const departmentEmployees = useMemo(() => {
    if (employees && currentDepartment)
      return employees.filter(e => e.departmentId === currentDepartment.id)
    else
      return null
  }, [employees, currentDepartment])

  const getDate = (activity) => {
    return !!(activity && activity.expectedCompleteDate) ? moment(activity.expectedCompleteDate).format('L') : ''
  }

  const getComponentNameById = useCallback((componentId) =>
      (components && componentId && components.find(x => x.id === componentId)) ?
        components.find(x => x.id === componentId).fullName : ''
    , [components])

  const getEmployeeNameById = useCallback((employeeId) =>
      employeeId && departmentEmployees && departmentEmployees.find(x => x.id === employeeId) ?
        departmentEmployees.find(x => x.id === employeeId).name : ''
    , [departmentEmployees])

  useEffect(() => {
    const fetchFilterDropdownsOptions = async (lang) => {
      let priorityResponse = await MmsService.getPriorityOptions(lang)
      setPriorityOptions(priorityResponse.data)
      let filterTimeTypeResponse = await MmsService.getFilterTimeTypeOptions(lang)
      setFilterTimeTypeOptions(filterTimeTypeResponse.data)
    }
    if (!currentDepartment)
      return
    fetchFilterDropdownsOptions(appLanguage)
  }, [currentDepartment, appLanguage])

  useEffect(() => {
    const currentDep = currentDepartment?.id;
  
    if (currentDep) {
      const getComponents = async (currentDep) => {
        try {
          const response = await Api.get(`${apiMmsBaseUrl}/components/by-departmentid/${currentDep}/short?sortBy=id`);
          setSortedComponents(response.data); 
        } catch (e) {
          throw e;
        }
      };
  
      getComponents(currentDep);
    }
  }, [currentDepartment]);

  const sortedActivities = useMemo(() => {
    if (!activities)
      return []
    let sortedActivities = [...activities]
    if (!sortConfig || !sortConfig.key)
      return sortedActivities

    if (sortConfig.key === 'component') {
      sortedActivities.sort((a, b) => {
        if (getComponentNameById(a.componentId) < getComponentNameById(b.componentId)) {
          return sortConfig.direction === ascConst ? -1 : 1
        }
        if (getComponentNameById(a.componentId) > getComponentNameById(b.componentId)) {
          return sortConfig.direction === ascConst ? 1 : -1
        }
        return 0
      })
      return sortedActivities
    }
    if (sortConfig.key === 'priority') {
      sortedActivities.sort((a, b) => {
        if ((priorityOptions.findIndex(x => x.priority === a.priority)) < (priorityOptions.findIndex(x => x.priority === b.priority))) {
          return sortConfig.direction === ascConst ? -1 : 1
        }
        if ((priorityOptions.findIndex(x => x.priority === a.priority)) > (priorityOptions.findIndex(x => x.priority === b.priority))) {
          return sortConfig.direction === ascConst ? 1 : -1
        }
        return 0
      })
      return sortedActivities
    }
    if (sortConfig.key === 'tasks') {
      sortedActivities.sort((a, b) => {
        if (a.tasks.length < b.tasks.length) {
          return sortConfig.direction === ascConst ? -1 : 1
        }
        if (a.tasks.length > b.tasks.length) {
          return sortConfig.direction === ascConst ? 1 : -1
        }
        return 0
      })
      return sortedActivities
    }
    if (sortConfig.key === 'assigned') {
      sortedActivities.sort((a, b) => {
        if (getEmployeeNameById(a.assigneeId) < getEmployeeNameById(b.assigneeId)) {
          return sortConfig.direction === ascConst ? -1 : 1
        }
        if (getEmployeeNameById(a.assigneeId) > getEmployeeNameById(b.assigneeId)) {
          return sortConfig.direction === ascConst ? 1 : -1
        }
        return 0
      })
      return sortedActivities
    }
    if (sortConfig.key === 'date') {
      sortedActivities.sort((a, b) => {
        if (moment(getDate(a)).isAfter(getDate(b))) {
          return sortConfig.direction === ascConst ? -1 : 1
        }
        if (moment(getDate(b)).isAfter(getDate(a))) {
          return sortConfig.direction === ascConst ? 1 : -1
        }
        return 0
      })
      return sortedActivities
    }
    if (sortConfig.key === 'workhours') {
      sortedActivities.sort((a, b) => {
        if ((a.expectedWorkhours !== undefined ? a.expectedWorkhours : -1) < (b.expectedWorkhours !== undefined ? b.expectedWorkhours : -1)) {
          return sortConfig.direction === ascConst ? -1 : 1
        }
        if ((a.expectedWorkhours !== undefined ? a.expectedWorkhours : -1) > (b.expectedWorkhours !== undefined ? b.expectedWorkhours : -1)) {
          return sortConfig.direction === ascConst ? 1 : -1
        }
        return 0
      })
      return sortedActivities
    }
    if (sortConfig.key === 'status') {
      sortedActivities.sort((a, b) => {
        if ((statusOptions.findIndex(x => x.status === a.status)) < (statusOptions.findIndex(x => x.status === b.status))) {
          return sortConfig.direction === ascConst ? -1 : 1
        }
        if ((statusOptions.findIndex(x => x.status === a.status)) > (statusOptions.findIndex(x => x.status === b.status))) {
          return sortConfig.direction === ascConst ? 1 : -1
        }
        return 0
      })
      return sortedActivities
    }
    sortedActivities.sort((a, b) => {
      if (a[sortConfig.key].toLowerCase() < b[sortConfig.key].toLowerCase()) {
        return sortConfig.direction === ascConst ? -1 : 1
      }
      if (a[sortConfig.key].toLowerCase() > b[sortConfig.key].toLowerCase()) {
        return sortConfig.direction === ascConst ? 1 : -1
      }
      return 0
    })
    return sortedActivities
  }, [activities, sortConfig, getComponentNameById, getEmployeeNameById, priorityOptions, statusOptions])

  const pageData = useMemo(() => {
    let pageData = search ? [...filteredActivities] : [...sortedActivities]
    const totalLength = pageData.length

    if ((pageNumber - 1) * rowsOnPage + 1 > totalLength) {
      setPageNumber(1)
    }
    setTotalRows(totalLength)
    setFirstIndex(Math.min(((pageNumber - 1) * rowsOnPage + 1), totalLength))
    setLastIndex(Math.min((pageNumber * rowsOnPage), totalLength))
    let tableData = pageData.splice((pageNumber - 1) * rowsOnPage, Math.min((pageNumber * rowsOnPage), totalLength))
    return [...tableData]
  }, [
    sortedActivities,
    rowsOnPage,
    pageNumber,
    search,
    filteredActivities
  ])

  const closePaginationMenu = () => {
    onAnchorElChange(null)
  }

  const handleRowCount = (count) => {
    setRowsOnPage(count)
    setPageNumber(1)
    closePaginationMenu()
  }

  const onNextPage = () => {
    setPageNumber(pageNumber + 1)
    window.scrollTo(0, 0)
  }

  const onPrevPage = () => {
    setPageNumber(pageNumber - 1)
    window.scrollTo(0, 0)
  }

  const requestSort = (key) => {
    let direction = ascConst
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === ascConst
    ) {
      direction = descConst
    }
    setSortConfig({ key, direction })
  }

  const getClassNamesFor = (name) => {
    if (!sortConfig || sortConfig.key !== name) {
      return
    }
    if (sortConfig.direction)
      return sortConfig.direction === ascConst ? arrowDownClassName : arrowUpClassName
  }

  const handleSelectedIdChange = (activityId, checked) => {
    activities.find(c => c.id === activityId).selected = checked
    setActivities([...activities])
    getSelectedActivityParts()
  }

  const handleAllSelectedChange = (checked) => {
    const newActivities = activities.map(c => {
      c.selected = checked
      return c
    })
    setActivities([...newActivities])
    getSelectedActivityParts()
  }

  const getSelectedActivityParts = () => {
    const selectedActivityIds = activities.filter(a => a.selected).map(a => a.id)
    if (selectedActivityIds.length === 0) {
      setActivitiesParts([])
      return
    }
    setIsLoadingParts(true)
    MmsService.getActivityParts(selectedActivityIds).then(res => {
      setIsLoadingParts(false)
      setActivitiesParts(res.data)
    }).catch(error => {
      setIsLoadingParts(false)
      if (error && error.response && error.response.data && error.response.data.message)
        AlertToastr.showErrorAlert(error.response.data.message)
    })
  }

  const pagePartsData = useMemo(() => {
    let pageData = [...activitiesParts]
    const partsTotalLength = pageData.length

    if ((partsPageNumber - 1) * partsRowsOnPage + 1 > partsTotalLength) {
      setPageNumber(1)
    }
    setPartsTotalRows(partsTotalLength)
    setPartsFirstIndex(Math.min(((partsPageNumber - 1) * partsRowsOnPage + 1), partsTotalLength))
    setPartsLastIndex(Math.min((partsPageNumber * partsRowsOnPage), partsTotalLength))
    let tableData = pageData.splice((partsPageNumber - 1) * partsRowsOnPage, Math.min((partsPageNumber * partsRowsOnPage), partsTotalLength))
    return [...tableData]
  }, [
    activitiesParts,
    partsRowsOnPage,
    partsPageNumber,
  ])

  useEffect(() => {
    setShowActivitiesPartsExportPdf(!!pagePartsData.length)
  }, [pagePartsData, setShowActivitiesPartsExportPdf])

  const closePartsPaginationMenu = () => {
    onPartsAnchorElChange(null)
  }

  const handlePartsRowCount = (count) => {
    setPartsRowsOnPage(count)
    setPartsPageNumber(1)
    closePartsPaginationMenu()
  }

  const onPartsNextPage = () => {
    setPartsPageNumber(partsPageNumber + 1)
    window.scrollTo(0, 0)
  }

  const onPartsPrevPage = () => {
    setPartsPageNumber(partsPageNumber - 1)
    window.scrollTo(0, 0)
  }

  const handleRowClicked = (activity) => {
    if (activity.tasks.length === 0)
      return
    setExpandedActivity(id => (id === activity.id) ? null : activity.id)
    // activity.isShownTasks = activity.isShownTasks ? false : true;
    // setActivities([...activities]);
  }

  const handleSaveAssignee = (employeeId) => {
    selectedActivity.assigneeId = employeeId
    setActivities([...activities])
  }

  const resetFilter = () => {
    setFilter(BlankFilterData);
    setSelectedFilters({
      status: null,
      dateRange: null,
      component: null,
      priority: null,
      assignee: null,
    });
  };

  const applyFilter = useCallback(async () => {
    if (!currentDepartment) return
    localStorage.setItem(`selectedFilters_${currentDepartment.id}`, JSON.stringify(filter))
    setActivitiesParts([])
    setFilterAnchorEl(false)
    document.body.classList.remove('view-scroll');
    setIsLoading(true)
    const response = await MmsService.getFiltredActivities(currentDepartment.id, filter)
    setIsLoading(false)
    setActivities(response.data)
  }, [filter, currentDepartment, setActivities])  


  const [anchorElPopover, setAnchorElPopover] = React.useState(null)

  const handlePopoverClick = (event) => {
    setAnchorElPopover(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorElPopover(null)
  }

  const renderPagination = () => {
    if (!pagePartsData.length) return null
    return (
      <div className="tableControlsHolder">
        <div className="sortHolder">
          <div className="caption">Rows per page:</div>
          <Button onClick={(e) => onPartsAnchorElChange(e.target)}
                  endIcon={<ArrowDropDownIcon/>}
                  id="activitiesTableRowsPerPageBtn"
                  className="dropDownBtn">
            {partsRowsOnPage}
          </Button>

          <Popover
            anchorEl={partsAnchorEl}
            open={Boolean(partsAnchorEl)}
            onClose={closePartsPaginationMenu}
            disableScrollLock={true}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
            <MenuItem id="activitiesTblRowCount20Btn" onClick={() => handlePartsRowCount(20)}>20</MenuItem>
            <MenuItem id="activitiesTblRowCount50Btn" onClick={() => handlePartsRowCount(50)}>50</MenuItem>
            <MenuItem id="activitiesTblRowCount100Btn" onClick={() => handlePartsRowCount(100)}>100</MenuItem>
          </Popover>
        </div>
        <div className="paginationHolder mms">
          <div className="caption">{`${partsFirstIndex}-${partsLastIndex} of ${partsTotalRows}`}</div>
          <div className="paginationBtn">
            <IconButton
              disabled={partsFirstIndex === 1 || partsFirstIndex === 0}
              id="activitiesTblPrevPageBtn"
              onClick={() => onPartsPrevPage()}>
              <ArrowLeft/>
            </IconButton>
            <IconButton
              disabled={partsLastIndex === partsTotalRows}
              id="activitiesTblNextPageBtn"
              onClick={() => onPartsNextPage()}>
              <ArrowRight/>
            </IconButton>
          </div>
        </div>
      </div>
    )
  }

  const renderEmptyListText = () => {
    if (pagePartsData.length) return null
    return (
      <p className="textGray text-center">{translation.activitiesPagePartsEmptyListPlaceholderLabel}</p>
    )
  }

  const onDeleteActivity = () => {
    MmsService.deleteActivity(selectedActivity.id).then(res => {
      if (res.data.isSuccessful) {
        fetchActivities().then()
        AlertToastr.showAlert(translation.notificationsActivityDeleted)
      }
    })
      .catch((error) => {
        if (error && error.response && error.response.data && error.response.data.message)
          AlertToastr.showErrorAlert(error.response.data.message)
      })
  }

  const openPopover = Boolean(anchorElPopover)

  return (
    <div className="pmPageHolder">
      <div className="pmPageBlock">
        <div className="pmPageBlockTitle">{translation.activitiesPageActivitiesLabel}</div>
        <div className="subTitleWithFilter mb15">
          <SearchInput search={search} onChange={setSearch} id="activitiesSearchField"/>
          <p className="textGray"></p>
          <div className="filter">
          <Button
            variant={"contained"}
            color="primary"
            id="activitiesFilterBtn"
            onClick={(e) => {
              setFilterAnchorEl(e.target);
              document.body.classList.add('view-scroll');
            }}              
            startIcon={
              selectedFilterCount > 0 ? (
                <div>
                  <span className="filterCounter">{selectedFilterCount}</span>
                </div>
              ) : (
                <TuneIcon />
              )
            }
          >
            {translation.filterButtonLabel}
          </Button>
          </div>
        </div>
        <div className="pmTableHolder mb15">
          <table className="pmTable">
            <thead>
            <tr>

              <th className="checkBoxCell">
                <Checkbox
                  checked={activities ? activities.every(c => c.selected) : false}
                  onChange={(e) => handleAllSelectedChange(e.target.checked)}
                  id="activitiesTblCheckBoxAllRows"
                  color="default"
                  size="small"
                />
              </th>
              <th onClick={() => requestSort('')}
                  id="activitiesTblHeaderDescription"
                  className={getClassNamesFor('name')}>
                {translation.activitiesNameHeader}
              </th>
              <th onClick={() => requestSort('')}
                  id="activitiesTblHeaderAsset"
                  className={getClassNamesFor('component')}>
                {translation.activitiesPageComponentHeader}
              </th>
              {/*<th onClick={() => requestSort('type')}*/}
              {/*    className={getClassNamesFor('type')}>*/}
              {/*    {translation.activitiesPageTypeHeader}*/}
              {/*</th>*/}
              {/*<th onClick={() => requestSort('priority')}*/}
              {/*    className={getClassNamesFor('priority')}>*/}
              {/*    {translation.activitiesPagePriorityHeader}*/}
              {/*</th>*/}
              {/*<th onClick={() => requestSort('tasks')}*/}
              {/*    className={getClassNamesFor('tasks')}>*/}
              {/*    {translation.activitiesPageTasksHeader}*/}
              {/*</th>*/}
              <th onClick={() => requestSort('')}
                  id="activitiesTblHeaderAssignedTo"
                  className={getClassNamesFor('assigned')}>
                {translation.activitiesPageAssignedToHeader}
              </th>
              <th onClick={() => requestSort('')}
                  id="activitiesTblHeaderDue"
                  className="textFieldCellDue">
                {translation.activitiesPageDueHeader}
              </th>
              <th onClick={() => requestSort('')}
                  id="activitiesTblHeaderRemaining"
                  className="textFieldCellDue">
                {translation.activitiesPageRemainingHeader}
              </th>
              <th onClick={() => requestSort('')}
                  id="activitiesTblHeaderCompleted"
                  className="textFieldCellDue">
                {translation.activitiesPageCompletedHeader}
              </th>
              {/* <th onClick={() => requestSort('date')}
                  className={getClassNamesFor('date')}>
                {translation.activitiesPageDateHeader}
              </th>
              <th onClick={() => requestSort('workhours')}
                  className={getClassNamesFor('workhours')}>
                {translation.activitiesPageWorkhoursHeader}
              </th> */}
              <th style={{textAlign: 'center'}} onClick={() => requestSort('')}
                  id="activitiesTblHeaderOperationalStatus"
                  className={getClassNamesFor('status')} colSpan="2">
                {translation.activitiesPageStatusHeader}
              </th>
            </tr>
            </thead>
            <tbody>
            {pageData && pageData.length > 0 ? pageData.map(activity =>
              <Fragment key={activity.id}>
                <tr>
                  <td className="checkBoxCell">
                    <Checkbox
                      checked={activity.selected ? activity.selected : false}
                      onChange={(e) => handleSelectedIdChange(activity.id, e.target.checked)}
                      color="default"
                      size="small"
                    />
                  </td>
                  <td onClick={(e) => handleRowClicked(activity)}>{activity.name}</td>
                  <td onClick={(e) => handleRowClicked(activity)}>{getComponentNameById(activity.componentId)}</td>
                  {/*<td onClick={(e) => handleRowClicked(activity)}>{typeOptions && typeOptions.find(x => x.activityType === activity.type) ? typeOptions.find(x => x.activityType === activity.type).name : ''}</td>*/}
                  {/*<td onClick={(e) => handleRowClicked(activity)}>{priorityOptions.find(x => x.priority === activity.priority) ? priorityOptions.find(x => x.priority === activity.priority).name : activity.priority}</td>*/}
                  {/*<td onClick={(e) => handleRowClicked(activity)}>{activity.tasks.length}</td>*/}
                  <td className="textFieldCell" onClick={(e) => handleRowClicked(activity)}>
                    <div className="selectField" onClick={(e) => {
                      e.stopPropagation()
                    }}>
                      {
                        activity.status === 'opened' ? (
                          <AssignPersonButton
                            department={currentDepartment}
                            employeesOptions={employeesOptions}
                            employeeId={activity.assigneeId}
                            activityId={activity.id}
                            handleSave={handleSaveAssignee}
                          />
                        ) : getEmployeeNameById(activity.assigneeId)
                      }
                    </div>
                  </td>
                  {/* <td onClick={(e) => handleRowClicked(activity)}>{getDate(activity)}</td>
                  <td onClick={(e) => handleRowClicked(activity)}>{activity.expectedWorkhours}</td> */}
                  <td className="textFieldCellDue">{activity.due[0]}<br/>{activity.due[1]}</td>
                  <td className="textFieldCellDue">{activity.remaining[0]}<br/>{activity.remaining[1]}</td>
                  <td className="textFieldCellDue">{activity.completed[0]}<br/>{activity.completed[1]}</td>
                  <td onClick={(e) => handleRowClicked(activity)}>
                    <div className="statusItem">
                    {activity.status === 'completed' && (
                      <div className="statusItem open">
                        {translation.activitiesPageStatusHeaderCompeted}
                      </div>
                    )}
                    {activity.status === 'removed' && (
                      <div className="statusItem off">
                        {translation.activitiesPageStatusHeaderRemoved}
                      </div>
                    )}
                    {activity.status === 'opened' && activity.isOverdue && !activity.isUpcoming && (
                      <div className="statusItem overdue">
                        {translation.activitiesPageStatusHeaderOverdue}
                      </div>
                    )}
                    {activity.status === 'opened' && !activity.isOverdue && activity.isUpcoming && (
                      <div className="statusItem upcoming">
                        {translation.activitiesPageStatusHeaderUpcoming}
                      </div>
                    )}
                    {activity.status === 'opened' && activity.isOverdue && activity.isUpcoming && (
                      <div className="statusItem overdue">
                        {translation.activitiesPageStatusHeaderOverdue}
                      </div>
                    )}
                    {activity.status === 'opened' && !activity.isOverdue && !activity.isUpcoming && (
                      <div className="statusItem on">
                        {translation.activitiesPageStatusHeaderOpened}
                      </div>
                    )}
                      {/* <div
                                                className="caption">{statusOptions.find(x => x.status === activity.status).name.toUpperCase()}</div> */}
                    </div>
                  </td>
                  <td className="btnCell">
                    <IconButton aria-label="delete" size="small" className="dropDownBtn mobile"
                                id="activitiesTblDropDownBtn"
                                onClick={(e) => {
                                  e.preventDefault()
                                  setActivityAnchorEl(e.target)
                                  setSelectedActivity(activity)
                                }}
                    >
                      <MoreVertIcon/>
                    </IconButton>
                  </td>
                </tr>
                {(activity.id === expandedActivity) &&
                  <tr className="activitiesInnerTableHolder">
                    <td className="activitiesInnerTableContent" colSpan={100}>
                      <table className="activitiesTaskTable pageTable">
                        <thead>
                        <tr>
                          <th>{translation.activitiesPageTasksTableTaskLabel}</th>
                          <th>{activity.status !== 'completed' ? translation.activitiesPageTasksTablePartToReplaceLabel : translation.activitiesPageTasksTablePartReplacedLabel}</th>
                          <th>{activity.status !== 'completed' ? translation.activitiesPageAlternativePartLabel : null}</th>
                          <th>{translation.activitiesPageTasksTableQuantityLabel}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {activity.tasks.map(task => {

                            const partData = parts && parts.find(x => x.id === task.partId)
                            let alternativePart = '-'
                            if (partData && partData.alternativePartId) {
                              const alternativePartModel = parts.find(x => x.id === partData.alternativePartId)
                              alternativePart = alternativePartModel.fullName
                            }

                            return (<tr key={task.id}>
                              <td>
                                <div
                                  className={`taskNameHolder ${task.isCompleted ? 'completed' : ''}`}>
                                  <div className="name">{task.taskName}</div>
                                  <div className="infoHolder">
                                    <div className="icoHolder"
                                         onClick={handlePopoverClick}/>
                                    <div className="popoverHolder">
                                      <Popover
                                        open={openPopover}
                                        anchorEl={anchorElPopover}
                                        onClose={handlePopoverClose}
                                        anchorOrigin={{
                                          vertical: 'bottom',
                                          horizontal: 'left',
                                        }}
                                        transformOrigin={{
                                          vertical: 'top',
                                          horizontal: 'left',
                                        }}
                                        elevation={1}
                                        className="pmPopover equipmentSubMenu activitiesTaskPopover"
                                      >
                                        <div
                                          className="title mb5">{translation.activitiesPageAlternativePartLabel}</div>
                                        <div className="">
                                          {parts && parts.find(x => x.id === task.alternativePartId) ?
                                            parts.find(x => x.id === task.alternativePartId).name : '-'}
                                        </div>
                                      </Popover>
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="taskNameHolder">
                                  <div className="name">
                                    {activity.status !== 'completed' ? 
                                      (parts && parts.find(x => x.id === task.partId) ? 
                                        parts.find(x => x.id === task.partId).fullName : '-') 
                                      : 
                                      (parts && parts.find(x => x.id === task.partReplacedId) ? 
                                      parts.find(x => x.id === task.partReplacedId).fullName : '-')}
                                  </div>
                                </div>
                              </td>

                              <td>
                              <div className="taskNameHolder">
                                <div className="name">
                                  {activity.status !== 'completed' ?  alternativePart : null}
                                </div>
                              </div>
                              </td>
                              <td>
                                <div className="taskNameHolder">
                                  <div className="name">{activity.status !== 'completed' ? task.quantity : task.partReplacedQuantity}</div>
                                </div>
                              </td>
                            </tr>)
                          },
                        )}
                        </tbody>
                      </table>
                    </td>
                  </tr>}
              </Fragment>,
            ) : null}
            </tbody>
          </table>
          {pageData && pageData.length > 0 && <div className="tableControlsHolder">
            <div className="sortHolder">
              <div className="caption">Rows per page:</div>
              <Button onClick={(e) => onAnchorElChange(e.target)}
                      endIcon={<ArrowDropDownIcon/>}
                      className="dropDownBtn" style={{ color: '#969696' }}>
                {rowsOnPage}
              </Button>

              <Popover
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={closePaginationMenu}
                disableScrollLock={true}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <MenuItem onClick={() => handleRowCount(20)}>20</MenuItem>
                <MenuItem onClick={() => handleRowCount(50)}>50</MenuItem>
                <MenuItem onClick={() => handleRowCount(100)}>100</MenuItem>
              </Popover>
            </div>
            <div className="paginationHolder mms">
              <div className="caption">{`${firstIndex}-${lastIndex} of ${totalRows}`}</div>
              <div className="paginationBtn">
                <IconButton
                  disabled={firstIndex === 1 || firstIndex === 0}
                  onClick={() => onPrevPage()}>
                  <ArrowLeft/>
                </IconButton>
                <IconButton
                  disabled={lastIndex === totalRows}
                  onClick={() => onNextPage()}>
                  <ArrowRight/>
                </IconButton>
              </div>
            </div>
          </div>}
          {isLoading ? <div className="loaderHolder">
            <Fade
              in={isLoading}
              unmountOnExit>
              <CircularProgress/>
            </Fade>
          </div> : (sortedActivities.length === 0 ?
            <div className="emptyInfoHolder">
              <div className="text">{translation.activitiesPagePartsEmptyListPlaceholderLabel}</div>
            </div> : null)}
        </div>
        <div className="subTitleWithFilter mb15">
          <p className="textGray">{translation.activitiesPagePartsTableLabel}</p>
        </div>
        <div className="pmTableHolder mb15">
          <table className="pmTable">
            <thead>
            <tr>
              <th>{translation.activitiesPagePartsTablePartHeader}</th>
              {/*<th>{translation.activitiesPagePartsTablePartNumberHeader}</th>*/}
              <th>{translation.activitiesPagePartsTableManufacturerHeader}</th>
              {/* <th>{translation.activitiesPagePartsTableManufacturerTypeHeader}</th> */}
              <th>{translation.activitiesPagePartsTableAlternativePartHeader}</th>
              <th>{translation.activitiesPagePartsTableQuantityHeader}</th>
            </tr>
            </thead>
            <tbody>
            {pagePartsData.map((part, index) =>
              <tr key={index}>
                <td>{part.fullName}</td>
                {/*<td>{part.partNumber}</td>*/}
                <td>{part.manufacturer}</td>
                {/* <td>{part.manufacturerType}</td> */}
                <td>{part.alternativePartFullName}</td>
                <td>{part.quantity}</td>
              </tr>,
            )}
            </tbody>
          </table>
          {renderEmptyListText()}
          {renderPagination()}
        </div>
        {isLoadingParts ? <div className="loaderHolder">
          <Fade
            in={isLoadingParts}
            unmountOnExit>
            <CircularProgress/>
          </Fade>
        </div> : null}
      </div>
      <Popover
        anchorEl={activityAnchorEl}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        disableScrollLock={true}
        open={Boolean(activityAnchorEl)}
        onClose={e => setActivityAnchorEl(null)}
        className="pmPopover equipmentSubMenu"
      >
        {selectedActivity?.status === 'opened' &&
          <MenuItem
            key={'mark'}
            id="activitiesTblSideMenuMarkAsComplBtn"
            onClick={() => {
              setActivityAnchorEl(null)
              setIsOpenCompleteActivityModal(true)
            }}
            className="menuItem mark"
          >{translation.popoverMarkAsCompletedLabel}</MenuItem>}
        <MenuItem
          key={'details'}
          id="assetsTblActivitySideMenuDetailsBtn"
          onClick={() => {
            setActivityAnchorEl(null)
            setIsOpenSaveActivityModal(true)
          }}
          className="menuItem edit"
        >{translation.popoverDetailsLabel}</MenuItem>
        {
          (selectedActivity?.status !== 'removed') && (
            <MenuItem
              key={'delete'}
              id="assetsTblActivitySideMenuRemoveBtn"
              onClick={() => {
                setActivityAnchorEl(null)
                setIsOpenDeleteModal(true)
              }}
              className="menuItem delete"
            >{translation.popoverRemoveLabel}</MenuItem>
          )
        }

      </Popover>
      <Popover
        anchorEl={filterAnchorEl}
        keepMounted
        open={Boolean(filterAnchorEl)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={(e) => {
          setFilterAnchorEl(false);
          document.body.classList.remove('view-scroll');
        }}        
      >
        <div className="activitiesFilterHolder respModal">
          <div className="filterHeading">
            <div className="pmPageBlockTitle">{translation.filterActivitiesModalLabel}</div>
          </div>
          {filter && <div className="formHolder">
            <TextField
              value={filter.statusId}
              onChange={(e) => {
                setFilter({ ...filter, statusId: e.target.value });
                if (e.target.value !== emptyStatusOption.id) {
                  setSelectedFilters({ ...selectedFilters, status: e.target.value });
                } else {
                  setSelectedFilters({ ...selectedFilters, status: null });
                }
              }}
              label={translation.filterActivitiesModalStatusLabel}
              variant="outlined"
              fullWidth
              select
              margin="normal"
              id="activitiesFilterChangeStatus"
            >
              <MenuItem key={emptyStatusOption.id} className="filterMenuItem" 
                        value={emptyStatusOption.id}>
                {emptyStatusOption.name}
              </MenuItem>
              {statusOptions && statusOptions.map((statusOption) =>
                <MenuItem key={statusOption.uiStatus} className="filterMenuItem" id="activitiesFilterChooseStatus"
                          value={statusOption.uiStatus}>
                  {statusOption.name}
                </MenuItem>)}
            </TextField>
            <TextField
              value={filter.dateRangeId}
              onChange={(e) => {
                setFilter({ ...filter, dateRangeId: e.target.value });
                if (e.target.value !== emptyDateRangeOption.id) {
                  setSelectedFilters({ ...selectedFilters, dateRangeId: e.target.value });
                } else {
                  setSelectedFilters({ ...selectedFilters, dateRangeId: null });
                }
              }}
              label={translation.filterActivitiesModalDateRangeLabel}
              variant="outlined"
              fullWidth
              select
              margin="normal"
              id="activitiesFilterChangeDateRange"
            >
              <MenuItem key={emptyDateRangeOption.id} className="filterMenuItem" 
                        value={emptyDateRangeOption.id}>
                {emptyDateRangeOption.name}
              </MenuItem>
              {filterTimeTypeOptions && filterTimeTypeOptions.map((filterTimeTypeOption) =>
                <MenuItem key={filterTimeTypeOption.filterDateType}
                          id="activitiesFilterChooseDateRange"
                          className="filterMenuItem"
                          value={filterTimeTypeOption.filterDateType}>
                  {filterTimeTypeOption.name}
                </MenuItem>)}
            </TextField>
            <Autocomplete
              options={[
                { label: translation.filterActivitiesModalSelectComponentLabel },
                ...(sortedComponents ? sortedComponents.filter(c => !c.parentComponentId && c.isActive) : [])
              ]}
              getOptionLabel={(option) => {
                if (!option) return '';
                return option.label ? option.label : `${option.name} (${option.number})`;
              }}
              value={selectedFilters.component}
              className="autocompleteBox"
              id="activitiesFilterChangeAsset"
              onChange={(event, option) => {
                if (option && option.label === translation.filterActivitiesModalSelectComponentLabel) {
                  setSelectedFilters({ ...selectedFilters, component: null });
                  setFilter({ ...filter, componentId: emptyComponentOption.id });
                } else {
                  setSelectedFilters({ ...selectedFilters, component: option });
                  setFilter({ ...filter, componentId: option ? option.id : emptyComponentOption.id });
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={translation.filterActivitiesModalComponentLabel}
                  variant="outlined"
                  fullWidth
                  className="autocomplete"
                  InputProps={{ ...params.InputProps, type: 'search' }}
                  InputLabelProps={{ shrink: true }}
                  placeholder={translation.filterActivitiesModalSelectComponentLabel}
                />
              )}
              renderOption={(option) => (
                <div>
                  {option.label ? (
                    <div>{option.label}</div>
                  ) : (
                    <div id="activitiesFilterChooseAsset">{`${option.name} (${option.number})`}</div>
                  )}
                </div>
              )}
            />
              {/* <TextField
                value={filter.componentId}
                onChange={e => setFilter({ ...filter, componentId: e.target.value })}
                label={translation.filterActivitiesModalComponentLabel}
                variant="outlined"
                fullWidth
                select
                margin="normal"
                open={open}
                onClick={() => setOpen(true)}
                onClose={handleClose}
              >

              <MenuItem key={emptyComponentOption.id} className="filterMenuItem" value={emptyComponentOption.id}>
                {emptyComponentOption.name}
              </MenuItem> */}
              {/* <TextField
                inputRef={searchInputRef}
                value={searchComponents}
                onChange={(e) => setSearchComponents(e.target.value)}
                label='Search...'
                variant="outlined"
                fullWidth
                margin="normal"
                autoFocus
              /> */}

              {/* {components && components
                .filter(c => !c.parentComponentId && c.isActive)
                // .filter(c => `${c.name} (${c.number})`.toLowerCase().includes(searchComponents.toLowerCase()))
                .map((component) => {
                  return (
                    <MenuItem key={component.id} className="filterMenuItem" value={component.id} onClick={() => setOpen(false)}>
                      {`${component.name} (${component.number})`}
                    </MenuItem>
                  )
                })}
              </TextField> */}
            <TextField
              value={filter.priorityId}
              onChange={(e) => {
                setFilter({ ...filter, priorityId: e.target.value });
                if (e.target.value !== emptyPriorityOption.id) {
                  setSelectedFilters({ ...selectedFilters, priorityId: e.target.value });
                } else {
                  setSelectedFilters({ ...selectedFilters, priorityId: null });
                }
              }}
              label={translation.filterActivitiesModalPriorityLabel}
              variant="outlined"
              fullWidth
              select
              margin="normal"
              id="activitiesFilterChangePriority"
            >
              <MenuItem key={emptyPriorityOption.id} className="filterMenuItem" 
                        value={emptyPriorityOption.id}>
                {emptyPriorityOption.name}
              </MenuItem>
              {priorityOptions && priorityOptions.map((priorityOption) =>
                <MenuItem key={priorityOption.priority} className="filterMenuItem"
                          id="activitiesFilterChoosePriority"
                          value={priorityOption.priority}>
                  {priorityOption.name}
                </MenuItem>)}
            </TextField>
            <TextField
              value={filter.assigneeId}
              onChange={(e) => {
                setFilter({ ...filter, assigneeId: e.target.value });
                if (e.target.value !== emptyGuidAssigneeOption.id) {
                  setSelectedFilters({ ...selectedFilters, assigneeId: e.target.value });
                } else {
                  setSelectedFilters({ ...selectedFilters, assigneeId: null });
                }
              }}
              label={translation.filterActivitiesModalAssigneeLabel}
              variant="outlined"
              fullWidth
              select
              margin="normal"
              id="activitiesFilterChangeAssignee"
            >
              <MenuItem
                key={emptyGuidAssigneeOption.id}
                className="filterMenuItem"
                value={emptyGuidAssigneeOption.id}
              >
                {emptyGuidAssigneeOption.name}
              </MenuItem>
              <MenuItem
                key={emptyAssigneeOption.id}
                className="filterMenuItem"
                value={emptyAssigneeOption.id}
              >
                {emptyAssigneeOption.name}
              </MenuItem>
              {employeesOptions
                  ? <p className="filterMenuItemDisabled">{translation.saveDepartmentModalFromDepartmentLabel}</p>
                  : <p style={{ display: 'none' }}></p>}

              {employeesOptions.fromDepartment && employeesOptions.fromDepartment.map((employee) =>
                <MenuItem key={employee.id} className="filterMenuItem" id="activitiesFilterChooseAssigneeFromDep"
                          value={employee.id}>
                  {employee.name}
                </MenuItem>)}
                
                {employeesOptions
                  ? <p className="filterMenuItemDisabled">{translation.saveDepartmentModalOthersLabel}</p>
                  : <p style={{ display: 'none' }}></p>}  
                {employeesOptions.other && employeesOptions.other.map((employee) =>
                <MenuItem key={employee.id} className="filterMenuItem" id="activitiesFilterChooseAssigneeOthers"
                          value={employee.id}>
                  {employee.name}
                </MenuItem>)}
            </TextField>
          </div>}
          <div className="btnHolder end fixedWidthBtn" style={{marginTop: '15px'}}>
            <Button variant="outlined"
                    className='respModal'
                    id="activitiesFilterResetBtn"
                    onClick={resetFilter}>
              {translation.resetButtonLabel}
            </Button>
            <Button variant="contained"
                    color="primary"
                    className='respModal'
                    id="activitiesFilterApplyBtn"
                    onClick={applyFilter}>
              {translation.applyButtonLabel}
            </Button>
          </div>
        </div>
      </Popover>
      <CompleteActivityModal
        employeesOptions={employeesOptions}
        isOpen={isOpenCompleteActivityModal}
        isOpenManager={setIsOpenCompleteActivityModal}
        activity={selectedActivity}
        employees={departmentEmployees}
        handleCompleteUpdate={a => {
          if (currentDepartment) {
            fetchActivities().then()
          }
        }}/>
      <AssignPersonModal
        open={isOpenAssignPersonModal}
        handleClose={e => setIsOpenAssignPersonModal(false)}
        employeeId={selectedActivity?.assigneeId}
        activityId={selectedActivity?.id}
        handleSave={handleSaveAssignee}
      />
      <SaveActivityModal
        isOpen={isOpenSaveActivityModal}
        isOpenManager={setIsOpenSaveActivityModal}
        isCatalog={false}
        componentId={selectedActivity?.componentId}
        activity={selectedActivity}
        isReadOnly={true}
        openedFromActivities={true}
        onSuccessSave= {() => queryClient.invalidateQueries(['activities'])}
        handleActivityUpdate={a => {
          if (currentDepartment) {
            fetchActivities().then()
          }
        }}
        disableCompleted={!!selectedActivity}
        employeeId={selectedActivity?.assigneeId}
      />

      <DeleteItemModal
        isOpen={isOpenDeleteModal}
        isOpenManager={setIsOpenDeleteModal}
        message={translation.removeActivityModalMessageLabel}
        onDeleteModalClose={() => {
          onDeleteActivity()
        }}/>

    </div>
  )
}

const mapDispatchToProps = {
  setFilterInfo,
  setActivities,
  setPageTitle,
}

const mapStateToProps = ({ mms }) => ({
  currentDepartment: mms.currentDepartment,
  components: mms.components,
  parts: mms.parts,
  typeOptions: mms.typeOptions,
  statusOptions: mms.statusOptions,
  states: mms.states,
  employees: mms.employees,
  activities: mms.activities,
})

export default connect(mapStateToProps, mapDispatchToProps)(ActivitiesPage)
